/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  const userdata = JSON.parse(localStorage.getItem("userdata"));
  const isAuthenticated = userdata?.token;
  return (
    <div>
      <div className="footer_wrapper float_left">
        <div className="section_2">
          <div className="section2_footer_overlay" />
          <div className="section2_footer_wrapper">
            <div className="container">
              <div className="row upper">
                <div className="col-lg-5 col-md-6 col-xs-12 col-sm-6">
                  <div className="footer_widget section2_about_wrapper">
                    <div className="wrapper_first_image">
                      <a href="index.html">
                        <img
                          src="images/racing/footer-logo.png"
                          className="img-responsive"
                          alt="logo"
                        />
                      </a>
                    </div>
                    <div className="abotus_content">
                      <p>
                        RacePace Your F1 fantasy playground for crafting dream
                        <br />
                        teams, strategic Grand Prix planning, and immersing in
                        <br />
                        Formula 1 excitement with live updates.
                      </p>
                      <p />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-xs-12 col-sm-6">
                  <div className="footer_widget section2_useful_wrapper">
                    <h4>Menu</h4>
                    <ul className="pl-0">
                      <li>
                        <NavLink to={"Home"}>
                          Home <span className="sr-only">(current)</span>
                        </NavLink>
                        {/* <a href="/Home">Home</a> */}
                      </li>
                      {isAuthenticated && (
                        <>
                          <li>
                            <NavLink to={"Leaderboard"}>
                              Leaderboard{" "}
                              <span className="sr-only">(current)</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={"my_team"}>
                              My Team <span className="sr-only">(current)</span>
                            </NavLink>
                          </li>
                        </>
                      )}
                      {!isAuthenticated && (
                        <li>
                          <NavLink to={"About_us"}>
                            About <span className="sr-only">(current)</span>
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-xs-12 col-sm-6">
                  <div className="footer_widget section2_useful_second_wrapper">
                    <h4>Socials</h4>
                    <ul className="pl-0">
                      <li>
                        <a href="https://www.instagram.com/" target="_blank">
                          Instagram{" "}
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/?lang=en" target="_blank">
                          Twitter
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                  <div className="btm_foter_box">
                    <p>
                      <i className="fa fa-copyright" /> 2024 design by
                      <a> Racepace.vip</a>
                    </p>
                    <ul className="aboutus_social_icons main">
                      <li>
                        <NavLink
                          className={"px-2 rounded-25"}
                          to={"contact-us"}
                        >
                          Contact Us <span className="sr-only">(current)</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className={"px-2 rounded-25"}
                          to={"Privacy_policy"}
                        >
                          Privacy &amp; Policy{" "}
                          <span className="sr-only">(current)</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink className={"px-2 rounded-25"} to={"FAQ"}>
                          FAQ
                          <span className="sr-only">(current)</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink className={"px-2 rounded-25"} to={"Rules"}>
                          Game Rules
                          <span className="sr-only">(current)</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className={"px-2 rounded-25"}
                          to={"Terms_condition"}
                        >
                          Terms &amp; Condition{" "}
                          <span className="sr-only">(current)</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="close_wrapper"></div>
        {/* section-2 end */}
      </div>
    </div>
  );
}
