/* eslint-disable no-script-url */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../Services/ApiService";
import Modal from "react-bootstrap/Modal";
import {
  showAccountVerify,
  showErrorAlert,
  showLoginAlert,
  showSuccessAlert,
  showSuccesstoaster,
} from "../../../Helper/SweetAlert";
import { MoonLoader } from "react-spinners";
import { useGoogleLogin } from "@react-oauth/google";
import { PostService } from "../../../Services/ConstantService";
import Spinner from "react-bootstrap/Spinner";
import { Button } from "react-bootstrap";
import { requestForToken } from "../../../Common/firebase";
export default function Login() {
  const navigate = useNavigate();
  const [submitloader, setsubmitLoader] = useState(false);
  const [typepassword, settypepassword] = useState("password");
  const [show, setShow] = useState(false);
  const [showverification, setshowverification] = useState(false);
  const [submit, setsubmit] = useState(false);
  const [emailaddress, setemailaddress] = useState("");
  const [FCM_token, setFCM_token] = useState("");
  const [DataForVerification, setDataForVerification] = useState("");
  const handleClose = () => setShow(false);
  const handleCloseverification = () => setshowverification(false);
  const otpInputs = useRef([]);
  const [seconds, setSeconds] = useState(300);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    requestForToken().then((e) => {
      setFCM_token(e);
    });
  }, []);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate: (values) => {
      const errors = {};

      // Validate 'password' field
      if (!values.password) {
        errors.password = "password is required";
      }

      // Validate 'email' field
      if (!values.email) {
        errors.email = "email is required";
      }

      return errors;
    },

    onSubmit: (values) => {
      setsubmit(true);
      if (formik.isValid) {
        setsubmitLoader(true);
        if (FCM_token) {
          values.FCM_token = FCM_token;
        }
        axios.post(API_URL.ADMIN_LOGIN, values).then(
          (res) => {
            if (res?.data?.status === true) {
              showLoginAlert(
                res?.data?.message,
                `Welcome, ${res?.data?.data?.user?.name}`,
                () => {
                  const data = {
                    token: res?.data?.data?.accessToken,
                    userName: res?.data?.data?.user?.name,
                    id: res?.data?.data?.user?._id,
                    profile: res?.data?.data?.user?.image,
                    timeZone: timeZone,
                    history:
                      res?.data?.data?.user?.teams?.length > 0 ? true : false,
                  };
                  localStorage.setItem("userdata", JSON.stringify(data));
                  document.getElementById("closemodal")?.click();
                  setsubmit(false);
                  setsubmitLoader(false);
                  window?.location?.reload();
                }
              );
            } else {
              setsubmitLoader(false);
              showErrorAlert(res.data?.message);
            }
          },
          (err) => {
            console.log(err);
            setsubmitLoader(false);
            if (err?.response?.data?.data?.verify === false) {
              formik.values.name = err?.response?.data?.data?.name;
              showAccountVerify().then((res) => {
                console.log(res);
                if (res?.isConfirmed) {
                  setDataForVerification(err?.response?.data?.data);
                  document.getElementById("closemodal")?.click();
                  setTimeout(() => {
                    otpInputs.current[0]?.focus();
                  }, 1000);
                  resendOtp(
                    err?.response?.data?.data?.email,
                    err?.response?.data?.data?.name
                  );
                  console.log(formik?.values);
                  setshowverification(true);
                }
              });
            } else {
              setsubmitLoader(false);
              showErrorAlert(err.response?.data?.message);
            }
          }
        );
      }
    },
  });

  function verifyotp() {
    console.log(otp, DataForVerification);
    const data = {
      email: DataForVerification?.email,
      otp: parseInt(otp.join("")),
    };
    axios.post(API_URL.VERIFY_OTP, data).then(
      (res) => {
        if (res?.data?.status === true) {
          showLoginAlert(
            res?.data?.message,
            `Hi, ${res?.data?.data?.name}`,
            () => {
              const data = {
                token: res?.data?.data?.token,
                userName: res?.data?.data?.name,
                id: res?.data?.data?._id,
                profile: res?.data?.data?.image ? res?.data?.data?.image : "",
              };
              if (data) {
                localStorage.setItem("userdata", JSON.stringify(data));
              }
              setsubmit(false);
              setsubmitLoader(false);
              setShow(false);
              window?.location?.reload();
            }
          );
        } else {
          setsubmitLoader(false);
          setOtp(["", "", "", ""]);
          setSeconds(300);
          showErrorAlert(res.data?.message);
        }
      },
      (err) => {
        console.log(err);
        setsubmitLoader(false);
        showErrorAlert(err.response?.data?.message);
      }
    );
  }
  function resendOtp(email, name) {
    setSeconds(300);
    const data = {
      email: email,
      name: name,
    };
    axios.post(API_URL.RESEND_OTP, data).then(
      (res) => {
        if (res?.data?.status === true) {
          showSuccesstoaster(res?.data?.message);
          handleTimerLogic();
          setsubmitLoader(false);
        } else {
          setsubmitLoader(false);
          showErrorAlert(res.data?.message);
        }
      },
      (err) => {
        console.log(err);
        setsubmitLoader(false);
        showErrorAlert(err.response?.data?.message);
      }
    );
  }

  const handleOtpChange = (e, index) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);
    if (e.target.value !== "" && index < otpInputs.current.length - 1) {
      otpInputs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      otpInputs.current[index - 1].focus();
    }
  };

  const handleTimerLogic = () => {
    let interval;

    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (seconds <= 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  };
  function loginWithGoogle(access_token) {
    axios
      .get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        if (res?.data?.verified_email) {
          const data = {
            logintype: "google",
            googlekey: res?.data?.id,
            name: res?.data?.name,
            email: res?.data?.email,
            timeZone: timeZone,
            image: res?.data?.picture,
          };
          if (FCM_token) {
            data.FCM_token = FCM_token;
          }
          PostService(API_URL.ADMIN_LOGIN, data).then((res) => {
            if (res?.data?.status === true) {
              showLoginAlert(
                res?.data?.message,
                `Welcome, ${data?.name}`,
                () => {
                  const data = {
                    token: res?.data?.data?.accessToken
                      ? res?.data?.data?.accessToken
                      : res?.data?.data?.token,
                    userName: res?.data?.data?.updatedUser?.name
                      ? res?.data?.data?.updatedUser?.name
                      : res?.data?.data?.user?.name,
                    id: res?.data?.data?.updatedUser?._id
                      ? res?.data?.data?.updatedUser?._id
                      : res?.data?.data?.user?._id,
                    profile: res?.data?.data?.updatedUser?.image
                      ? res?.data?.data?.updatedUser?.image
                      : res?.data?.data?.user?.image,
                    history:
                      res?.data?.data?.updatedUser?.teams?.length > 0 ||
                      res?.data?.data?.user?.teams?.length > 0
                        ? true
                        : false,
                  };
                  if (data) {
                    localStorage.setItem("userdata", JSON.stringify(data));
                  }

                  document.getElementById("closemodal")?.click();
                  setsubmit(false);
                  setsubmitLoader(false);
                  window?.location?.reload();
                }
              );
            } else {
              setsubmitLoader(false);
              showErrorAlert(res.data?.message);
            }
          });
        } else {
          showErrorAlert(res.data?.message);
        }
      });
  }
  function forgotpassword() {
    setsubmit(true);
    if (!emailaddress) {
      return;
    }
    setsubmitLoader(true);
    PostService(API_URL.FORGOT_PASSWORD, { email: emailaddress }).then(
      (res) => {
        if (res?.data?.status) {
          setsubmitLoader(false);
          setemailaddress("");
          setShow(false);
          showSuccessAlert(res?.data?.message, "");
        } else {
          showErrorAlert(res?.data?.message);
        }
      },
      (error) => {
        setsubmitLoader(false);
      }
    );
  }
  const reset = () => {
    setsubmit(false);
    setsubmitLoader(false);
    setemailaddress("");
    formik.resetForm();
  };
  return (
    <div>
      <div
        className="modal fade"
        id="loginModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content loginarea__wraper">
            <div className="modal-body">
              <div className>
                <div className="login__heading">
                  <button
                    id="closemodal"
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={reset}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <h5 className="login__title">Login</h5>
                  <p className="login__description">
                    Login and dive into the fast lane
                    <br /> with Race Pace !
                  </p>
                  <div className="panel panel-default">
                    <div className="panel-body">
                      <form
                        className="form-horizontal"
                        onSubmit={formik.handleSubmit}
                      >
                        <div className="form-group">
                          <label className="control-label">Email</label>
                          <div className="col-md-12">
                            <input
                              type="email"
                              className={`form-control ${
                                formik.errors.email && submit
                                  ? "border_error"
                                  : ""
                              }`}
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              name="email"
                              placeholder="Enter Email"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label">Password</label>
                          <div className="col-md-12">
                            <input
                              id="password-field"
                              type={typepassword}
                              className={`form-control ${
                                formik.errors.password && submit
                                  ? "border_error"
                                  : ""
                              }`}
                              value={formik.values.password}
                              onChange={formik.handleChange}
                              name="password"
                              placeholder="Enter Password"
                            />
                            <span
                              className={
                                typepassword === "password"
                                  ? "fa-solid fa-eye-slash field-icon toggle-password"
                                  : "fa-solid fa-eye field-icon toggle-password"
                              }
                              onClick={() =>
                                settypepassword(
                                  typepassword === "password"
                                    ? "text"
                                    : "password"
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="my-1 mx-3">
                          <a
                            href="javascript:void(0)"
                            className="font-weight-bold text-muted forgot_text"
                            onClick={() => {
                              document.getElementById("closemodal")?.click();
                              setShow(true);
                            }}
                          >
                            Forgot Password
                          </a>
                        </div>
                        <div className="form-group ">
                          <div className="col-md-12">
                            <div className="login-btn">
                              <button
                                type="submit"
                                className="btn btn-primary login-btn"
                                onClick={() => setsubmit(true)}
                              >
                                <div className="d-flex justify-content-center align-items-center">
                                  <span> Login</span>
                                  <Spinner
                                    animation="border"
                                    role="status"
                                    size="sm"
                                    className={
                                      submitloader ? "mx-2" : "mx-2 d-none"
                                    }
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </Spinner>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                        <p className="or-line">or </p>
                        <div className="form-group d-flex justify-content-center">
                          <div className="col-md-6 btn-group px-0">
                            <a
                              className="default__button login__button__1 px-4"
                              style={{
                                backgroundColor: "#EF6358",
                                whiteSpace: "nowrap",
                              }}
                              href="javascript:void(0)"
                              onClick={useGoogleLogin({
                                onSuccess: (tokenResponse) =>
                                  loginWithGoogle(tokenResponse?.access_token),
                              })}
                            >
                              <span>
                                <img
                                  src="images/racing/gmail.svg"
                                  className="img-responsive"
                                  alt="logo"
                                />
                              </span>{" "}
                              Login with Google
                            </a>
                          </div>
                          {/* <div className="col-md-6 btn-group">
                            <a
                              className="default__button login__button__1"
                              style={{ backgroundColor: "#1A73E8" }}
                              href="#"
                            >
                              <span>
                                <img
                                  src="images/racing/facebook.svg"
                                  className="img-responsive"
                                  alt="logo"
                                />
                              </span>{" "}
                              Login With facebook
                            </a>
                          </div> */}
                        </div>
                        <p className="d-flex flex-column last login__description tag">
                          Don't have an account yet?
                          <span className="login">
                            <a
                              href="javascript:void(0)"
                              data-toggle="modal"
                              data-target="#RegisterModal"
                              onClick={() => {
                                document.getElementById("closemodal")?.click();
                              }}
                            >
                              Register with RacePace
                            </a>
                          </span>
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#registerModal"
                          >
                            {" "}
                          </a>
                        </p>
                      </form>
                    </div>
                  </div>
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#registerModal"
                  >
                    {" "}
                  </a>
                  <p />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* enter team name modal */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        backdrop="static"
      >
        {/* <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="h5">
            Enter Your Registrated Email
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="login__heading">
            <button type="button" className="close" onClick={handleClose}>
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="login__title">Forgot Password</h5>
            <p className="login__description">
              You will be receiving a email from Racepace to
              <br />
              forgot your password
            </p>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group form-horizontal my-4">
                <label className="control-label ml-3">Email</label>
                <div className="col-md-12">
                  <input
                    type="email"
                    className={`form-control ${
                      !emailaddress && submit ? "border_error" : ""
                    }`}
                    value={emailaddress}
                    onChange={(e) => {
                      setemailaddress(e?.target?.value);
                    }}
                    name="email"
                    placeholder="Enter Email"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-primary px-4 rounded-25 d-flex justify-content-center align-items-center"
                onClick={() => forgotpassword()}
              >
                Submit
                <Spinner
                  animation="border"
                  role="status"
                  size="sm"
                  className={submitloader ? "mx-2" : "mx-2 d-none"}
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </button>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button className="btn btn-primary px-4 rounded-25">Submit</Button>
        </Modal.Footer> */}
      </Modal>

      <Modal
        show={showverification}
        onHide={handleCloseverification}
        backdrop="static"
        keyboard={false}
        className="border-0"
      >
        <Modal.Body className="pb-0">
          <div className="leader-board-team login__heading pb-3 pt-1 upcome_race_wrapper">
            <button
              type="button"
              className="close"
              onClick={handleCloseverification}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="login__title px-3 text-left">Otp Verification</h5>
            <div className="modal-body">
              <div className="container px-0">
                <div className="my-1 d-flex justify-content-center"></div>
                <div className="position-relative">
                  <div className="text-center d-block">
                    <h5>Please enter the OTP to verify your account</h5>
                    <div>
                      {" "}
                      <span className="text-reset opacity-60 fs-6">
                        A code has been sent to {DataForVerification?.email}
                      </span>
                    </div>
                    <div className="inputs d-flex flex-row justify-content-center mt-2">
                      {otp.map((value, i) => (
                        <input
                          ref={(el) => (otpInputs.current[i] = el)}
                          className="m-2 text-center form-control rounded font-weight-bold"
                          type="text"
                          key={i}
                          maxLength={1}
                          value={value}
                          onChange={(e) => handleOtpChange(e, i)}
                          onKeyDown={(e) => handleKeyDown(e, i)}
                        />
                      ))}
                    </div>
                    {seconds > 1 && (
                      <div className="row">
                        <div className="d-flex justify-content-center">
                          <p>Valid for</p>
                          <h5
                            className={
                              seconds < 60 ? "text-danger px-2" : "px-2"
                            }
                          >
                            {Math.floor(seconds / 60)}:
                            {seconds % 60 < 10 ? "0" : ""}
                            {seconds % 60}
                          </h5>
                        </div>
                      </div>
                    )}
                    {seconds <= 0 && (
                      <div className="text-center">
                        <a
                          href="javascript:void"
                          className="text-decoration-underline"
                          onClick={() => resendOtp()}
                        >
                          Resend Otp
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end mt-2">
              <button
                type="submit"
                className="btn btn-primary px-3 rounded-25"
                onClick={() => verifyotp()}
              >
                <div className="d-flex justify-content-center align-items-center">
                  <span> Verify Otp</span>
                  <Spinner
                    animation="border"
                    role="status"
                    size="sm"
                    className={submitloader ? "mx-2" : "mx-2 d-none"}
                  ></Spinner>
                </div>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
