/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Leaderboard from "../Leaderboard/Leaderboard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import Createteam from "../Create_Team/Create_Team";
import {
  GetService,
  convertUtcToTimeZone,
} from "../../../Services/ConstantService";
import { API_URL } from "../../../Services/ApiService";
import { showErrorAlert } from "../../../Helper/SweetAlert";
import { format } from "date-fns";
import RaceLeaderboard from "../Race_leaderboard/Race_leaderboard";
export default function AllLeaderboard(props) {
  const navigate = useNavigate();
  const [leaderboard_tab, setleaderboard_tab] = useState("leaderboard");
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [Active_race, setActive_race] = useState("");
  const swiperRef = useRef(null);

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
      setSwiperIndex(swiperRef.current.swiper.realIndex);
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
      setSwiperIndex(swiperRef.current.swiper.realIndex);
    }
  };
  useEffect(() => {
    setInnerWidth(window.innerWidth);
  }, [window.innerWidth]);

  function swipercounter() {
    console.log(innerWidth)
    if (innerWidth > 1200) {
      return 3;
    } else if (innerWidth < 1200 && innerWidth > 800) {
      return 2;
    } else {
      return 1;
    }
  }

  const getRacelist = async () => {
    setLoading(true);
    GetService(API_URL.ACTIVE_RACE).then(
      (res) => {
        if (res.data.status === true) {
          setActive_race(res.data.data);
          setLoading(false);
        } else {
          showErrorAlert(res.data?.message);
          setLoading(false);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    setleaderboard_tab(props.activetab);
  }, [props.activetab]);

  useEffect(() => {
    getRacelist();
  }, []);
  return (
    <div>
      {/*upcoming race wrapper start*/}
      <div
        className="upcome_race_wrapper float_left leader-board-team pt-0"
        id="myleaderboard"
      >
        <div className="container pt-5 mb-3">
          <div className="row">
            <div className="col-12"></div>
            <div className="col-md-12">
              <div className="ft_left_heading_wraper head1 center_headign">
                <h1>Check Your Leaderboard &amp; Team</h1>
                {/* <p>
                  It has survived not only five centuries, but also the leap
                  into <br /> elit typesetting, remaining essentially unchanged.
                </p> */}
              </div>
            </div>
            {/*leaderboard team tab*/}
            <div className="col-md-12">
              <div className="x_offer_tabs_wrapper tab_online">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    {" "}
                    <a
                      className={
                        leaderboard_tab === "leaderboard"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={() => setleaderboard_tab("leaderboard")}
                    >
                    Season Leaderboard
                    </a>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <a
                      className={
                        leaderboard_tab === "Team"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={() => setleaderboard_tab("Team")}
                    >
                     Race Leaderboard
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="tab-content select-player-2">
                <div
                  id="leaderboard"
                  className={
                    leaderboard_tab === "leaderboard"
                      ? "tab-pane active leaderboard"
                      : "tab-pane fade leaderboard"
                  }
                >
                  {leaderboard_tab === "leaderboard" && <Leaderboard />}
                </div>
                <div
                  id="team"
                  className={
                    leaderboard_tab === "Team"
                      ? "tab-pane active leaderboard"
                      : "tab-pane fade leaderboard"
                  }
                >
                  {leaderboard_tab === "Team" && <RaceLeaderboard />}
                </div>
              </div>
            </div>
            {/*leaderboard team tab*/}
          </div>
        </div>
        {Active_race?.length > 0 && (
          <div className="upcoming_games_wrapper mb-3">
            <div className="container">
              <div className="owl-theme row">
                <div className="ft_left_heading_wraper head1 d-flex justify-content-between">
                  <h1>next race</h1>
                  <div class="owl-nav">
                    <div
                      class="owl-prev"
                      onClick={goPrev}
                      disabled={swiperIndex === 0}
                    >
                      <i class="flaticon-left-arrow"></i>
                    </div>
                    <div
                      class="owl-next"
                      onClick={goNext}
                      disabled={swiperIndex === 8}
                    >
                      <i class="flaticon-right-arrow"></i>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <Swiper
                    ref={swiperRef}
                    slidesPerView={swipercounter()}
                    spaceBetween={30}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                    onSwiper={(swiper) => setSwiperIndex(swiper.realIndex)}
                  >
                    {Active_race &&
                      Active_race?.map((res, i) => (
                        <SwiperSlide>
                          <div className="item" key={i}>
                            <div className="game1_slider_wrapper float_left">
                              <div className="game_slider_wrapper">
                              <div className="race_label">{i===0? 'Next Race':'Upcoming'}</div>
                                <div className="game_1">
                                  <div className="match_list_wrapper">
                                    <div className="match_list_img">
                                      <h4>{res?.name}</h4>
                                      <p>{res?.venue}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="game_btm_cntnt float_left">
                                <p className="mb-1">
                                  {" "}
                                  {res?.qualifying_date
                                    ? convertUtcToTimeZone(res?.qualifying_date)
                                    : ""}{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
