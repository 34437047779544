import axios from "axios";
// import history from "../Helper/history";
// import { useNavigate  } from 'react-router-dom';
import { format, utcToZonedTime } from "date-fns-tz";

// Common get API
const userdata= JSON.parse(localStorage.getItem("userdata"));
const token = userdata?.token
export const GetService = async (url, params) => {
 
  if (!url) {
    return;
  }

  const axiosConfig = {
    headers: {
      Accept: "application/json",
      'authorization': token,
    },
    ...params,
  };

  try {
    const response = await axios.get(url, axiosConfig);
    return response;
  } catch (error) {
    if (error?.response.status === 401) {
      localStorage.removeItem("userdata"); 
      // history.push("/login");
      window.location.reload()  
    }
    return error.response;
  }
};

// Common Post API for JSON post
export const PostService = async (url, data) => {
  // const history1 = useNavigate ();

  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      'authorization': token,
    },
  };

  try {
    const response = await axios.post(url, data, axiosConfig);
    // console.log(response) 
    return response;
  } catch (error) {  
    if (error.response.status === 401) {
      localStorage.removeItem("userdata"); 
      // history.push("/login");
      window.location.reload()  
    }
    return error.response;
  }
};


// Common Post API for FormData post
export const PostFormData = async (url, data) => {
  const axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      'authorization': token,
    },
  };

  try {
    const response = await axios.post(url, data, axiosConfig); 
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userdata"); 
      // history.push("/login");
      window.location.reload()  
    }
    return error.response;
  }
};


// Common Put API
export const PutService = async (url, data) => {
    const axiosConfig = {
      headers: {
        'Content-Type': "application/json",
        Authorization: token,
      },
    };
  
    try {
      const response = await axios.put(url, data, axiosConfig); 
      return response;
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token"); 
        // history.push("/login");
        window.location.reload()  
      }
      return error.response;
    }
  };
  
  // Common Put API for FormData
  export const PutFormData = async (url, data) => {
    const token = localStorage.getItem('token');
  
    const axiosConfig = {
      headers: {
        'Content-Type': "multipart/form-data",
        Authorization: token,
      },
    };
  
    try {
      const response = await axios.put(url, data, axiosConfig); 
      return response;
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token"); 
        // history.push("/login");
        window.location.reload()  
      }
      return error.response;
    }
  };
  
  // Common Delete API
  export const DeleteService = async (url, params) => {
    const token = localStorage.getItem('token');
  
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: token,
      },
      ...params,
    };
  
    try {
      const response = await axios.delete(url, axiosConfig); 
      return response;
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token"); 
        // history.push("/login");
        window.location.reload()  
      }
      return error.response;
    }
  };

  export function convertUtcToTimeZone(utcDate) {
    const timeZone= Intl.DateTimeFormat().resolvedOptions().timeZone;
    // console.log(timeZone)
    const zonedDate = utcToZonedTime(new Date(utcDate), timeZone);
    const formattedDate = format(zonedDate, "dd MMM yyyy hh:mm a", {
      timeZone: timeZone,
    });
    return formattedDate;
  }

  export const limitDigitsforPoints = (event) => {
  const inputElement = event.target;
  let inputValue = inputElement.value;
  inputValue = inputValue.replace(/^0+/, '').replace(/\D/g, '');
  if (!isNaN(Number(inputValue)) && Number(inputValue) > 0) {
    if (inputValue.length >= 6) {
      inputElement.value = inputValue.slice(0, 6);
    } else {
      inputElement.value = inputValue;
    }
  } else {
    inputElement.value = '';
  }

};
  