/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import Accordion from "react-bootstrap/Accordion";
import { GetService } from "../../Services/ConstantService";
import { API_URL } from "../../Services/ApiService";
import { showErrorAlert } from "../../Helper/SweetAlert";
import Customloader from "../../Helper/CustomLoader";

export default function FAQ() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [FAQItem, setFAQItem] = useState("");

  useEffect(() => {
    getFAQlist();
  }, []);
  const getFAQlist = async () => {
    setLoading(true);
    GetService(API_URL.GET_FAQ).then(
      (res) => {
        if (res.data.status === true) {
          setFAQItem(res.data.data);
          setLoading(false);
        } else {
          showErrorAlert(res.data?.message);
          setLoading(false);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
        setLoading(false);
      }
    );
  };
  return (
    <div>
      <div className="upcome_race_wrapper float_left leader-board-team mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ft_left_heading_wraper head1 center_headign text-center">
                <h1>FAQ</h1>
                <p className="py-1">
                  Unleash your inner champion with our comprehensive FAQ guide
                  and get ready to
                  <br /> dominate the fantasy racing tracks!
                </p>
              </div>
            </div>
            <Accordion defaultActiveKey="0">
              <div className="row">
                {FAQItem &&
                  FAQItem?.map((res, i) => (
                    <div className="col-12 my-2">
                      <div className="d-flex justify-content-center">
                        <div className="w-75">
                          <Accordion.Item eventKey={i}>
                            <Accordion.Header>
                              <span className="font-weight-bold text-muted">
                                {res?.question}
                              </span>
                            </Accordion.Header>
                            <Accordion.Body>
                              {res?.answer && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: res.answer,
                                  }}
                                ></div>
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </Accordion>
          </div>
        </div>
        {loading ? <Customloader /> : ""}
      </div>
    </div>
  );
}
