import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { firebaseConfig, vapid_key } from '../Environment/Environment';

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestForToken = async () => {
  try {
    // Ensure notification permission is granted, or request again
    while (Notification?.permission !== 'granted') {
      const permission = await Notification?.requestPermission();
      if (permission !== 'granted') {
        console.warn('Notification permission denied. Retrying...');
      }
    }

    // Once permission is granted, proceed to get the token
    // console.log(vapid_key, 'vapid_key')
    const currentToken = await getToken(messaging, { vapidKey: vapid_key });
    if (currentToken) {
      // console.log(currentToken);
      return currentToken; // Return the token if available
    } else {
      throw new Error('No registration token available.');
    }
  } catch (error) {
    console.error('Error retrieving Firebase token:', error);
    throw new Error('An error occurred while retrieving token. ' + error.message);
  }
};




export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      new Notification(payload?.notification?.title, {
        body: payload?.notification?.body,
        icon: ''
      });
      resolve(payload);
    });
  });