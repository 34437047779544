/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { GetService } from "../../Services/ConstantService";
import { API_URL } from "../../Services/ApiService";
import { showErrorAlert } from "../../Helper/SweetAlert";
import Customloader from "../../Helper/CustomLoader";

export default function PrivacyPolicy() {
  const [loading, setLoading] = useState(false);
  const [PrivacyPolicy, setPrivacyPolicy] = useState("");
  useEffect(() => {
    getPrivacyPolicy();
  }, []);
  const getPrivacyPolicy = () => {
    setLoading(true);
    GetService(`${API_URL.GET_CMS}?type=privacy_policy`).then(
      (res) => {
        if (res.data.status === true && res.data.data) {
          setPrivacyPolicy(res.data.data[0]?.description);
          setLoading(false);
        } else {
          showErrorAlert(res.data?.message);
          setLoading(false);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
        setLoading(false);
      }
    );
  };

  return (
    <div>
      <div className="upcome_race_wrapper float_left leader-board-team">
        <div className="container my-5">
          <div className="row">
            <h4 className="text-center">Privacy Policy</h4>
            <div
              className="white-pre-line"
              dangerouslySetInnerHTML={{ __html: PrivacyPolicy }}
            />
          </div>
        </div>
      </div>
      {loading ? <Customloader /> : ""}
    </div>
  );
}
