/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../Services/ApiService";
import { showErrorAlert, showSuccessAlert } from "../../../Helper/SweetAlert";
import { GetService, PostService } from "../../../Services/ConstantService";
import { ImageURL } from "../../../Environment/Environment";
import { Button, Modal } from "react-bootstrap";
import Customloader from "../../../Helper/CustomLoader";
export default function Leaderboard() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [ConstrutorData, setConstrutorData] = useState();
  const [driverData, setdriverData] = useState();
  const [leaderboard_tab, setleaderboard_tab] = useState("drivers");
  const [selecteditem, setselecteditem] = useState("");
  const [Myraces, setMyraces] = useState("");
  const [mydriverdetails, setmydriverdetails] = useState("");
  const [sortAction, setsortAction] = useState("up");
  const [sortcurrentField, setsortcurrentField] = useState();
  const [LeagueDetail, setLeagueDetail] = useState();

  const { leagueId} = useParams()
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const location = useLocation();
  const getUserList = async () => {
    setLoading(true);
    const route= leagueId? `${API_URL.GET_LEADERBOARD_USERS}?leagueId=${leagueId}`:`${API_URL.GET_LEADERBOARD_USERS}`
    GetService(route).then(
      (res) => {
        if (res.data.status === true) {
          setUserData(res.data.data);
          setLoading(false);
        } else {
          showErrorAlert(res.data?.message);
          setLoading(false);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
        setLoading(false);
      }
    );
  };
  const getConstructorList = async () => {
    setLoading(true);
    GetService(API_URL.GET_LEADERBOARD_CONSTRUCTORS).then(
      (res) => {
        if (res.data.status === true) {
          setConstrutorData(res.data.data);
          setLoading(false);
        } else {
          showErrorAlert(res.data?.message);
          setLoading(false);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
        setLoading(false);
      }
    );
  };

  const getMyRaces = async (id) => {
    setLoading(true);
    const data = {
      id: id,
    };
    const route= leagueId?`${API_URL.My_RACES}?leagueId=${leagueId}`:API_URL.My_RACES 
    PostService(route, data).then(
      (res) => {
        if (res.data.status === true) {
          setMyraces(res.data.data);
          setLoading(false);
        } else {
          showErrorAlert(res.data?.message);
          setLoading(false);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
        setLoading(false);
      }
    );
  };

  const getMyDriverDetails = async (id) => {
    setLoading(true);
    const data = {
      id: id,
    };
    const route =
      leaderboard_tab === "drivers"
        ? API_URL.My_DRIVER_RACES
        : API_URL.My_CONSTRUCTOR_RACES;
    PostService(route, data).then(
      (res) => {
        if (res.data.status === true) {
          setmydriverdetails(res?.data?.data);
          console.log(res?.data?.data?.eventPoints);
          setLoading(false);
        } else {
          showErrorAlert(res.data?.message);
          setLoading(false);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
        setLoading(false);
      }
    );
  };
  const getDriverList = async () => {
    setLoading(true);
    GetService(API_URL.GET_LEADERBOARD_DRIVERS).then(
      (res) => {
        if (res.data.status === true) {
          setdriverData(res.data.data);
          setLoading(false);
        } else {
          showErrorAlert(res.data?.message);
          setLoading(false);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
        setLoading(false);
      }
    );
  };
  function startsWithHttp(url) {
    const regex = /^(http)/;
    return regex.test(url);
  }
  function getTabData() {
    switch (leaderboard_tab) {
      case "user":
        getUserList();
        break;
      case "drivers":
        getDriverList();
        break;
      case "constructors":
        getConstructorList();
        break;
      default:
      // Handle default case or do nothing
    }
  }

  function sortTable(fieldName, order) {
    console.log(sortAction, order, fieldName);
    setsortAction(order);
    setsortcurrentField(fieldName);
    if (leaderboard_tab === "user") {
      const data = [...userData];
      const sortedData =
        order === "up"
          ? sortByAscending(data, fieldName)
          : sortByDescending(data, fieldName);
      setUserData(sortedData);
    } else if (leaderboard_tab === "constructors") {
      const data = [...ConstrutorData];
      const sortedData =
        order === "up"
          ? sortByAscending(data, fieldName)
          : sortByDescending(data, fieldName);
      setConstrutorData(sortedData);
    } else {
      const data = [...driverData];
      const sortedData =
        order === "up"
          ? sortByAscending(data, fieldName)
          : sortByDescending(data, fieldName);
      setdriverData(sortedData);
    }
  }
  useEffect(() => {
    console.log(sortAction, sortcurrentField);
    setsortAction(sortAction);
    setsortcurrentField(sortcurrentField);
  }, [sortAction, sortcurrentField]);

  function sortByAscending(data, fieldName) {
    return data.sort((a, b) => {
      if (a[fieldName] < b[fieldName]) return -1;
      if (a[fieldName] > b[fieldName]) return 1;
      return 0;
    });
  }

  function sortByDescending(data, fieldName) {
    return data.sort((a, b) => {
      if (a[fieldName] < b[fieldName]) return 1;
      if (a[fieldName] > b[fieldName]) return -1;
      return 0;
    });
  }

  const getLeagueDetail = () => {
    const route = `${API_URL.GET_LEAGUE_DETAILS}?leagueId=${leagueId}`;
    GetService(route).then((res) => {
      if (res?.data?.status) {
          setLeagueDetail(res?.data?.data?.league);
      } else {
        showErrorAlert(res?.message, "");
      }
    });
  };

  useEffect(() => {
    getTabData();
    if(leagueId){
      getLeagueDetail()
    }else{
      setLeagueDetail('')
    }
  }, [leaderboard_tab, leagueId]);

  return (
    <div>
      {
        <div
          className={
            (location?.pathname === "/Leaderboard"|| leagueId)
              ? "upcome_race_wrapper float_left leader-board-team mt-5"
              : ""
          }
        >
          <div
            className={(location?.pathname === "/Leaderboard"|| leagueId) ? "container" : ""}
          >
            <div className="row">
              {(location?.pathname === "/Leaderboard"|| leagueId) && (
                <div className="col-md-12">
                  <div className="ft_left_heading_wraper head1 center_headign">
                    <h1>Check {LeagueDetail?LeagueDetail?.name:'Your Season'} Leaderboard &amp; Team</h1>
                  </div>
                </div>
              )}
            </div>
            <div
              className={
                (location?.pathname === "/Leaderboard"|| leagueId)
                  ? "col-xl-12 col-lg-12 col-md-12"
                  : ""
              }
            >
              <div
                className={
                  (location?.pathname === "/Leaderboard"|| leagueId)
                    ? "tab-content select-player-2"
                    : ""
                }
              >
                <div id="leaderboard" className="tab-pane active leaderboard">
                  <div className="row">
                    <div className="col-md-12 col-sm-12 offset-lg-2">
                     {!leagueId && (
                      <div className="x_offer_tabs_wrapper tab_online">
                        <ul className="nav nav-tabs">
                          {/* <li className="nav-item flex-grow-none">
                            {" "}
                            <a
                              className={
                                leaderboard_tab === "user"
                                  ? "nav-link active show"
                                  : "nav-link"
                              }
                              onClick={() => setleaderboard_tab("user")}
                            >
                              Users
                            </a>
                          </li> */}
                          <li className="nav-item flex-grow-none">
                            {" "}
                            <a
                              className={
                                leaderboard_tab === "drivers"
                                  ? "nav-link active show"
                                  : "nav-link"
                              }
                              onClick={() => setleaderboard_tab("drivers")}
                            >
                              Drivers
                            </a>
                          </li>
                          <li className="nav-item flex-grow-none">
                            {" "}
                            <a
                              className={
                                leaderboard_tab === "constructors"
                                  ? "nav-link active show"
                                  : "nav-link"
                              }
                              onClick={() => setleaderboard_tab("constructors")}
                            >
                              Constructors
                            </a>
                          </li>
                        </ul>
                      </div>
                     )} 
                      <div className="tab-content select-player">
                        {leaderboard_tab === "drivers" && (
                          <div
                            id="drivers-leader"
                            className="tab-pane active show drivers-leader-table"
                          >
                            <div className="row">
                              <div className="col-lg-8 col-md-12 col-sm-12">
                                <div className="table_next_race border table_view_leaderboard">
                                  <table className="add-new-ch">
                                    <tbody>
                                      <tr>
                                        <th>Rank</th>
                                        <th>All Drivers</th>
                                        <th
                                          onClick={() =>
                                            sortTable(
                                              "points",
                                              sortAction === "up"
                                                ? "down"
                                                : "up"
                                            )
                                          }
                                        >
                                          Pts{" "}
                                          <i
                                            class={
                                              (sortAction === "up" &&
                                                sortcurrentField ===
                                                  "points") ||
                                              (sortAction === "up" &&
                                                !sortcurrentField)
                                                ? "fa-solid sorting_icon fa-arrow-up"
                                                : "fa-solid sorting_icon fa-arrow-down"
                                            }
                                            onClick={() =>
                                              sortTable(
                                                "points",
                                                sortAction === "up"
                                                  ? "down"
                                                  : "up"
                                              )
                                            }
                                          ></i>
                                        </th>
                                      </tr>
                                      {driverData &&
                                        driverData?.map((res, i) => (
                                          <tr>
                                            <td>
                                              <span className="mr-3">
                                                #{res?.rank}
                                              </span>
                                            </td>
                                            <td
                                              data-toggle="modal"
                                              data-target="#exampleModal5"
                                            >
                                              <div className="d-flex justify-content-between">
                                                <img
                                                  className=""
                                                  src={
                                                    startsWithHttp(res?.image)
                                                      ? res?.image
                                                      : ImageURL + res?.image
                                                  }
                                                  alt=""
                                                  width="75px"
                                                  height="75px"
                                                  style={{
                                                    objectFit: "contain",
                                                  }}
                                                />
                                                <span
                                                  className=""
                                                  onClick={() => {
                                                    setselecteditem(res);
                                                    getMyDriverDetails(res?.id);
                                                    handleShow();
                                                  }}
                                                >
                                                  {res?.name}
                                                </span>
                                                <img
                                                  className="car-align"
                                                  src={
                                                    startsWithHttp(
                                                      res?.constructor.image
                                                    )
                                                      ? res?.constructor.image
                                                      : ImageURL +
                                                        res?.constructor.image
                                                  }
                                                  width="189px"
                                                  alt="team-logo"
                                                  onError={(e) => {
                                                    e.target.src =
                                                      "images/racing/car1.png";
                                                  }}
                                                />
                                              </div>
                                            </td>
                                            <td>{res?.points? res?.points?.toLocaleString():''}</td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {leaderboard_tab === "user" && (
                          <div
                            id="losing-leader"
                            className="tab-pane active show losing-leader-table"
                          >
                            <div className="row">
                              <div className="col-lg-8 col-md-12 col-sm-12">
                                <div className="table_next_race border table_view_leaderboard">
                                  <table>
                                    <tbody>
                                      <tr>
                                        <th>Rank</th>
                                        <th>All Users</th>
                                        <th>
                                          Pts{" "}
                                          <i
                                            class={
                                              (sortAction === "up" &&
                                                sortcurrentField ===
                                                  "points") ||
                                              (sortAction === "up" &&
                                                !sortcurrentField)
                                                ? "fa-solid sorting_icon fa-arrow-up"
                                                : "fa-solid sorting_icon fa-arrow-down"
                                            }
                                            onClick={() =>
                                              sortTable(
                                                "points",
                                                sortAction === "up"
                                                  ? "down"
                                                  : "up"
                                              )
                                            }
                                          ></i>
                                        </th>
                                        <th>Record</th>
                                      </tr>
                                      {userData &&
                                        userData?.map((res, i) => (
                                          <tr>
                                            <td>
                                              <span className="mr-3">
                                                # {res?.rank}
                                              </span>
                                            </td>
                                            <td
                                              data-toggle="modal"
                                              data-target="#exampleModal5"
                                            >
                                              <div className="d-flex ">
                                                <span className="team">
                                                  <img
                                                    src={
                                                      startsWithHttp(res?.image)
                                                        ? res?.image
                                                        : ImageURL + res?.image
                                                    }
                                                    alt="team-logo"
                                                    width="97px"
                                                    height="64px"
                                                    style={{
                                                      objectFit: "contain",
                                                    }}
                                                    onError={(e) => {
                                                      e.target.src =
                                                        "images/racing/user.svg";
                                                    }}
                                                  />
                                                </span>
                                                <span
                                                  className=" set-name-width"
                                                  onClick={() => {
                                                    setselecteditem(res);
                                                    getMyRaces(res?._id);
                                                    handleShow();
                                                  }}
                                                >
                                                  <p>{res?.team?.name ? res?.team?.name: res?.name}</p>
                                                </span>
                                              </div>
                                            </td>
                                            <td>{res?.points? res?.points?.toLocaleString():'--'}</td>
                                            <td>
                                              <span className="bg_theme">
                                                {res?.raceWins}-
                                                {res?.totalRaces -
                                                  res?.raceWins}
                                              </span>
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {leaderboard_tab === "constructors" && (
                          <div
                            id="constructors-leader"
                            class="tab-pane active show constructors-table"
                          >
                            <div class="row">
                              <div class="col-lg-8 col-md-12 col-sm-12">
                                <div class="table_next_race border table_view_leaderboard">
                                  <table>
                                    <tbody>
                                      <tr>
                                        <th>Rank</th>
                                        <th>All Constructors</th>
                                        <th></th>
                                        <th className="text-center">
                                          Pts{" "}
                                          <i
                                            class={
                                              (sortAction === "up" &&
                                                sortcurrentField ===
                                                  "points") ||
                                              (sortAction === "up" &&
                                                !sortcurrentField)
                                                ? "fa-solid sorting_icon fa-arrow-up"
                                                : "fa-solid sorting_icon fa-arrow-down"
                                            }
                                            onClick={() =>
                                              sortTable(
                                                "points",
                                                sortAction === "up"
                                                  ? "down"
                                                  : "up"
                                              )
                                            }
                                          ></i>
                                        </th>
                                      </tr>
                                      {ConstrutorData &&
                                        ConstrutorData?.map((res, i) => (
                                          <tr>
                                            <td>
                                              <span class="mr-3">
                                                # {i + 1}
                                              </span>
                                            </td>
                                            <td
                                              class="Constructors-design"
                                              data-toggle="modal"
                                              data-target="#exampleModal5"
                                            >
                                              <span
                                                class="mr-3"
                                                onClick={() => {
                                                  setselecteditem(res);
                                                  getMyDriverDetails(res?.id);
                                                  handleShow();
                                                }}
                                              >
                                                {res?.name}
                                              </span>
                                            </td>
                                            <td>
                                              <img
                                                class="car-align"
                                                src={
                                                  startsWithHttp(res?.image)
                                                    ? res?.image
                                                    : ImageURL + res?.image
                                                }
                                                width="189px"
                                                alt="team-logo"
                                              />
                                            </td>
                                            <td>{res?.points}</td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                                {loading ? <Customloader /> : ""}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {loading ? <Customloader /> : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>View Performance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <div className>
              <div className="login__heading">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  {/* <span aria-hidden="true">×</span> */}
                </button>
                <div className="panel panel-default">
                  <div className="panel-body ">
                    <div className="row mb-3">
                      <div
                        className="align-items-center bg-body-tertiary col-lg-4 col-sm-12 d-flex justify-content-center overflow-hidden py-2 rounded-25"
                        style={{ maxHeight: "250px" }}
                      >
                        <img
                          src={
                            startsWithHttp(selecteditem?.image)
                              ? selecteditem?.image
                              : ImageURL + selecteditem?.image
                          }
                          className={
                            leaderboard_tab === "constructors"
                              ? "img-responsive"
                              : "img-responsive h-100"
                          }
                          alt="logo"
                          onError={(e) => {
                            e.target.src = "images/racing/user.svg";
                          }}
                        />
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 mx-2 py-2">
                        <div className="mt-lg-0 mt-md-2 popup-head-list">
                          <h3 style={{ color: "#1C4BA5 !important" }}>
                            {selecteditem?.name}
                          </h3>
                          {leaderboard_tab === "drivers" && (
                            <div>
                              <p className="pt-2">Driver Value</p>
                              <h4>${selecteditem?.driverFunds}M</h4>
                            </div>
                          )}
                          {leaderboard_tab === "constructors" && (
                            <div>
                              <p className="pt-2">constructor Value</p>
                              <h4>${selecteditem?.funds}M</h4>
                            </div>
                          )}
                        </div>
                        <div className="d-flex mt-3">
                          <div className="popup-head-list-2 mr-1">
                            <p>Season Points</p>
                            <h4>{selecteditem?.points} Pts</h4>
                          </div>
                          {leaderboard_tab !== "user" && (
                            <div className="popup-head-list-2 ml-1">
                              <p>Percentage Picked</p>
                              <h4>
                                {mydriverdetails?.selectedDriverpercentage
                                  ? mydriverdetails?.selectedDriverpercentage
                                  : mydriverdetails?.selectedConstructorPercentage
                                  ? mydriverdetails?.selectedConstructorPercentage
                                  : "--"}{" "}
                                %
                              </h4>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table_next_race ">
                  <table>
                    <tbody>
                      <tr>
                        <th>Race</th>
                        <th>Rank</th>
                        <th>PTS</th>
                      </tr>
                      {leaderboard_tab === "user" && (
                        <>
                          <tr>
                            {Myraces?.length === 0 && (
                              <td
                                colSpan={3}
                                className="text-center font-weight-bold"
                              >
                                Season is not started yet !
                              </td>
                            )}
                          </tr>
                          {Myraces &&
                            Myraces?.map((res, i) => (
                              <tr key={i}>
                                <td>{res?.name}</td>
                                <td>
                                  {res?.teams
                                    ? res?.teams[0]?.teamEventPoints[0]
                                        ?.raceTeamRank
                                    : 1}
                                </td>
                                <td>
                                  {res?.teams
                                    ? res?.teams[0]?.teamEventPoints[0]
                                        ?.raceTeamPoints
                                    : 0}
                                </td>
                              </tr>
                            ))}
                        </>
                      )}
                      {(leaderboard_tab === "drivers" ||
                        leaderboard_tab === "constructors") && (
                        <>
                          <tr>
                            {mydriverdetails?.eventPoints?.length === 0 && (
                              <td
                                colSpan={3}
                                className="text-center font-weight-bold"
                              >
                                Season is not started yet !
                              </td>
                            )}
                          </tr>
                          {mydriverdetails &&
                            mydriverdetails?.eventPoints?.map((res, i) => (
                              <tr key={i}>
                                <td>{res?.raceId?.name}</td>
                                <td>{res?.raceRank ? res?.raceRank : 1}</td>
                                <td>{res?.racePoints ? res?.racePoints : 0}</td>
                              </tr>
                            ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <p />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
