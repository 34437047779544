/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../Services/ApiService";
import Modal from "react-bootstrap/Modal";
import {
  showCreateTeam,
  showErrorAlert,
  showSuccessAlert,
  showPreviourTeam,
  showSuccesstoaster,
  showOopsPopup,
  showHappypopup,
} from "../../../Helper/SweetAlert";
import { GetService, PostService } from "../../../Services/ConstantService";
import { ImageURL } from "../../../Environment/Environment";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Customloader from "../../../Helper/CustomLoader";

export default function Createteam() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [userteam, setuserteam] = useState();
  const [isNOCap, setisNOCap] = useState(false);
  const [ConstrutorData, setConstrutorData] = useState();
  const [driverData, setdriverData] = useState();
  const [Createteam_tab, setCreateteam_tab] = useState("drivers");
  const [selectedDriver, setselectedDriver] = useState([]);
  const [selectedConstructor, setselectedConstructor] = useState([]);
  const [selectedLosing, setselectedLosing] = useState("");
  const [selectBoosterx, setselectBoosterx] = useState("2x");
  const [Remainingfunds, setRemainingfunds] = useState(0);
  const [UserDetails, setUserDetails] = useState("");
  const [boasterplayer, setboasterplayer] = useState("");
  const [boasterplayer_3x, setboasterplayer__3x] = useState("");
  const [driverCounter3x, setdriverCounter3x] = useState();
  const [TeamName, setTeamName] = useState("");
  const [show, setShow] = useState(false);
  const [showNoCap, setshowNoCap] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [showteampopup, setshowteampopup] = useState(false);
  const [LeagueRules, setLeagueRules] = useState(false);
  const [LosingDriver, setLosingDriver] = useState(true);
  const [sortAction, setsortAction] = useState("up");
  const [sortcurrentField, setsortcurrentField] = useState();
  const [LeagueDetail, setLeagueDetail] = useState();
  const [Selected_pairDriverId, setSelected_pairDriverId] = useState("");
  const [shouldCreateNoCap, setshouldCreateNoCap] = useState(false);

  const [Totalcap, setTotalcap] = useState(125);
  const { id, inviteCode } = useParams();
  const userdata = JSON.parse(localStorage.getItem("userdata"));
  const handleClose = () => setShow(false);
  const handlepopupClose = () => setshowteampopup(false);
  const handleshowNoCap = () => setshowNoCap(false);
  const handleLeagueRules = () => setLeagueRules(false);
  const handleShow = () => {
    const isLosingDriverLeague = LeagueDetail?.isLosingDriver;
    const condition1 =
      selectedDriver?.length === 5 &&
      selectedConstructor?.length === 2 &&
      selectedLosing;

    const condition2 =
      selectedDriver?.length === 5 && selectedConstructor?.length === 2;

    if (LeagueDetail) {
      if (isLosingDriverLeague ? condition1 : condition2) {
        setShow(true);
        setselectBoosterx("2x");
      }
    } else if (condition1) {
      setShow(true);
      setselectBoosterx("2x");
    }
  };

  useEffect(()=>{
   console.log(Selected_pairDriverId)
  },[Selected_pairDriverId])

  const getConstructorList = async () => {
    setLoading(true);
    GetService(API_URL.GET_CONTRUCTOR).then(
      (res) => {
        console.log(res);
        if (res.data.status === true) {
          setConstrutorData(res.data.data);
          setLoading(false);
        } else {
          showErrorAlert(res.data?.message);
          setLoading(false);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
        setLoading(false);
      }
    );
  };
  const getDriverList = async () => {
    setLoading(true);
    GetService(API_URL.GET_DRIVERS).then(
      (res) => {
        console.log(res);
        if (res.data.status === true) {
          setdriverData(res.data.data);
          setLoading(false);
        } else {
          showErrorAlert(res.data?.message);
          setLoading(false);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
        setLoading(false);
      }
    );
  };
  const handleSelectItem = (item) => {
    const itemId = item._id;

    switch (Createteam_tab) {
      case "drivers":
        if (
          selectedDriver.length < 5 &&
          !selectedDriver.some((driver) => driver._id === itemId) &&
          Remainingfunds + (item?.funds || 0) <= Totalcap
        ) {
          setselectedDriver((prevSelected) => [...prevSelected, item]);
        }
        break;
      case "Constructors":
        if (
          selectedConstructor.length < 2 &&
          !selectedConstructor.some(
            (constructor) => constructor._id === itemId
          ) &&
          Remainingfunds + (item?.funds || 0) <= Totalcap
        ) {
          setselectedConstructor((prevSelected) => [...prevSelected, item]);
        }
        break;
      case "losing_driver":
        setselectedLosing(item);
        break;
      default:
        break;
    }
  };

  const removeFromSelectedDriver = (itemId) => {
    const driverIndex = selectedDriver.findIndex(
      (driver) => driver?._id === itemId
    );
    if (driverIndex !== -1) {
      const updatedSelectedDriver = [...selectedDriver];
      updatedSelectedDriver.splice(driverIndex, 1);
      setselectedDriver(updatedSelectedDriver);
    }
  };

  const removeFromSelectedConstructor = (itemId) => {
    const constructorIndex = selectedConstructor.findIndex(
      (constructor) => constructor?._id === itemId
    );
    if (constructorIndex !== -1) {
      const updatedSelectedConstructor = [...selectedConstructor];
      updatedSelectedConstructor.splice(constructorIndex, 1);
      setselectedConstructor(updatedSelectedConstructor);
    }
  };

  const removeFromSelectedLosing = () => {
    setselectedLosing("");
  };

  useEffect(() => {
    const team = [...selectedDriver, ...selectedConstructor].filter(Boolean);
    const totalFunds = team.reduce(
      (sum, player) => sum + (player?.funds || 0),
      0
    );

    setRemainingfunds(totalFunds);
  }, [selectedDriver, selectedConstructor, selectedLosing]);

  const isDriverSelected = (id) => {
    return selectedDriver.some((driver) => driver._id === id);
  };

  const isConstrutorSelected = (id) => {
    return selectedConstructor.some((i) => i._id === id);
  };

  function startsWithHttp(url) {
    const regex = /^(http)/;
    return regex.test(url);
  }
  function getTabData() {
    switch (Createteam_tab) {
      case "losing_driver":
        getDriverList();
        break;
      case "drivers":
        getDriverList();
        break;
      case "Constructors":
        getConstructorList();
        break;

      case "Pairs":
        getConstructorList();
        break;
      default:
      // Handle default case or do nothing
    }
  }
  function submitname() {
    setShow(false);
    setshowteampopup(true);
  }
  function handleTeam() {
    setSubmit(true);
    if (!TeamName) {
      return;
    }
    if (id && TeamName) {
      updateTeam();
    } else {
      create_team();
    }
  }
  const create_team = async () => {
    const driverIds = selectedDriver.map((driver) => driver._id);
    const constructorIds = selectedConstructor.map(
      (constructor) => constructor._id
    );
    const loserId = selectedLosing?._id;
    const data = {
      ownerId: UserDetails?._id,
      driverIds: driverIds,
      constructorIds: constructorIds,
      loserId: loserId,
      availableFunds: Totalcap,
      name: TeamName,
      captainId: boasterplayer ? boasterplayer?._id : "",
      captainId3x: boasterplayer_3x ? boasterplayer_3x?._id : "",
      pairDriverId: Selected_pairDriverId?._id
        ? Selected_pairDriverId?._id
        : "",
    };

    if (isNOCap && !inviteCode) {
      data.isNoCap = true;
    } else if (isNOCap && inviteCode) {
      data.league_isNoCap = true;
    }
    if (inviteCode) {
      data.isleagueTeam = true;
    }

    PostService(API_URL.CREATE_TEAM, data).then((res) => {
      if (res?.data?.status) {
        handlepopupClose();
        setSubmit(false);
        userdata.history = true;
        localStorage.setItem("userdata", JSON.stringify(userdata));
        console.log(res?.data);
        if (inviteCode) {
          console.log(res?.data);
          joinLeague(res?.data?.data?.newTeam?._id);
        } else {
          showCreateTeam(res?.data?.message, "My Teams").then((response) => {
            if (response.isConfirmed) {
              navigate("/my_team");
            }
          });
        }

        getuser();
      } else {
        showErrorAlert(res?.data?.message, "");
      }
    });
  };

  const joinLeague = (teamid) => {
    PostService(API_URL.JOIN_LEAGUE, {
      team_id: teamid,
      inviteCode: inviteCode,
    }).then((res) => {
      if (res?.data?.status) {
        showCreateTeam(
          `Your team has been created and has joined the ${LeagueDetail?.name} !`,
          "back to League"
        ).then((res) => {
          if (res) {
            navigate("/leagues");
          }
        });
      } else {
        showErrorAlert(res?.message, "");
      }
    });
  };
  const getuser = () => {
    const user_id = JSON.parse(localStorage.getItem("userdata"));
    if (user_id) {
      PostService(API_URL.USER_DETAIL, { id: user_id?.id }).then((res) => {
        if (res?.data?.status) {
          setdriverCounter3x(res?.data?.data?.driverCounter3x);
          if (id) {
            getteamview(id);
          }
          reset();
          setUserDetails(res?.data?.data);
        } else {
          showErrorAlert(res?.message, "");
        }
      });
    }
  };

  const getLeagueDetail = () => {
    const route =
      inviteCode?.length > 6
        ? `${API_URL.GET_LEAGUE_DETAILS}?leagueId=${inviteCode}`
        : `${API_URL.GET_LEAGUE_DETAILS}?inviteCode=${inviteCode}`;
    GetService(route).then((res) => {
      if (res?.data?.status) {
        setshouldCreateNoCap(res?.data?.data?.shouldCreateNoCap);
        if (
          (!res?.data?.data?.userParticipates &&
            location?.pathname?.split("/")[1] === "create_team") ||
          (res?.data?.data?.userParticipates &&
            location?.pathname?.split("/")[1] === "edit_team")
        ) {
          if (res?.data?.data?.isLeagueOpen === true) {
            setLeagueDetail(res?.data?.data?.league);
            setLeagueRules(true);
          } else if (res?.data?.data?.isLeagueOpen === false) {
            showOopsPopup("This League is Closed", "Back to League").then(
              (res) => {
                console.log(res);
                if (res?.isConfirmed || res?.isDismissed) {
                  navigate("/leagues");
                }
              }
            );
          }
        } else if (res?.data?.data?.userParticipates === true) {
          showOopsPopup(
            "You have Already Joined this League!",
            "back to Leagues"
          ).then((res) => {
            if (res?.isConfirmed || res?.isDismissed) {
              navigate("/leagues");
            }
          });
        } else {
          showErrorAlert("Something went wrong with League !");
        }
      } else {
        showErrorAlert(res?.message, "");
      }
    });
  };

  useEffect(() => {
    if (inviteCode) {
      getLeagueDetail();
    }
  }, [inviteCode]);
  function selectbooter3Xor2X() {
    if (
      boasterplayer?._id === boasterplayer_3x?._id &&
      selectBoosterx === "3x"
    ) {
      console.log("case1");
      setboasterplayer__3x(boasterplayer_3x);
      setboasterplayer("");
    } else if (
      boasterplayer?._id === boasterplayer_3x?._id &&
      selectBoosterx === "2x"
    ) {
      console.log("case2");
      setboasterplayer(boasterplayer);
      setboasterplayer__3x("");
    } else if (selectBoosterx === "2x") {
      console.log("case3");
      setboasterplayer(boasterplayer);
    } else {
      console.log("case4");
      setboasterplayer__3x(boasterplayer_3x);
    }
  }

  useEffect(() => {
    selectbooter3Xor2X();
  }, [boasterplayer, boasterplayer_3x]);

  const getteamview = async (id) => {
    setLoading(true);
    const data = {
      id: id,
    };
    PostService(API_URL.GET_TEAM_BY_ID, data).then(
      (res) => {
        if (res.data.status === true) {
          setuserteam(res?.data?.data);
          setLoading(false);
          setselectedDriver(res?.data?.data?.driverIds);
          setselectedConstructor(res?.data?.data?.constructorIds);
          setselectedLosing(res?.data?.data?.loserId);
          if (res?.data?.data?.isNoCap) {
            switchtoNocap();
          }
          const captainfilter = res?.data?.data?.driverIds.filter(
            (res) => res?.isCaptain
          );
          const captain_3xfilter = res?.data?.data?.driverIds.filter(
            (res) => res?.is3xCaptain
          );
          setboasterplayer(captainfilter ? captainfilter[0] : "");
          setboasterplayer__3x(captain_3xfilter ? captain_3xfilter[0] : "");
          setTeamName(res?.data?.data?.name);
        } else {
          showErrorAlert(res.data?.message);
          setLoading(false);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
        setLoading(false);
      }
    );
  };
  function updateTeam() {
    const driverIds = selectedDriver.map((driver) => driver._id);
    const constructorIds = selectedConstructor.map(
      (constructor) => constructor._id
    );
    const loserId = selectedLosing?._id;
    const data = {
      ownerId: UserDetails?._id,
      driverIds: driverIds,
      constructorIds: constructorIds,
      loserId: loserId,
      availableFunds: Totalcap,
      name: TeamName,
      captainId: boasterplayer ? boasterplayer?._id : "",
      teamId: id,
      captainId3x: boasterplayer_3x ? boasterplayer_3x?._id : "",
      pairDriverId: Selected_pairDriverId?._id
        ? Selected_pairDriverId?._id
        : "",
    };
    if (isNOCap && !inviteCode) {
      data.isNoCap = true;
    } else if (isNOCap && inviteCode) {
      data.league_isNoCap = true;
    }
    if (inviteCode) {
      data.isleagueTeam = true;
    }
    PostService(API_URL.UPDATE_TEAM, data).then((res) => {
      if (res?.data?.status) {
        handlepopupClose();
        setSubmit(false);
        showSuccessAlert(res?.data?.message, "");
        navigate("/my_team");
      } else {
        showErrorAlert(res?.data?.message, "");
      }
    });
  }
  function sortTable(fieldName, order) {
    console.log(sortAction, order, fieldName);
    setsortAction(order);
    setsortcurrentField(fieldName);
    if (Createteam_tab === "drivers") {
      const data = [...driverData];
      const sortedData =
        order === "up"
          ? sortByAscending(data, fieldName)
          : sortByDescending(data, fieldName);
      setdriverData(sortedData);
    } else if (Createteam_tab === "Constructors") {
      const data = [...ConstrutorData];
      const sortedData =
        order === "up"
          ? sortByAscending(data, fieldName)
          : sortByDescending(data, fieldName);
      setConstrutorData(sortedData);
    } else {
      const data = [...driverData];
      const sortedData =
        order === "up"
          ? sortByAscending(data, fieldName)
          : sortByDescending(data, fieldName);
      setdriverData(sortedData);
    }
  }
  useEffect(() => {
    console.log(sortAction);
    setsortAction(sortAction);
    setsortcurrentField(sortcurrentField);
  }, [sortAction, sortcurrentField]);

  function sortByAscending(data, fieldName) {
    return data.sort((a, b) => {
      const aValue = !isNaN(a[fieldName])
        ? parseFloat(a[fieldName])
        : a[fieldName];
      const bValue = !isNaN(b[fieldName])
        ? parseFloat(b[fieldName])
        : b[fieldName];

      if (aValue < bValue) return -1;
      if (aValue > bValue) return 1;
      return 0;
    });
  }

  function sortByDescending(data, fieldName) {
    return data.sort((a, b) => {
      const aValue = !isNaN(a[fieldName])
        ? parseFloat(a[fieldName])
        : a[fieldName];
      const bValue = !isNaN(b[fieldName])
        ? parseFloat(b[fieldName])
        : b[fieldName];

      if (aValue < bValue) return 1;
      if (aValue > bValue) return -1;
      return 0;
    });
  }

  function reset() {
    setselectedDriver([]);
    setselectedConstructor([]);
    setselectedLosing("");
  }
  useEffect(() => {
    getTabData();
  }, [Createteam_tab]);

  useEffect(() => {
    getuser();
    console.log(id);
    // showCreateTeam('Team created Succefully','')
  }, []);
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip
      arrow
      placement="right-start"
      {...props}
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#000",
      color: "#fff",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(10),
      padding: "20px",
      borderTopRightRadius: "25px",
      borderBottomLeftRadius: "25px",
      textTransform: "capitalize",
    },
    "& .MuiTooltip-arrow": {
      color: "#000",
    },
  }));

  const tryOnCap = () => {
    setshowNoCap(true);
    // setOnCap(true);
  };

  const switchtoNocap = () => {
    setTotalcap(1000);
    setisNOCap(true);
    setboasterplayer__3x("");
    setshowNoCap(false);
  };

  const continueWithLeague = () => {
    setTotalcap(LeagueDetail?.salarycap);
    // setisNOCap(LeagueDetail?.maxNocap);
    setdriverCounter3x(LeagueDetail?.max3xDriver === 2 ? 0 : 2);
    setLosingDriver(LeagueDetail?.isLosingDriver);
    setCreateteam_tab("drivers");
    handleLeagueRules();
    setisNOCap(userteam?.isNoCap);
  };

  const previousStateto125 = () => {
    console.log(userteam);
    if (selectedDriver && selectedConstructor && userteam) {
      const driverChanged = selectedDriver.some(
        (driver) =>
          !userteam.driverIds.find(
            (userDriver) => userDriver._id === driver._id
          )
      );
      console.log(driverChanged);
      const constructorChanged = selectedConstructor.some(
        (constructor) =>
          !userteam.constructorIds.find(
            (userConstructor) => userConstructor._id === constructor._id
          )
      );
      if (
        userteam.loserId?._id !== selectedLosing?._id ||
        driverChanged ||
        constructorChanged ||
        userteam?.isNoCap
      ) {
        showPreviourTeam().then((result) => {
          if (result.isConfirmed) {
            if (userteam?.isNoCap) {
              console.log("first");
              getPreviousTeam();
            } else {
              console.log("second");
              setLoading(true);
              setTotalcap(125);
              setisNOCap(false);
              window.location.reload();
            }
          } else {
            console.log("Continue");
          }
        });
      } else {
        setTotalcap(125);
        setisNOCap(false);
      }
    } else if (LeagueDetail) {
      console.log("league");
      continueWithLeague();
    }
  };

  const getPreviousTeam = () => {
    const data = {
      id: id,
    };

    PostService(API_URL.GET_PREVIOUS_TEAM, data).then((res) => {
      if (res?.data?.status) {
        showSuccesstoaster("switched to previous team");
        navigate(`/edit_team/${res?.data?.data?.previousTeamId}`);
        setLoading(true);
        setTotalcap(125);
        setisNOCap(false);
        window.location.reload();
      } else {
        showErrorAlert(res?.data?.message, "");
      }
    });
  };



  return (
    <div>
      {/*upcoming race wrapper start*/}
      <div className="upcome_race_wrapper float_left leader-board-team mt-5">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="tab-content select-player-2">
                <div id="team" className="tab-pane active team">
                  <div className="row">
                    {/*Createteam team tab*/}
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <div className="tab-content select-player-2">
                        <div id="team" className="tab-pane active team">
                          <div className="row mb-2">
                            <div className="col-12 col-lg-6">
                              <div className="select-player d-flex mt-2 mt-lg-0">
                                <p className="font-weight-bold fs-1 text-danger special_font">
                                  3X
                                </p>
                                <div className="pl-4">
                                  {LeagueDetail && LeagueDetail?.max3xDriver ? (
                                    <p className="font-weight-bold fs-6 text-danger mb-0">
                                      {2 -
                                      ((LeagueDetail?.userdetails?.length === 1
                                        ? LeagueDetail?.userdetails[0]
                                            ?.league_3xdriver
                                        : 0) !==
                                        -1)
                                        ? ` Remaining ${
                                            2 -
                                            (LeagueDetail?.userdetails
                                              ?.length === 1
                                              ? LeagueDetail?.userdetails[0]
                                                  ?.league_3xdriver
                                              : 0)
                                          }`
                                        : "Not Available"}
                                    </p>
                                  ) : (
                                    LeagueDetail &&
                                    !LeagueDetail?.max3xDriver && (
                                      <p className="font-weight-bold fs-6 text-danger mb-0">
                                        Not Available for this League
                                      </p>
                                    )
                                  )}
                                  {!LeagueDetail && (
                                    <p className="font-weight-bold fs-6 text-danger mb-0">
                                      {2 - driverCounter3x === -1
                                        ? "Not Available"
                                        : `Remaining ${2 - driverCounter3x}`}
                                    </p>
                                  )}
                                  <p className="font_smaller opacity-50">
                                    Experience the thrill of the 3X Booster
                                    driver. Elevate your fantasy F1 team with
                                    triple the driving excellence.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-lg-6">
                              <div className="select-player d-flex mt-2 mt-lg-0">
                                <p className="font-weight-bold fs-1 text-primary special_font">
                                  NO CAP
                                </p>
                                <div className="pl-4">
                                  <p className="mb-0 font_smaller opacity-50">
                                    Choose your team without a salary cap!
                                  </p>
                                  {!LeagueDetail && (
                                    <button
                                      className="btn btn-primary px-3 py-1 rounded-25 mt-2 font_smaller"
                                      disabled={
                                        !isNOCap && UserDetails?.isNoCap
                                      }
                                      onClick={
                                        !isNOCap && !UserDetails?.isNoCap
                                          ? tryOnCap
                                          : (isNOCap && UserDetails?.isNoCap) ||
                                            (isNOCap && !UserDetails?.isNoCap)
                                          ? previousStateto125
                                          : console.log("not working")
                                      }
                                    >
                                      {!isNOCap && !UserDetails?.isNoCap
                                        ? "Remaining 1"
                                        : (isNOCap && UserDetails?.isNoCap) ||
                                          (isNOCap && !UserDetails?.isNoCap)
                                        ? "Back to Previous"
                                        : "Already Used"}
                                    </button>
                                  )}

                                  {LeagueDetail && LeagueDetail?.maxNocap && (
                                    <div className="align-items-baseline d-flex">
                                      <button
                                        className="btn btn-primary px-3 py-1 rounded-25 mt-2 font_smaller"
                                        disabled={
                                          (!isNOCap &&
                                            LeagueDetail?.userdetails[0]
                                              ?.league_isNoCap === true) ||
                                          !shouldCreateNoCap
                                        }
                                        onClick={() => {
                                          if (shouldCreateNoCap) {
                                            if (
                                              !isNOCap &&
                                              LeagueDetail?.userdetails[0]
                                                ?.league_isNoCap === false
                                            ) {
                                              tryOnCap();
                                            } else if (
                                              isNOCap &&
                                              (LeagueDetail?.userdetails[0]
                                                ?.league_isNoCap === true ||
                                                LeagueDetail?.userdetails[0]
                                                  ?.league_isNoCap === false)
                                            ) {
                                              previousStateto125();
                                            }
                                          }
                                        }}
                                      >
                                        {(!isNOCap &&
                                          LeagueDetail?.userdetails[0]
                                            ?.league_isNoCap === false) ||
                                        !shouldCreateNoCap
                                          ? "Remaining 1"
                                          : (isNOCap &&
                                              LeagueDetail?.userdetails[0]
                                                ?.league_isNoCap === true) ||
                                            (isNOCap &&
                                              LeagueDetail?.userdetails[0]
                                                ?.league_isNoCap === false)
                                          ? "Back to Previous"
                                          : "Already Used"}
                                      </button>
                                      {shouldCreateNoCap === false && (
                                        <HtmlTooltip
                                          title={
                                            <React.Fragment>
                                              <Typography color="inherit">
                                                You are not allowed to use it
                                                initially for this league.
                                              </Typography>
                                            </React.Fragment>
                                          }
                                        >
                                          <Button
                                            sx={{ m: 1 }}
                                            className="custom_tooltip bg-body ml-2 px-2 py-0 rounded-2 text-black curson_pointer border-0"
                                          >
                                            i
                                          </Button>
                                        </HtmlTooltip>
                                      )}
                                    </div>
                                  )}

                                  {LeagueDetail && !LeagueDetail?.maxNocap && (
                                    <button
                                      className="btn btn-primary px-3 py-1 rounded-25 mt-2 font_smaller"
                                      disabled={!LeagueDetail?.maxNocap}
                                    >
                                      Not Available for this League
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12">
                              <div className="select-player">
                                <div className="x_offer_tabs_wrapper tab_online">
                                  <ul className="nav nav-tabs">
                                    <li
                                      className={`flex-grow-1 ${ LosingDriver
                                        ? "nav-item"
                                        : "nav-item w-50"}`
                                        
                                      }
                                    >
                                      <a
                                        className={
                                          Createteam_tab === "drivers"
                                            ? "nav-link active show"
                                            : "nav-link"
                                        }
                                        data-toggle="tab"
                                        onClick={() => {
                                          setCreateteam_tab("drivers");
                                        }}
                                      >
                                        Drivers
                                      </a>
                                    </li>
                                    <li
                                      className={
                                        LosingDriver
                                          ? "nav-item flex-grow-1"
                                          : "nav-item w-50"
                                      }
                                    >
                                      {" "}
                                      <a
                                        className={
                                          Createteam_tab === "Constructors"
                                            ? "nav-link active show"
                                            : "nav-link"
                                        }
                                        onClick={() => {
                                          setCreateteam_tab("Constructors");
                                        }}
                                      >
                                        Constructors
                                      </a>
                                    </li>
                                    {LosingDriver && (
                                      <li className="nav-item flex-grow-1">
                                        {" "}
                                        <a
                                          className={
                                            Createteam_tab === "losing_driver"
                                              ? "nav-link active show"
                                              : "nav-link"
                                          }
                                          onClick={() => {
                                            setCreateteam_tab("losing_driver");
                                          }}
                                        >
                                          Losing Driver
                                        </a>
                                      </li>
                                    )}
                                    <li className="nav-item flex-grow-1">
                                      {" "}
                                      <a
                                        className={
                                          Createteam_tab === "Pairs"
                                            ? "nav-link active show"
                                            : "nav-link"
                                        }
                                        onClick={() => {
                                          setCreateteam_tab("Pairs");
                                        }}
                                      >
                                        Pair
                                      </a>
                                    </li>
                                  </ul>
                                </div>

                                <div className="tab-content">
                                  {Createteam_tab === "drivers" && (
                                    <div
                                      id="drivers"
                                      className="tab-pane active show"
                                    >
                                      <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                          <div className="table_next_race table_view">
                                            <table>
                                              <tbody>
                                                <tr>
                                                  <th
                                                    style={{
                                                      minWidth: "278px",
                                                    }}
                                                  >
                                                    Name{" "}
                                                    <i
                                                      class={
                                                        (sortAction === "up" &&
                                                          sortcurrentField ===
                                                            "name") ||
                                                        (sortAction === "up" &&
                                                          !sortcurrentField)
                                                          ? "fa-solid sorting_icon fa-arrow-up"
                                                          : "fa-solid sorting_icon fa-arrow-down"
                                                      }
                                                      onClick={() =>
                                                        sortTable(
                                                          "name",
                                                          sortAction === "up"
                                                            ? "down"
                                                            : "up"
                                                        )
                                                      }
                                                    ></i>
                                                  </th>
                                                  <th>
                                                    % Sel by{" "}
                                                    <i
                                                      class={
                                                        (sortAction === "up" &&
                                                          sortcurrentField ===
                                                            "selectedDriverpercentage") ||
                                                        (sortAction === "up" &&
                                                          !sortcurrentField)
                                                          ? "fa-solid sorting_icon fa-arrow-up"
                                                          : "fa-solid sorting_icon fa-arrow-down"
                                                      }
                                                      onClick={() =>
                                                        sortTable(
                                                          "selectedDriverpercentage",
                                                          sortAction === "up"
                                                            ? "down"
                                                            : "up"
                                                        )
                                                      }
                                                    ></i>
                                                  </th>
                                                  <th>
                                                    Season Pts{" "}
                                                    <i
                                                      class={
                                                        (sortAction === "up" &&
                                                          sortcurrentField ===
                                                            "points") ||
                                                        (sortAction === "up" &&
                                                          !sortcurrentField)
                                                          ? "fa-solid sorting_icon fa-arrow-up"
                                                          : "fa-solid sorting_icon fa-arrow-down"
                                                      }
                                                      onClick={() =>
                                                        sortTable(
                                                          "points",
                                                          sortAction === "up"
                                                            ? "down"
                                                            : "up"
                                                        )
                                                      }
                                                    ></i>
                                                  </th>
                                                  <th className="px-2">
                                                    Value{" "}
                                                    <i
                                                      class={
                                                        (sortAction === "up" &&
                                                          sortcurrentField ===
                                                            "funds") ||
                                                        (sortAction === "up" &&
                                                          !sortcurrentField)
                                                          ? "fa-solid sorting_icon fa-arrow-up"
                                                          : "fa-solid sorting_icon fa-arrow-down"
                                                      }
                                                      onClick={() =>
                                                        sortTable(
                                                          "funds",
                                                          sortAction === "up"
                                                            ? "down"
                                                            : "up"
                                                        )
                                                      }
                                                    ></i>
                                                  </th>
                                                  <th className="px-2">Add</th>
                                                </tr>
                                                {driverData &&
                                                  driverData?.map((res, i) => (
                                                    <tr
                                                      className={
                                                        !res?.isActive
                                                          ? "unavaliable_item"
                                                          : ""
                                                      }
                                                      title={
                                                        res?.isActive
                                                          ? res?.name
                                                          : "Not Avaliable"
                                                      }
                                                      key={i}
                                                      onClick={() => {
                                                        if (res?.isActive) {
                                                          isDriverSelected(
                                                            res?._id
                                                          )
                                                            ? removeFromSelectedDriver(
                                                                res?._id
                                                              )
                                                            : handleSelectItem(
                                                                res
                                                              );
                                                        }
                                                      }}
                                                    >
                                                      <td>
                                                        <div className="row ">
                                                          <div className="col-lg-4 col-4 pr-0 text-center">
                                                            <img
                                                              className="img-fluid img-responsive center-block"
                                                              src={
                                                                startsWithHttp(
                                                                  res?.image
                                                                )
                                                                  ? res?.image
                                                                  : ImageURL +
                                                                    res?.image
                                                              }
                                                              alt="..."
                                                              onError={(e) => {
                                                                e.target.src =
                                                                  "images/racing/team-driver.png";
                                                              }}
                                                            />
                                                          </div>
                                                          <div
                                                            className="align-items-baseline col-8 d-flex flex-column justify-content-center text-capitalize"
                                                            style={{
                                                              whiteSpace: "pre",
                                                            }}
                                                          >
                                                            <p className="mb-1">
                                                              {res?.name}
                                                            </p>
                                                            <div className="d-flex font-weight-bold fs_xsmall">
                                                              <span
                                                                className={
                                                                  res?.isActive
                                                                    ? "avaliable_driver"
                                                                    : "unavaliable_driver"
                                                                }
                                                              ></span>
                                                              {res?.isActive
                                                                ? "In"
                                                                : "Out"}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>

                                                      <td>
                                                        {
                                                          res?.selectedDriverpercentage
                                                        }
                                                        %
                                                      </td>
                                                      <td>{res?.points}</td>
                                                      <td>${res?.funds}M</td>
                                                      <td>
                                                        <div className="table_match">
                                                          <a>
                                                            <img
                                                              src={
                                                                isDriverSelected(
                                                                  res?._id
                                                                )
                                                                  ? "images/racing/removemember.png"
                                                                  : "images/racing/add.png"
                                                              }
                                                              alt="team-logo"
                                                            />
                                                          </a>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  ))}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {Createteam_tab === "Constructors" && (
                                    <div
                                      id="constructors"
                                      className="tab-pane active show"
                                    >
                                      <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                          <div className="table_next_race table_view">
                                            <table>
                                              <tbody>
                                                <tr>
                                                  <th>
                                                    Name{" "}
                                                    <i
                                                      class={
                                                        (sortAction === "up" &&
                                                          sortcurrentField ===
                                                            "name") ||
                                                        (sortAction === "up" &&
                                                          !sortcurrentField)
                                                          ? "fa-solid sorting_icon fa-arrow-up"
                                                          : "fa-solid sorting_icon fa-arrow-down"
                                                      }
                                                      onClick={() =>
                                                        sortTable(
                                                          "name",
                                                          sortAction === "up"
                                                            ? "down"
                                                            : "up"
                                                        )
                                                      }
                                                    ></i>
                                                  </th>
                                                  <th></th>
                                                  <th>
                                                    % Sel by{" "}
                                                    <i
                                                      class={
                                                        (sortAction === "up" &&
                                                          sortcurrentField ===
                                                            "selectedConstructorpercentage") ||
                                                        (sortAction === "up" &&
                                                          !sortcurrentField)
                                                          ? "fa-solid sorting_icon fa-arrow-up"
                                                          : "fa-solid sorting_icon fa-arrow-down"
                                                      }
                                                      onClick={() =>
                                                        sortTable(
                                                          "selectedConstructorpercentage",
                                                          sortAction === "up"
                                                            ? "down"
                                                            : "up"
                                                        )
                                                      }
                                                    ></i>
                                                  </th>
                                                  <th>
                                                    Season Pts{" "}
                                                    <i
                                                      class={
                                                        (sortAction === "up" &&
                                                          sortcurrentField ===
                                                            "points") ||
                                                        (sortAction === "up" &&
                                                          !sortcurrentField)
                                                          ? "fa-solid sorting_icon fa-arrow-up"
                                                          : "fa-solid sorting_icon fa-arrow-down"
                                                      }
                                                      onClick={() =>
                                                        sortTable(
                                                          "points",
                                                          sortAction === "up"
                                                            ? "down"
                                                            : "up"
                                                        )
                                                      }
                                                    ></i>
                                                  </th>
                                                  <th>
                                                    Value{" "}
                                                    <i
                                                      class={
                                                        (sortAction === "up" &&
                                                          sortcurrentField ===
                                                            "funds") ||
                                                        (sortAction === "up" &&
                                                          !sortcurrentField)
                                                          ? "fa-solid sorting_icon fa-arrow-up"
                                                          : "fa-solid sorting_icon fa-arrow-down"
                                                      }
                                                      onClick={() =>
                                                        sortTable(
                                                          "funds",
                                                          sortAction === "up"
                                                            ? "down"
                                                            : "up"
                                                        )
                                                      }
                                                    ></i>
                                                  </th>
                                                  <th className="px-2">Add</th>
                                                </tr>
                                                {ConstrutorData &&
                                                  ConstrutorData?.map(
                                                    (res, i) => (
                                                      <tr
                                                        key={i}
                                                        onClick={() =>
                                                          isConstrutorSelected(
                                                            res?._id
                                                          )
                                                            ? removeFromSelectedConstructor(
                                                                res?._id
                                                              )
                                                            : handleSelectItem(
                                                                res
                                                              )
                                                        }
                                                      >
                                                        <td>
                                                          <div className="d-flex ">
                                                            <span className="team">
                                                              <img
                                                                className="img-fluid img-responsive center-block"
                                                                src={
                                                                  startsWithHttp(
                                                                    res?.image
                                                                  )
                                                                    ? res?.image
                                                                    : ImageURL +
                                                                      res?.image
                                                                }
                                                                alt="..."
                                                                onError={(
                                                                  e
                                                                ) => {
                                                                  e.target.src =
                                                                    "images/racing/car1.png";
                                                                }}
                                                              />
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td className="text-start">
                                                          <span>
                                                            <p>{res?.name}</p>
                                                          </span>
                                                        </td>
                                                        <td>
                                                          {
                                                            res?.selectedConstructorpercentage
                                                          }
                                                          %
                                                        </td>
                                                        <td>{res?.points}</td>
                                                        <td>${res?.funds}M</td>
                                                        <td>
                                                          <div className="table_match">
                                                            <a>
                                                              <img
                                                                src={
                                                                  isConstrutorSelected(
                                                                    res?._id
                                                                  )
                                                                    ? "images/racing/removemember.png"
                                                                    : "images/racing/add.png"
                                                                }
                                                                alt="team-logo"
                                                              />
                                                            </a>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {Createteam_tab === "losing_driver" && (
                                    <div
                                      id="losing"
                                      className="tab-pane active show"
                                    >
                                      <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                          <div className="table_next_race table_view">
                                            <table>
                                              <tbody>
                                                <tr>
                                                  <th
                                                    style={{
                                                      minWidth: "278px",
                                                    }}
                                                  >
                                                    Name{" "}
                                                    <i
                                                      class={
                                                        (sortAction === "up" &&
                                                          sortcurrentField ===
                                                            "name") ||
                                                        (sortAction === "up" &&
                                                          !sortcurrentField)
                                                          ? "fa-solid sorting_icon fa-arrow-up"
                                                          : "fa-solid sorting_icon fa-arrow-down"
                                                      }
                                                      onClick={() =>
                                                        sortTable(
                                                          "name",
                                                          sortAction === "up"
                                                            ? "down"
                                                            : "up"
                                                        )
                                                      }
                                                    ></i>
                                                  </th>
                                                  <th>
                                                    % Sel by{" "}
                                                    <i
                                                      class={
                                                        (sortAction === "up" &&
                                                          sortcurrentField ===
                                                            "selectedDriverpercentage") ||
                                                        (sortAction === "up" &&
                                                          !sortcurrentField)
                                                          ? "fa-solid sorting_icon fa-arrow-up"
                                                          : "fa-solid sorting_icon fa-arrow-down"
                                                      }
                                                      onClick={() =>
                                                        sortTable(
                                                          "selectedDriverpercentage",
                                                          sortAction === "up"
                                                            ? "down"
                                                            : "up"
                                                        )
                                                      }
                                                    ></i>
                                                  </th>
                                                  <th>
                                                    Season Pts{" "}
                                                    <i
                                                      class={
                                                        (sortAction === "up" &&
                                                          sortcurrentField ===
                                                            "points") ||
                                                        (sortAction === "up" &&
                                                          !sortcurrentField)
                                                          ? "fa-solid sorting_icon fa-arrow-up"
                                                          : "fa-solid sorting_icon fa-arrow-down"
                                                      }
                                                      onClick={() =>
                                                        sortTable(
                                                          "points",
                                                          sortAction === "up"
                                                            ? "down"
                                                            : "up"
                                                        )
                                                      }
                                                    ></i>
                                                  </th>
                                                  <th className="px-3">Add</th>
                                                </tr>
                                                {driverData &&
                                                  driverData?.map((res, i) => (
                                                    <tr
                                                      key={i}
                                                      className={
                                                        !res?.isActive
                                                          ? "unavaliable_item"
                                                          : ""
                                                      }
                                                      title={
                                                        res?.isActive
                                                          ? res?.name
                                                          : "Not Avaliable"
                                                      }
                                                      onClick={() => {
                                                        if (
                                                          res?.isActive &&
                                                          res?._id ===
                                                            selectedLosing?._id
                                                        ) {
                                                          removeFromSelectedLosing();
                                                        } else if (
                                                          res?.isActive
                                                        ) {
                                                          handleSelectItem(res);
                                                        }
                                                      }}
                                                    >
                                                      <td>
                                                        <div className="row ">
                                                          <div className="col-lg-4 col-4 pr-0 text-center">
                                                            <img
                                                              className="img-fluid img-responsive center-block"
                                                              src={
                                                                startsWithHttp(
                                                                  res?.image
                                                                )
                                                                  ? res?.image
                                                                  : ImageURL +
                                                                    res?.image
                                                              }
                                                              alt="..."
                                                              onError={(e) => {
                                                                e.target.src =
                                                                  "images/racing/team-driver.png";
                                                              }}
                                                            />
                                                          </div>
                                                          <div
                                                            className="align-items-baseline col-8 d-flex flex-column justify-content-center text-capitalize"
                                                            style={{
                                                              whiteSpace: "pre",
                                                            }}
                                                          >
                                                            <p className="mb-1">
                                                              {res?.name}
                                                            </p>
                                                            <div className="d-flex font-weight-bold fs_xsmall">
                                                              <span
                                                                className={
                                                                  res?.isActive
                                                                    ? "avaliable_driver"
                                                                    : "unavaliable_driver"
                                                                }
                                                              ></span>
                                                              {res?.isActive
                                                                ? "In"
                                                                : "Out"}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        {
                                                          res?.selectedDriverpercentage
                                                        }
                                                        %
                                                      </td>
                                                      <td>{res?.points}</td>
                                                      <td>
                                                        <div className="table_match">
                                                          <a>
                                                            <img
                                                              src={
                                                                res?._id ===
                                                                selectedLosing?._id
                                                                  ? "images/racing/removemember.png"
                                                                  : "images/racing/add.png"
                                                              }
                                                              alt="team-logo"
                                                            />
                                                          </a>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  ))}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {Createteam_tab === "Pairs" && (
                                    <div
                                      id="constructors"
                                      className="tab-pane active show"
                                    >
                                      <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                          <div className="table_next_race table_view">
                                            <table>
                                              <tbody>
                                                <tr>
                                                  <th>Name </th>
                                                  <th>Driver 1 </th>
                                                  <th>Driver 2 </th>
                                                </tr>
                                                {ConstrutorData &&
                                                  ConstrutorData?.map(
                                                    (res, i) => (
                                                      <tr key={i}>
                                                        <td>
                                                          <div className="ml-4">
                                                            <div className="team text-start">
                                                              <img
                                                                className="img-fluid img-responsive center-block"
                                                                src={
                                                                  startsWithHttp(
                                                                    res?.image
                                                                  )
                                                                    ? res?.image
                                                                    : ImageURL +
                                                                      res?.image
                                                                }
                                                                alt="..."
                                                                onError={(
                                                                  e
                                                                ) => {
                                                                  e.target.src =
                                                                    "images/racing/car1.png";
                                                                }}
                                                              />
                                                            </div>
                                                            <p className="fw-bold mb-0">
                                                              {res?.name}
                                                            </p>
                                                          </div>
                                                        </td>

                                                        {res?.driversIds &&
                                                          res?.driversIds?.map(
                                                            (driver, i) => (
                                                              <td
                                                                key={i}
                                                               
                                                              >
                                                                <div className="radio-wrapper-22">
                                                                  <label
                                                                    className="radio-wrapper mb-0"
                                                                    htmlFor={driver?._id}
                                                                  >
                                                                    <input
                                                                      type="radio"
                                                                      checked={Selected_pairDriverId?._id===driver?._id}
                                                                      className="radio-input"
                                                                      name="radio-examples"
                                                                      id={driver?._id}
                                                                      onClick={() =>
                                                                        setSelected_pairDriverId(
                                                                          driver
                                                                        )
                                                                      }
                                                                    />
                                                                    <div className="radio-tile">
                                                                      <div className="radio-icon ">
                                                                        <img
                                                                          className="img-fluid img-responsive center-block"
                                                                          src={
                                                                            startsWithHttp(
                                                                              driver?.image
                                                                            )
                                                                              ? driver?.image
                                                                              : ImageURL +
                                                                                driver?.image
                                                                          }
                                                                          alt="..."
                                                                          onError={(
                                                                            e
                                                                          ) => {
                                                                            e.target.src =
                                                                              "images/racing/car1.png";
                                                                          }}
                                                                        />
                                                                      </div>
                                                                      <p className="radio-label mb-0 font-weight-normal mt-1">
                                                                        {driver?.name}
                                                                      </p>
                                                                    </div>
                                                                  </label>
                                                                </div>
                                                              </td>
                                                            )
                                                          )}
                                                      </tr>
                                                    )
                                                  )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                              <div
                                className={
                                  !isNOCap
                                    ? "select-player d-lg-block d-none"
                                    : "select-player bg-primary-subtle d-lg-block d-none"
                                }
                              >
                                <div className="welcome-white-box">
                                  <div className="row d-flex purse justify-content-between">
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                      <div className="d-flex justify-content-between mb-3">
                                        <span className="funds">
                                          <h4>Available Funds:</h4>
                                        </span>
                                        <span className="money">
                                          {!isNOCap ? (
                                            <h4>
                                              ${" "}
                                              {Remainingfunds % 1 !== 0
                                                ? (
                                                    Totalcap - Remainingfunds
                                                  ).toFixed(2)
                                                : Totalcap - Remainingfunds}
                                              M
                                            </h4>
                                          ) : (
                                            <img
                                              className="position-absolute infinity_icon"
                                              src="images/racing/infinity.png"
                                              alt=""
                                            />
                                          )}
                                        </span>
                                      </div>
                                      {!isNOCap && (
                                        <div className="progress w-100 mb-3">
                                          <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{
                                              width: `${Remainingfunds}%`,
                                            }}
                                            aria-valuenow={0}
                                            aria-valuemin={0}
                                            aria-valuemax={Totalcap}
                                          ></div>
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 my-2 my-lg-0 text-right">
                                      {!id && (
                                        <a
                                          href="javascript:void(0)"
                                          className="res_btn_log p-team"
                                          onClick={handleShow}
                                        >
                                          Save
                                        </a>
                                      )}

                                      {id && (
                                        <a
                                          href="javascript:void(0)"
                                          className="res_btn_log p-team"
                                          onClick={handleShow}
                                        >
                                          Save
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                  <div className="match-white-box">
                                    <div className="half-hero-heading">
                                      <p>Drivers</p>
                                    </div>
                                    <ul className="d-flex inner-match-ul first-li px-2">
                                      <li>
                                        <ul className="align-items-baseline d-flex flex-wrap justify-content-center px-1">
                                          {selectedDriver &&
                                            selectedDriver?.map((item, i) => (
                                              <li className="text-center">
                                                <a>
                                                  <img
                                                    src={
                                                      startsWithHttp(
                                                        item?.image
                                                      )
                                                        ? item?.image
                                                        : ImageURL + item?.image
                                                    }
                                                    alt="..."
                                                    onError={(e) => {
                                                      e.target.src =
                                                        "images/racing/team-driver.png";
                                                    }}
                                                    className="img-fluid img-responsive center-block"
                                                  />
                                                </a>
                                                <a
                                                  href
                                                  className="remove-player"
                                                  onClick={() =>
                                                    removeFromSelectedDriver(
                                                      item?._id
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src="images/racing/remove.png"
                                                    alt="team-logo"
                                                  />
                                                </a>
                                                <p className="name-list my-0">
                                                  {item?.name}
                                                </p>
                                                <p>$ {item?.funds}m</p>
                                              </li>
                                            ))}

                                          {[
                                            ...Array(
                                              5 - selectedDriver?.length
                                            ),
                                          ].map((_, index) => (
                                            <div
                                              key={index}
                                              className="ml-2 my-1"
                                            >
                                              <img
                                                src="images/racing/team-driver.png"
                                                alt=""
                                                className="img-fluid"
                                              />
                                            </div>
                                          ))}
                                        </ul>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="match-white-box">
                                    <div className="half-hero-heading">
                                      <p>Constructors</p>
                                    </div>
                                    <ul className="d-flex inner-match-ul first-li px-2">
                                      <li>
                                        <ul className="align-items-baseline d-flex px-1">
                                          {selectedConstructor &&
                                            selectedConstructor?.map(
                                              (item, i) => (
                                                <li className="text-center car">
                                                  <a>
                                                    <img
                                                      className="img-fluid img-responsive center-block car-team"
                                                      src={
                                                        startsWithHttp(
                                                          item?.image
                                                        )
                                                          ? item?.image
                                                          : ImageURL +
                                                            item?.image
                                                      }
                                                      alt="..."
                                                      onError={(e) => {
                                                        e.target.src =
                                                          "images/racing/car1.png";
                                                      }}
                                                    />
                                                  </a>
                                                  <a
                                                    href
                                                    className="remove-player"
                                                    onClick={() =>
                                                      removeFromSelectedConstructor(
                                                        item?._id
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src="images/racing/remove.png"
                                                      alt="team-logo"
                                                    />
                                                  </a>
                                                  <p className="name-list m-auto">
                                                    {item?.name}
                                                  </p>
                                                  <p>${item?.funds}m</p>
                                                </li>
                                              )
                                            )}
                                          {[
                                            ...Array(
                                              2 - selectedConstructor?.length
                                            ),
                                          ].map((_, index) => (
                                            <div
                                              key={index}
                                              className="ml-2 my-1"
                                            >
                                              <img
                                                src="images/racing/constructor.png"
                                                alt=""
                                                className="img-fluid"
                                              />
                                            </div>
                                          ))}
                                        </ul>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="d-flex">
                                  {LosingDriver && (
                                    <div className="match-white-box flex-grow-1">
                                      <div className="half-hero-heading">
                                        <p>Losing Driver</p>
                                      </div>
                                      <ul className="d-flex inner-match-ul first-li px-2">
                                        <li>
                                          <ul className="px-0">
                                            {selectedLosing && (
                                              <li className="text-center">
                                                <a>
                                                  <img
                                                    className="img-fluid img-responsive center-block"
                                                    src={
                                                      startsWithHttp(
                                                        selectedLosing?.image
                                                      )
                                                        ? selectedLosing?.image
                                                        : ImageURL +
                                                          selectedLosing?.image
                                                    }
                                                    alt="..."
                                                    onError={(e) => {
                                                      e.target.src =
                                                        "images/racing/team-driver.png";
                                                    }}
                                                  />
                                                </a>
                                                <a
                                                  href
                                                  className="remove-player"
                                                  onClick={() =>
                                                    removeFromSelectedLosing()
                                                  }
                                                >
                                                  <img
                                                    src="images/racing/remove.png"
                                                    alt="team-logo"
                                                  />
                                                </a>
                                                <p className="name-list my-0">
                                                  {selectedLosing?.name}
                                                </p>
                                                {/* <p>${selectedLosing?.funds}m</p> */}
                                              </li>
                                            )}
                                            {selectedLosing?.length === 0 && (
                                              <div className="mt-2">
                                                <img
                                                  src="images/racing/team-driver.png"
                                                  alt=""
                                                  className="img-fluid"
                                                />
                                              </div>
                                            )}
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                                  
                                  
                                    <div className="match-white-box flex-grow-1">
                                      <div className="half-hero-heading">
                                        <p>Pair</p>
                                      </div>
                                      <ul className="d-flex inner-match-ul first-li px-2">
                                        <li>
                                          <ul className="px-0">
                                            {Selected_pairDriverId && (
                                              <li className="text-center">
                                                <a>
                                                  <img
                                                    className="img-fluid img-responsive center-block"
                                                    src={
                                                      startsWithHttp(
                                                        Selected_pairDriverId?.image
                                                      )
                                                        ? Selected_pairDriverId?.image
                                                        : ImageURL +
                                                          Selected_pairDriverId?.image
                                                    }
                                                    alt="..."
                                                    onError={(e) => {
                                                      e.target.src =
                                                        "images/racing/team-driver.png";
                                                    }}
                                                  />
                                                </a>
                                                <a
                                                  href
                                                  className="remove-player"
                                                  onClick={() =>
                                                    setSelected_pairDriverId("")
                                                  }
                                                >
                                                  <img
                                                    src="images/racing/remove.png"
                                                    alt="team-logo"
                                                  />
                                                </a>
                                                <p className="name-list my-0">
                                                  {Selected_pairDriverId?.name}
                                                </p>
                                                {/* <p>${selectedLosing?.funds}m</p> */}
                                              </li>
                                            )}
                                              {Selected_pairDriverId?.length === 0 && (
                                              <div className="mt-2">
                                                <img
                                                  src="images/racing/team-driver.png"
                                                  alt=""
                                                  className="img-fluid"
                                                />
                                              </div>
                                            )}
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  
                                  </div>
                               
                                </div>
                              </div>
                              <div className="mt-2 d-block d-lg-none text-right">
                                <button className="bg_theme btn px-3 rounded-25 text-white">
                                  View Team
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {loading ? <Customloader /> : ""}
                    {/*Createteam team tab*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* for selecting 2x and 3x */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">
            <div className="d-flex flex-wrap">
              <span>Select Your Booster player</span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="welcome-white-box upcome_race_wrapper leader-board-team py-0">
                <div className="mb-2">
                  <div className="d-flex justify-content-between">
                    <div
                      className={
                        selectBoosterx === "2x"
                          ? "badge bg_theme py-3 rounded-start-pill w-100 fs-6 curson_pointer"
                          : "badge py-3 bg-body-secondary rounded-start-pill w-100 fs-6 text-black curson_pointer"
                      }
                      onClick={() => setselectBoosterx("2x")}
                    >
                      Select 2x
                    </div>
                    {LeagueDetail && LeagueDetail?.max3xDriver !== 0 && (
                      <>
                        {driverCounter3x === 0 && (
                          <div
                            className={
                              selectBoosterx === "3x"
                                ? "badge bg-danger py-3 rounded-end-pill w-100 fs-6 curson_pointer"
                                : "badge bg-body-secondary py-3 rounded-end-pill text-black w-100 fs-6 curson_pointer"
                            }
                            onClick={() => setselectBoosterx("3x")}
                          >
                            Select 3x
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <Typography color="inherit">
                                    you are only allowed to use it twice a
                                    season !.
                                  </Typography>
                                </React.Fragment>
                              }
                            >
                              <Button
                                sx={{ m: 1 }}
                                className="custom_tooltip bg-body ml-4 px-2 py-0 rounded-2 text-black curson_pointer border-0"
                              >
                                i
                              </Button>
                            </HtmlTooltip>
                          </div>
                        )}

                        {driverCounter3x < 3 && driverCounter3x > 0 && (
                          <div
                            className={
                              selectBoosterx === "3x"
                                ? "badge bg-danger py-3 rounded-end-pill w-100 fs-6 curson_pointer"
                                : "badge bg-body-secondary py-3 rounded-end-pill text-black w-100 fs-6 curson_pointer"
                            }
                            onClick={() => setselectBoosterx("3x")}
                          >
                            Select 3x
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  {2 - driverCounter3x !== 0 ? (
                                    <Typography color="inherit">
                                      Only {2 - driverCounter3x} left for you.
                                      allowed to use twice a season !.
                                    </Typography>
                                  ) : (
                                    <Typography color="inherit">
                                      Last chance to use 3x player. allowed to
                                      use twice a season !.
                                    </Typography>
                                  )}
                                </React.Fragment>
                              }
                            >
                              <Button
                                sx={{ m: 1 }}
                                className="custom_tooltip bg-body ml-4 px-2 py-0 rounded-2 text-black curson_pointer border-0"
                              >
                                i
                              </Button>
                            </HtmlTooltip>
                          </div>
                        )}
                      </>
                    )}
                    {!isNOCap && !LeagueDetail && (
                      <>
                        {driverCounter3x === 0 && (
                          <div
                            className={
                              selectBoosterx === "3x"
                                ? "badge bg-danger py-3 rounded-end-pill w-100 fs-6 curson_pointer"
                                : "badge bg-body-secondary py-3 rounded-end-pill text-black w-100 fs-6 curson_pointer"
                            }
                            onClick={() => setselectBoosterx("3x")}
                          >
                            Select 3x
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <Typography color="inherit">
                                    you are only allowed to use it twice a
                                    season !.
                                  </Typography>
                                </React.Fragment>
                              }
                            >
                              <Button
                                sx={{ m: 1 }}
                                className="custom_tooltip bg-body ml-4 px-2 py-0 rounded-2 text-black curson_pointer border-0"
                              >
                                i
                              </Button>
                            </HtmlTooltip>
                          </div>
                        )}

                        {driverCounter3x < 3 && driverCounter3x > 0 && (
                          <div
                            className={
                              selectBoosterx === "3x"
                                ? "badge bg-danger py-3 rounded-end-pill w-100 fs-6 curson_pointer"
                                : "badge bg-body-secondary py-3 rounded-end-pill text-black w-100 fs-6 curson_pointer"
                            }
                            onClick={() => setselectBoosterx("3x")}
                          >
                            Select 3x
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  {2 - driverCounter3x !== 0 ? (
                                    <Typography color="inherit">
                                      Only {2 - driverCounter3x} left for you.
                                      allowed to use twice a season !.
                                    </Typography>
                                  ) : (
                                    <Typography color="inherit">
                                      Last chance to use 3x player. allowed to
                                      use twice a season !.
                                    </Typography>
                                  )}
                                </React.Fragment>
                              }
                            >
                              <Button
                                sx={{ m: 1 }}
                                className="custom_tooltip bg-body ml-4 px-2 py-0 rounded-2 text-black curson_pointer border-0"
                              >
                                i
                              </Button>
                            </HtmlTooltip>
                          </div>
                        )}
                      </>
                    )}
                    {LeagueDetail && !LeagueDetail?.max3xDriver && (
                      <div className="badge bg-body-secondary py-3 rounded-end-pill text-muted w-100 fs-6">
                        Select 3x
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography color="inherit">
                                Not Allowed to use in this league !
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <Button
                            sx={{ m: 1 }}
                            className="custom_tooltip bg-body ml-4 px-2 py-0 rounded-2 text-black curson_pointer border-0"
                          >
                            i
                          </Button>
                        </HtmlTooltip>
                      </div>
                    )}
                    {(driverCounter3x === 3 ||
                      (isNOCap && !LeagueDetail?.max3xDriver)) && (
                      <div className="badge bg-body-secondary py-3 rounded-end-pill text-black w-100 fs-6">
                        Select 3x
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography color="inherit">
                                {isNOCap
                                  ? "You can't use this option with the NOCAP team."
                                  : "You have exceeded the limit for using the 3x player. You are only allowed to use it twice a season."}
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <Button
                            sx={{ m: 1 }}
                            className="custom_tooltip bg-body ml-4 px-2 py-0 rounded-2 text-black curson_pointer border-0"
                          >
                            i
                          </Button>
                        </HtmlTooltip>
                      </div>
                    )}
                  </div>
                </div>
                <div className="match-white-box">
                  <div className="half-hero-heading">
                    <p>Drivers</p>
                  </div>
                  <ul className="d-flex inner-match-ul first-li px-2 justify-content-center px-2 my-0 overflow-hidden">
                    <li>
                      <ul className="px-1 d-flex flex-wrap justify-content-center">
                        {selectedDriver &&
                          selectedDriver?.map((item, i) => (
                            <li
                              className="text-center mx-2"
                              onClick={() =>
                                selectBoosterx === "2x"
                                  ? setboasterplayer(item)
                                  : setboasterplayer__3x(item)
                              }
                            >
                              <a>
                                <img
                                  src={
                                    startsWithHttp(item?.image)
                                      ? item?.image
                                      : ImageURL + item?.image
                                  }
                                  alt="..."
                                  onError={(e) => {
                                    e.target.src =
                                      "images/racing/team-driver.png";
                                  }}
                                  className="img-fluid img-responsive center-block"
                                />
                              </a>
                              <p
                                className={
                                  boasterplayer?._id === item?._id &&
                                  boasterplayer_3x?._id !== item?._id
                                    ? "name-list my-1 boaster_player_selection"
                                    : boasterplayer_3x?._id === item?._id
                                    ? "name-list my-1 boaster_player_selection_3x"
                                    : "name-list my-1"
                                }
                              >
                                {item?.name}
                              </p>
                              <p>$ {item?.funds}m</p>
                            </li>
                          ))}
                        {selectedDriver?.length === 0 && (
                          <div className="mt-2">
                            <img
                              src="images/racing/5driver.png"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        )}
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn" onClick={handleClose} className=" rounded-25">
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => submitname()}
            className="btn btn-primary px-4 rounded-25"
            disabled={!boasterplayer}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* enter team name modal */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showteampopup}
        onHide={handlepopupClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="h5">
            Enter Your Team Name
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <input
                type="text"
                className={
                  !TeamName && submit
                    ? "form-control border_danger"
                    : "form-control"
                }
                placeholder="Your Team Name"
                onChange={(e) => {
                  setTeamName(e.target?.value);
                }}
                value={TeamName}
                maxLength={30}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-primary px-4 rounded-25"
            onClick={() => handleTeam()}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* for Nocap team guide */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showNoCap}
        onHide={handleshowNoCap}
        backdrop="static"
        size="xl"
      >
        <Modal.Body>
          <div className="row my-2 mx-2">
            <button
              type="button"
              className="close d-flex justify-content-end "
              onClick={handleshowNoCap}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4>What is NO CAP Team?</h4>
            <p className="">
              Build your fantasy F1 dream team without budget caps. Compete
              based on talent, strategy, and love for racing
            </p>
            <div className="col-12 d-flex flex-lg-nowrap flex-wrap justify-content-between">
              <div className="box_nocap text-center border rounded-25 mx-2 my-2 my-lg-0">
                <div>
                  <h5 className="bg_theme py-2 text-white">Create Team</h5>
                  <p className="px-2 pt-2 opacity-75">
                    Create a team without considering capital. This special
                    option is available once per season.
                    {LeagueDetail
                      ? ""
                      : "The 3x booster selection will not be available for this team"}
                  </p>
                </div>
              </div>
              <div className="box_nocap text-center border rounded-25 mx-2 my-2 my-lg-0">
                <div>
                  <h5 className="bg_theme py-2 text-white">Next Race</h5>
                  <p className="px-2 pt-2 opacity-75">
                    The No cap option is available only once throughout the
                    entire season. Your team selection will revert to the
                    previous choice after the upcoming race.
                  </p>
                </div>
              </div>
              <div className="box_nocap text-center border rounded-25 mx-2 my-2 my-lg-0">
                <div>
                  <h5 className="bg_theme py-2 text-white">Avoid</h5>
                  <p className="px-2 pt-2 opacity-75">
                    If user do not want to use it even after creating a team
                    it's possible to avoid it before qualifying Race starts.
                  </p>
                </div>
              </div>
            </div>
            <div className="row px-0">
              <div className="d-flex justify-content-end mt-2">
                <button
                  className="bg_theme btn px-3 rounded-25 text-white"
                  onClick={switchtoNocap}
                >
                  {"Let's Go ->"}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* for Creating a league team */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={LeagueRules}
        onHide={handleLeagueRules}
        backdrop="static"
        size="md"
      >
        <Modal.Header className="bg_league text-white d-flex justify-content-between align-items-baseline">
          <Modal.Title id="contained-modal-title-vcenter" className="h6">
            <div>
              {inviteCode?.length > 6 ? "Update" : "Create"}{" "}
              {LeagueDetail?.name} Team
            </div>
          </Modal.Title>
          <div
            className="curson_pointer"
            onClick={() => {
              handleLeagueRules();
              navigate("/leagues");
            }}
            title="back to Leagues"
          >
            <i class="fa-solid fa-xmark"></i>
          </div>
        </Modal.Header>
        <Modal.Body className="welcome-white-box upcome_race_wrapper leader-board-team">
          <div className="row my-2 mx-2">
            <h5 className="text-center"># League Rules</h5>
            <div>
              <ul className="my-1">
                <li className="mt-2 d-flex align-items-baseline">
                  <i
                    class={
                      LeagueDetail?.max3xDriver
                        ? "fa-solid fa-circle-check mr-2 text-primary"
                        : "fa-solid fa-circle-xmark mr-2 text-danger"
                    }
                  ></i>
                  3x Drivers are {LeagueDetail?.max3xDriver ? "" : "not"}{" "}
                  Available.
                </li>
                <li className="mt-2 d-flex align-items-baseline">
                  <i class="fa-solid fa-circle-check mr-2 text-primary"></i>
                  Available Fund for the team is {LeagueDetail?.salarycap}m.
                </li>
                <li className="mt-2 d-flex align-items-baseline">
                  <i
                    class={
                      LeagueDetail?.maxNocap
                        ? "fa-solid fa-circle-check mr-2 text-primary"
                        : "fa-solid fa-circle-xmark mr-2 text-danger"
                    }
                  ></i>{" "}
                  NOCAP Team is {LeagueDetail?.maxNocap ? "" : "not"} Available.
                </li>
                <li className="mt-2 d-flex align-items-baseline">
                  <i
                    class={
                      LeagueDetail?.isLosingDriver
                        ? "fa-solid fa-circle-check mr-2 text-primary"
                        : "fa-solid fa-circle-xmark mr-2 text-danger"
                    }
                  ></i>{" "}
                  Losing Driver Selection is Available.
                </li>
                <li className="mt-2 d-flex align-items-baseline">
                  <i class="fa-solid fa-circle-check mr-2 text-primary"></i>
                  You will be Auto-Joined in this League as you Create a Team.
                </li>
                <li className="mt-2 d-flex align-items-baseline">
                  <i class="fa-solid fa-circle-check mr-2 text-primary"></i>
                  {LeagueDetail?.allowLateJoin
                    ? "This league is open throughout the season !"
                    : `This League is Open Until the Qualifying Time of
                  ${LeagueDetail?.latestRace?.name}.`}
                </li>
              </ul>
            </div>
            <div className="text-center">
              <div className="mt-2">
                <button
                  className="bg_league btn font_smaller px-4 rounded-25 text-white bg_theme"
                  onClick={continueWithLeague}
                >
                  Continue <i class="fa-solid fa-arrow-right px-2"></i>
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
