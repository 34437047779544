/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "../About_us/About_us.css";
import { GetService } from "../../Services/ConstantService";
import { API_URL } from "../../Services/ApiService";
import { showErrorAlert } from "../../Helper/SweetAlert";
import Customloader from "../../Helper/CustomLoader";
export default function Aboutus() {
  const [loading, setLoading] = useState(false);
  const [AboutUs, setAboutUs] = useState("");
  useEffect(() => {
    getAboutUs();
  }, []);
  const getAboutUs = () => {
    setLoading(true);
    GetService(`${API_URL.GET_CMS}?type=about_us`).then(
      (res) => {
        if (res.data.status === true && res.data.data) {
          setAboutUs(res.data.data[0]?.description);
          setLoading(false);
        } else {
          showErrorAlert(res.data?.message);
          setLoading(false);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
        setLoading(false);
      }
    );
  };

  return (
    <div>
      <div className="indx_title_main_wrapper float_left abt">
        <div className="title_img_overlay" />
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 full_width">
              <div className="indx_title_left_wrapper">
                <h2>About Us</h2>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 full_width">
              <div className="indx_title_right_wrapper">
                <ul>
                  <li>
                    <NavLink className="nav-link" to={"/Home"}>
                      Home &nbsp;&nbsp;&nbsp;&gt;{" "}
                      <span className="sr-only">(current)</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="nav-link" to={"/About_us"}>
                      About Us<span className="sr-only">(current)</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="iner_about_wrapper float_left abt mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="about_slider_wrapper float_left">
                <div className="about_image">
                  <img
                    src="images/racing/ne2.jpg"
                    className="img-responsive"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="about_welcome_content float_left">
                <div
                  className="white-pre-line"
                  dangerouslySetInnerHTML={{ __html: AboutUs }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? <Customloader /> : ""}
    </div>
  );
}
