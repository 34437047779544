/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Login from "../../Main/Auth/Login/Login";
import Signup from "../../Main/Auth/Sign_up/Sign_up";
import { ImageURL } from "../../Environment/Environment";
import { GetService } from "../../Services/ConstantService";
import { API_URL } from "../../Services/ApiService";
import { format } from "date-fns";
import { onMessageListener } from "../firebase";

export default function Header() {
  const navigate = useNavigate();
  const [activeTab, setactiveTab] = useState("Login");
  const [shownotification, setshownotification] = useState(false);
  const [newnotification, setnewnotification] = useState(false);
  const [Notification, setNotification] = useState("");
  const userdata = JSON.parse(localStorage.getItem("userdata"));
  const isAuthenticated = userdata?.token;

  const getnotification = () => {
    GetService(API_URL.GET_NOTIFICATION).then((res) => {
      if (res?.data?.status) {
        setNotification(res?.data);
        if (res?.data?.data?.unreadCount > 0) {
          setnewnotification(true);
        }
      }
    });
  };

  const readnotification = () => {
    if (Notification?.data?.unreadCount > 0) {
      GetService(API_URL.READ_NOTIFICATION).then((res) => {
        if (res?.data?.status) {
          setnewnotification(false);
          setshownotification(false);
        }
      });
    } else {
      setnewnotification(false);
      setshownotification(false);
    }
  };
  const formatTimeDifference = (timestamp) => {
    const currentTime = new Date();
    const diffInSeconds = Math.floor(
      (currentTime - new Date(timestamp)) / 1000
    );

    if (diffInSeconds < 15) {
      return "Just now";
    } else if (diffInSeconds < 60) {
      return `${diffInSeconds} sec ago`;
    } else if (diffInSeconds < 3600) {
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      return `${diffInMinutes} min ago`;
    } else if (diffInSeconds < 86400) {
      // Less than 24 hours
      const diffInHours = Math.floor(diffInSeconds / 3600);
      return `${diffInHours} hour ago`;
    } else if (diffInSeconds < 259200) {
      // Less than 3 days (3 days = 24 hours * 3)
      const diffInDays = Math.floor(diffInSeconds / 86400);
      return `${diffInDays} day ago`;
    } else {
      const formattedDate = new Date(timestamp).toLocaleDateString();
      return formattedDate;
    }
  };

  useEffect(() => {
    if (isAuthenticated && shownotification) {
      getnotification();
    }
  }, [shownotification]);

  function startsWithHttp(url) {
    const regex = /^(http)/;
    return regex.test(url);
  }
  onMessageListener()
    .then((payload) => {
      console.log(payload);
      if (payload) {
        setnewnotification(true);
      }
    })
    .catch((err) => console.log("Failed: ", err));

  return (
    <div>
      <header className="header">
        <nav className="navbar navbar-expand-lg navbar-light bg-dark header__area sticky">
          <Link to={"Home"}>
            <img src="images/racing/logo1.png" alt="logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto">
              <li className="nav-item">
                <NavLink className="nav-link" to={"Home"}>
                  Home <span className="sr-only">(current)</span>
                </NavLink>
              </li>
              {isAuthenticated && (
                <li className="nav-item">
                  <NavLink className="nav-link" to={"Leaderboard"}>
                    Leaderboard <span className="sr-only">(current)</span>
                  </NavLink>
                </li>
              )}

              {isAuthenticated && (
                <li className="nav-item">
                  <NavLink className="nav-link" to={"statistics"}>
                    Statistics <span className="sr-only">(current)</span>
                  </NavLink>
                </li>
              )}

              {isAuthenticated && (
                <li className="nav-item">
                  <NavLink className="nav-link" to={"leagues"}>
                    Leagues <span className="sr-only">(current)</span>
                  </NavLink>
                </li>
              )}
              {isAuthenticated && (
                <li className="nav-item">
                  <NavLink className="nav-link" to={"my_team"}>
                    My Team <span className="sr-only">(current)</span>
                  </NavLink>
                </li>
              )}

              {!isAuthenticated && (
                <li className="nav-item">
                  <NavLink className="nav-link" to={"About_us"}>
                    About <span className="sr-only">(current)</span>
                  </NavLink>
                </li>
              )}
            </ul>
            {!isAuthenticated && (
              <ul className="navbar-nav">
                <li
                  className={activeTab === "Register" ? "vtbbb" : "buy_now_btn"}
                >
                  <div className="main_button_wrapper register">
                    <a
                      href="#"
                      className="res_btn_log"
                      data-toggle="modal"
                      data-target="#RegisterModal"
                      onClick={() => setactiveTab("Register")}
                    >
                      Register
                    </a>
                  </div>
                </li>
                <li className={activeTab === "Login" ? "vtbbb" : "buy_now_btn"}>
                  <div className="main_button_wrapper">
                    <a
                      href="#"
                      className="res_btn_log"
                      data-toggle="modal"
                      data-target="#loginModal"
                      onClick={() => setactiveTab("Login")}
                    >
                      login
                    </a>
                  </div>
                </li>
              </ul>
            )}
            {isAuthenticated && (
              <>
                <ul className="align-items-lg-center align-items-start d-flex navbar-nav">
                  <li className="d-lg-inline d-none mr-4 position-relative">
                    {newnotification && (
                      <span className="bg-danger notification_count position-absolute rounded-circle"></span>
                    )}
                    <i
                      class="curson_pointer fa-bell fa-solid fs-5 text-primary"
                      onClick={() => setshownotification(true)}
                    ></i>
                  </li>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn btn-primary dropdown-toggle px-3 py-1 rounded-25"
                      data-toggle="dropdown"
                    >
                      <span>
                        <img
                          src={
                            startsWithHttp(userdata?.profile)
                              ? userdata?.profile
                              : ImageURL + userdata?.profile
                          }
                          alt="..."
                          onError={(e) => {
                            e.target.src = "images/racing/user.svg";
                          }}
                          className="rounded-circle"
                          height={35}
                          width={35}
                        />
                      </span>
                      <span className="user-signin mx-2">
                        {userdata?.userName
                          ? userdata?.userName
                          : "RacePace_User"}
                      </span>
                    </button>
                    <div className="dropdown-menu">
                      <a
                        className="dropdown-item"
                        onClick={() => navigate("/profile")}
                      >
                        View Profile
                      </a>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          localStorage.clear();
                          navigate("/Home");
                          window.location.reload();
                        }}
                      >
                        Logout
                      </a>
                    </div>
                  </div>
                </ul>

                <div
                  className={
                    shownotification
                      ? "notification_box_active notification_box rounded-25 shadow"
                      : "notification_box rounded-25 shadow"
                  }
                >
                  <div className="d-flex justify-content-between m-3">
                    <h4>Notification</h4>
                    <i
                      class="fa-solid fa-circle-xmark curson_pointer fs-4"
                      onClick={() => {
                        readnotification();
                      }}
                    ></i>
                  </div>
                  <div className="mx-3 mb-3 notification_box_body overflow-auto">
                    {Notification?.data?.notifications?.length === 0 && (
                      <div className="d-flex justify-content-center">
                        <img
                          src="images/racing/no_data_transparent.png"
                          alt=""
                          height="170px"
                        />
                      </div>
                    )}
                    {Notification?.data?.notifications &&
                      Notification?.data?.notifications?.map((res, i) => (
                        <>
                          <div
                            key={i}
                            className={
                              res?.status === "unread"
                                ? "bg-body-secondary notification_list rounded-25 py-2 px-3 mb-2"
                                : "notification_list rounded-25 py-2 px-3 mb-2"
                            }
                          >
                            <div className="d-flex justify-content-between">
                              <h5 className="f_15">{res?.content?.title}</h5>
                              <p
                                className="mb-0 font_smaller ml-3"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {res && res.timestamp
                                  ? formatTimeDifference(res.timestamp)
                                  : ""}
                              </p>
                            </div>
                            <p className="mb-0 font_smaller">
                              {res?.content?.body}
                            </p>
                          </div>
                        </>
                      ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </nav>
      </header>
      {!isAuthenticated && (
        <>
          <Login />
          <Signup />
        </>
      )}
    </div>
  );
}
