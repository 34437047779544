// const APIBaseURL = 'https://54.163.58.235/v0/'; 
const APIBaseURL = 'https://racepace.vip/v0/'; 
const ImageURL = 'https://racepace-images.s3.amazonaws.com/';
// const currentDomain= 'https://54.163.58.235/';
const currentDomain= 'https://racepace.vip/'

const firebaseConfig = {
    apiKey: "AIzaSyCcIx5bzKvXhoXKiSArxCAv_DuwZvQnb_M",
    authDomain: "racepace-ec6a0.firebaseapp.com",
    projectId: "racepace-ec6a0",
    storageBucket: "racepace-ec6a0.appspot.com",
    messagingSenderId: "918498714532",
    appId: "1:918498714532:web:d6f95bbff9307570a51731",
    measurementId: "G-636DVD671J",
  };
 const vapid_key= 'BIp0aS_xYVoRbGvGO5jt0lXLOZeUYBCRc_Byct3s3VVGab5L0bIl-nGvbhyNKL6UusE9tOS1vWrgV2BNhZMSsEk';

export {APIBaseURL,ImageURL,currentDomain,firebaseConfig,vapid_key}