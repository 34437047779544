import { APIBaseURL } from "../Environment/Environment";

const API_URL = {

  //USER 
  ADMIN_LOGIN: APIBaseURL + "login",
  USER_DETAIL: APIBaseURL + "userById",
  UPDATE_PROFILE: APIBaseURL + "update-user",
  GET_USERS_LIST: APIBaseURL + "users",
  REGISTRATION: APIBaseURL + "registration",
  VERIFY_OTP: APIBaseURL + "verifyOtp",
  RESEND_OTP: APIBaseURL + "resendOtp",
  FORGOT_PASSWORD: APIBaseURL + "forgotPasswordMail",
  FORGOT_PASSWORD_BY_TOKEN: APIBaseURL + "forgotPassword",

  //CONTRUCTORS
  GET_CONTRUCTOR: APIBaseURL + "get-constructor",
  CONTRUCTOR_BY_ID: APIBaseURL + "get-constructor",

  //DRIVERS
  GET_DRIVERS: APIBaseURL + "get-driver",
  DRIVERS_BY_ID: APIBaseURL + "get-driverById",

  //RACES
  GET_RACES: APIBaseURL + "races",
  JOIN_RACES: APIBaseURL + "join-race",

  // TEAMS
  GET_TEAMS: APIBaseURL + "get-teams",
  GET_TEAM_BY_ID: APIBaseURL + "get-teamById",
  CREATE_TEAM: APIBaseURL + 'create-team',
  USERS_TEAMS_BY_ID: APIBaseURL + "get-teamsByUser",
  UPDATE_TEAM: APIBaseURL + 'update-team',
  GET_PREVIOUS_TEAM: APIBaseURL + 'previousTeam',

  //LEADERBOARD
  GET_LEADERBOARD_USERS: APIBaseURL + "leaderboard-Users",
  GET_LEADERBOARD_DRIVERS: APIBaseURL + "leaderboard-Drivers",
  GET_LEADERBOARD_CONSTRUCTORS: APIBaseURL + "leaderboard-Constructor",
  My_RACES: APIBaseURL + "getRaceByUser",
  My_DRIVER_RACES: APIBaseURL + "getRaceByDriver",
  My_CONSTRUCTOR_RACES: APIBaseURL + "getRaceByConstructor",
  ACTIVE_RACE: APIBaseURL + "getActiveRaces",
  GET_LEADERBOARD_BY_RACE: APIBaseURL + "getRaceById",

  //COUNTRY
  GET_COUNTRY: APIBaseURL + "country-list",

  //DASHBOARD
  DASHBOARD: APIBaseURL + "get-latest-event",
  GET_INACTIVE_PLAYERS: APIBaseURL + "getInactivePlayer",

  //LEAGUE
  CREATE_LEAGUE: APIBaseURL + "create-league",
  GET_LEAGUE: APIBaseURL + "get-leagues",
  JOIN_LEAGUE: APIBaseURL + "join-league",
  GET_LEAGUE_DETAILS: APIBaseURL + "league-singleDetail",

  //NOTIFICATION
  GET_NOTIFICATION: APIBaseURL + "notifications",
  READ_NOTIFICATION: APIBaseURL + "read_notifications",

  //GET FAQ
  GET_FAQ: APIBaseURL + "getfaqs",

  //GET STATISTICS
  GET_STATISTICS_DRIVER: APIBaseURL + "get-driverStatistics",
  GET_STATISTICS_CONSTRUCTOR: APIBaseURL + "get-constructorStatistics",

  //CMS
  GET_CMS: APIBaseURL + "get-cms",


};

export { API_URL };
