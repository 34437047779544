/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

export default function PageNotFound() {
  const navigate = useNavigate();

  return (
    <div>
        <div className="d-flex justify-content-center leader-board-team py-5 upcome_race_wrapper welcome-white-box">
       <img src="images/racing/404.png" alt="" />
        </div>
        
    </div>
  );
}
