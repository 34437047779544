/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from "../Common/Header/Header.jsx";
import Footer from "../Common/Footer/Footer.jsx";
import { GetService } from "../Services/ConstantService";
import { API_URL } from "../Services/ApiService.js";
import { requestForToken ,onMessageListener} from "../Common/firebase.js";

export default function Main() {
    const navigate = useNavigate();
    const location = useLocation();
    const userdata = JSON.parse(localStorage.getItem("userdata"));
    const isAuthenticated = userdata?.token;
    const [inActivePlayers, setinActivePlayers] = useState("")
    const [id, setId] = useState("")

    useEffect(() => {
        getInActivePlayers()
        requestForToken()
    }, [])

    onMessageListener()
    .then((payload) => {
      console.log('new notification received')    
    })
    .catch((err) => console.log('failed: ', err));

    const getInActivePlayers = () => {
        GetService(API_URL.GET_INACTIVE_PLAYERS)
            .then((res) => {
                if (res.data.status === true) {
                    const data = res.data.data[0];
                    const driver = data?.driverIds || [];
                    const loserDriver = data?.loserId ? [data.loserId] : []; 
                    const combinedArray = driver.concat(loserDriver);
                    const uniqueObjects = {};
                    combinedArray.forEach(obj => {
                        if (!uniqueObjects[obj._id]) {
                            uniqueObjects[obj._id] = obj;
                        }
                    });
                    const uniqueCombine = Object.values(uniqueObjects);
                    setinActivePlayers(uniqueCombine);
                    setId(data?._id);
                    const teamdiv = document.getElementById('teamdiv');
                    if (uniqueCombine.length > 0) {
                        setTimeout(() => {
                            teamdiv?.classList.add('visible_team');
                        }, 3000);
                    }
                }
            })
            .catch(error => {
                console.error("Error occurred while fetching inactive players:", error);
            });
    };
    
    
    

    const close=()=>{
        const teamdiv = document.getElementById('teamdiv');
        teamdiv?.classList.remove('visible_team')
    }

    return (
        <>
            <div>
                <Header />
                <div >
                    <div>
                        <Outlet />
                        {isAuthenticated && !(location?.pathname.split('/')[1] === 'edit_team' || location?.pathname.split('/')[1] === 'create_team') && (
                            <div className="position-fixed change_in_team rounded-25 shadow overflow-hidden" id="teamdiv">
                                <div className="d-flex justify-content-between bg_theme py-2 px-3 text-white align-items-center">
                                    <p className="font_smaller mb-0 font-weight-bold">Drivers Not Available</p>
                                    <button type="button" className="close text-white" onClick={close}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="welcome-white-box upcome_race_wrapper leader-board-team py-2">
                                    <div className="match-white-box">
                                        <div className="half-hero-heading">
                                        </div>
                                        <ul className="d-flex inner-match-ul first-li justify-content-center px-2 my-0 overflow-hidden">
                                            <li>
                                                <ul className="px-1 d-flex flex-wrap justify-content-center">
                                                    {inActivePlayers && inActivePlayers.map((res) => (
                                                        <li className="text-center mx-2">
                                                            <a href="javascript:void(0)">
                                                                <img
                                                                    src={
                                                                        res?.image
                                                                    }
                                                                    alt="..."
                                                                    onError={(e) => {
                                                                        e.target.src =
                                                                            "images/racing/team-driver.png";
                                                                    }}
                                                                    className="img-fluid img-responsive center-block"
                                                                />
                                                            </a>
                                                            <p
                                                                className={

                                                                    "name-list my-1"
                                                                }
                                                            >
                                                                {res?.name}
                                                            </p>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="mr-2 mt-1 text-end">
                                        <button className="bg_theme btn px-3 rounded-25 text-white"
                                            style={{ fontSize: 'x-small', letterSpacing: '1px' }} onClick={() => navigate(`edit_team/${id}`)}>
                                            Check Team <i class="fa-solid fa-arrow-right"></i></button></div>
                                </div>
                            </div>
                        )}

                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}
