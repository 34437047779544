/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { API_URL } from "../../../Services/ApiService";
import { showErrorAlert } from "../../../Helper/SweetAlert";
import { GetService, PostService } from "../../../Services/ConstantService";
import { ImageURL } from "../../../Environment/Environment";
import { Swiper, SwiperSlide } from "swiper/react";
import Customloader from "../../../Helper/CustomLoader";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
export default function RaceLeaderboard() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [ConstrutorData, setConstrutorData] = useState();
  const [driverData, setdriverData] = useState();
  const [leaderboard_tab, setleaderboard_tab] = useState("");
  const [selecteditem, setselecteditem] = useState("");
  const [Myraces, setMyraces] = useState("");
  const [mydriverdetails, setmydriverdetails] = useState("");
  const [seasonRace, setseasonRace] = useState("");
  const [swiperIndex, setSwiperIndex] = useState();
  const [MyRaceTeamView, setMyRaceTeamView] = useState("");
  const [myTeamPointsIndex, setmyTeamPointsIndex] = useState();
  const [swiperStart, setswiperStart] = useState(false);
  const swiperRef = useRef(null);
  const location = useLocation();
  const getUserList = async (id) => {
    setLoading(true);
    const data = {
      id: id,
    };
    PostService(API_URL.GET_LEADERBOARD_BY_RACE, data).then(
      (res) => {
        if (res.data.status === true) {
          setUserData(res.data.data);
          getteamview(res?.data?.data?.myTeamId, id);
          setLoading(false);
        } else {
          showErrorAlert(res.data?.message);
          setLoading(false);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
        setLoading(false);
      }
    );
  };
  const getConstructorList = async (id) => {
    setLoading(true);
    GetService(`${API_URL.GET_LEADERBOARD_CONSTRUCTORS}?raceId=${id}`).then(
      (res) => {
        if (res.data.status === true) {
          setConstrutorData(res.data.data);
          setLoading(false);
        } else {
          showErrorAlert(res.data?.message);
          setLoading(false);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
        setLoading(false);
      }
    );
  };
  function viewOtherTeam(teamId, raceId) {
    if (userData?.status === "expired" || userData?.status === "inactive") {
      getteamview(teamId, raceId);
    } else {
    }
  }
  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
      setSwiperIndex(swiperRef.current.swiper.realIndex);
      if (leaderboard_tab === "user") {
        getUserList(seasonRace[swiperRef.current.swiper.realIndex]?._id);
      } else if (leaderboard_tab === "drivers") {
        getDriverList(seasonRace[swiperRef.current.swiper.realIndex]?._id);
      } else if (leaderboard_tab === "constructors") {
        getConstructorList(seasonRace[swiperRef.current.swiper.realIndex]?._id);
      }
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
      setSwiperIndex(swiperRef.current.swiper.realIndex);
      if (leaderboard_tab === "user") {
        getUserList(seasonRace[swiperRef.current.swiper.realIndex]?._id);
      } else if (leaderboard_tab === "drivers") {
        getDriverList(seasonRace[swiperRef.current.swiper.realIndex]?._id);
      } else if (leaderboard_tab === "constructors") {
        getConstructorList(seasonRace[swiperRef.current.swiper.realIndex]?._id);
      }
    }
  };
  const getDriverList = async (id) => {
    setLoading(true);
    GetService(`${API_URL.GET_LEADERBOARD_DRIVERS}?raceId=${id}`).then(
      (res) => {
        if (res.data.status === true) {
          setdriverData(res.data.data);
          setLoading(false);
        } else {
          showErrorAlert(res.data?.message);
          setLoading(false);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
        setLoading(false);
      }
    );
  };
  function startsWithHttp(url) {
    const regex = /^(http)/;
    return regex.test(url);
  }
  const getRacelist = async () => {
    setLoading(true);
    GetService(`${API_URL.ACTIVE_RACE}?type=All`).then(
      (res) => {
        if (res.data.status === true) {
          const raceData = res.data?.data;
          setSwiperIndex(raceData?.startIndex);
          setseasonRace(res.data?.data?.races);
          getUserList(res?.data?.data?.races[raceData?.startIndex]?._id);
          setswiperStart(true);
          setleaderboard_tab("user");
          setLoading(false);
        } else {
          showErrorAlert(res.data?.message);
          setLoading(false);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
        setLoading(false);
      }
    );
  };
  const getteamview = async (id, raceId) => {
    setLoading(true);
    const data = {
      id: id,
    };
    PostService(API_URL.GET_TEAM_BY_ID, data).then(
      (res) => {
        if (res.data.status === true) {
          setLoading(false);
          setMyRaceTeamView(res?.data?.data);
          const getCurrentRacePointIndex =
            res?.data?.data?.teamEventPoints.findIndex(
              (i) => i?.raceId === raceId
            );
          console.log(getCurrentRacePointIndex, raceId);
          setmyTeamPointsIndex(getCurrentRacePointIndex);
        } else {
          setMyRaceTeamView("");
          setLoading(false);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    getRacelist();
  }, []);

  return (
    <div>
      {
        <div
          className={
            location?.pathname === "/Leaderboard"
              ? "upcome_race_wrapper float_left leader-board-team mt-5"
              : ""
          }
        >
          <div
            className={location?.pathname === "/Leaderboard" ? "container" : ""}
          >
            <div className="row">
              {location?.pathname === "/Leaderboard" && (
                <div className="col-md-12">
                  <div className="ft_left_heading_wraper head1 center_headign">
                    <h1>Check Your Leaderboard &amp; Team</h1>
                  </div>
                </div>
              )}
            </div>
            <div
              className={
                location?.pathname === "/Leaderboard"
                  ? "col-xl-12 col-lg-12 col-md-12"
                  : ""
              }
            >
              <div
                className={
                  location?.pathname === "/Leaderboard"
                    ? "tab-content select-player-2"
                    : ""
                }
              >
                <div id="leaderboard" className="tab-pane active leaderboard">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="row">
                        <div className="col-lg-6 col-12">
                          <div className="x_offer_tabs_wrapper tab_online">
                            <ul className="nav nav-tabs">
                              <li className="nav-item flex-grow-none">
                                {" "}
                                <a
                                  className={
                                    leaderboard_tab === "user"
                                      ? "nav-link active show"
                                      : "nav-link"
                                  }
                                  onClick={() => {
                                    setleaderboard_tab("user");
                                    getUserList(seasonRace[swiperIndex]?._id);
                                  }}
                                >
                                  Users
                                </a>
                              </li>
                              <li className="nav-item flex-grow-none">
                                {" "}
                                <a
                                  className={
                                    leaderboard_tab === "drivers"
                                      ? "nav-link active show"
                                      : "nav-link"
                                  }
                                  onClick={() => {
                                    setleaderboard_tab("drivers");
                                    getDriverList(seasonRace[swiperIndex]?._id);
                                  }}
                                >
                                  Drivers
                                </a>
                              </li>
                              <li className="nav-item flex-grow-none">
                                {" "}
                                <a
                                  className={
                                    leaderboard_tab === "constructors"
                                      ? "nav-link active show"
                                      : "nav-link"
                                  }
                                  onClick={() => {
                                    setleaderboard_tab("constructors");
                                    getConstructorList(
                                      seasonRace[swiperIndex]?._id
                                    );
                                  }}
                                >
                                  Constructors
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {swiperStart && swiperIndex !== null && (
                          <div className="align-items-baseline col-lg-6 col-12 d-flex text-start">
                            <div class="owl-nav curson_pointer">
                              <div class="owl-prev" onClick={goPrev}>
                                <i class="flaticon-left-arrow"></i>
                              </div>
                            </div>
                            <Swiper
                              ref={swiperRef}
                              slidesPerView={1}
                              spaceBetween={30}
                              initialSlide={swiperIndex}
                              modules={[Pagination]}
                              allowTouchMove={false}
                              className="mySwiper px-3"
                              onSwiper={(swiper) =>
                                setSwiperIndex(swiper.realIndex)
                              }
                            >
                              {seasonRace &&
                                seasonRace?.map((res, i) => (
                                  <SwiperSlide>
                                    <div className="text-center">
                                      <h5>{res?.name}</h5>
                                      {leaderboard_tab === "user" &&
                                      userData?.status !== "active" ? (
                                        <p>
                                          Tap on user to see their team for this
                                          race
                                        </p>
                                      ) : (
                                        <p>{res?.venue}</p>
                                      )}
                                    </div>
                                  </SwiperSlide>
                                ))}
                            </Swiper>
                            <div class="owl-nav curson_pointer">
                              <div class="owl-next" onClick={goNext}>
                                <i class="flaticon-right-arrow"></i>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="row">
                        <div
                          className={
                            leaderboard_tab !== "user"
                              ? "col-12"
                              : "col-lg-6 col-12"
                          }
                        >
                          <div className="tab-content select-player">
                            {leaderboard_tab === "drivers" && (
                              <div
                                id="drivers-leader"
                                className="tab-pane active show drivers-leader-table"
                              >
                                <div className="row">
                                  <div className="col-12">
                                    <div className="table_next_race border table_view">
                                      <table className="add-new-ch">
                                        <tbody>
                                          <tr>
                                            {seasonRace[swiperIndex]
                                              ?.pointsStatus && <th>Rank</th>}
                                            <th>All Drivers</th>
                                            <th className="text-center">
                                              Q. Pts
                                            </th>
                                            <th className="text-center">
                                              Race Pts
                                            </th>
                                            <th className="text-center">
                                              Total Pts
                                            </th>
                                          </tr>
                                          {driverData &&
                                            driverData?.map((res, i) => (
                                              <tr>
                                                {seasonRace[swiperIndex]
                                                  ?.pointsStatus && (
                                                  <td>
                                                    <span className="mr-3">
                                                      {res?.eventPoint?.raceRank
                                                        ? `# ${res?.eventPoint?.raceRank}`
                                                        : "--"}
                                                    </span>
                                                  </td>
                                                )}
                                                <td
                                                  data-toggle="modal"
                                                  data-target="#exampleModal5"
                                                >
                                                  <div className="d-flex justify-content-between">
                                                    <img
                                                      className=""
                                                      src={
                                                        startsWithHttp(
                                                          res?.image
                                                        )
                                                          ? res?.image
                                                          : ImageURL +
                                                            res?.image
                                                      }
                                                      alt=""
                                                      width="75px"
                                                      height="75px"
                                                      style={{
                                                        objectFit: "contain",
                                                      }}
                                                    />
                                                    <span
                                                      className=""
                                                      onClick={() => {
                                                        // setselecteditem(res);
                                                        // getMyDriverDetails(
                                                        //   res?.id
                                                        // );
                                                        // handleShow();
                                                      }}
                                                    >
                                                      {res?.name}
                                                    </span>
                                                    <img
                                                      className="car_align_Raceleaderboard"
                                                      src={
                                                        startsWithHttp(
                                                          res?.constructor.image
                                                        )
                                                          ? res?.constructor
                                                              .image
                                                          : ImageURL +
                                                            res?.constructor
                                                              .image
                                                      }
                                                      width="189px"
                                                      onError={(e) => {
                                                        e.target.src =
                                                          "images/racing/car1.png";
                                                      }}
                                                      alt="team-logo"
                                                    />
                                                  </div>
                                                </td>
                                                <td className="custom_column_table">
                                                  {res?.eventPoint
                                                    ?.qualifyingRacePoints
                                                    ? res?.eventPoint
                                                        ?.qualifyingRacePoints
                                                    : !res?.eventPoint
                                                        ?.qualifyingRacePoints &&
                                                      seasonRace[swiperIndex]
                                                        ?.pointsStatus
                                                    ? "--"
                                                    : "pending"}
                                                </td>
                                                <td>
                                                  {res?.eventPoint
                                                    ?.mainRacePoints
                                                    ? res?.eventPoint
                                                        ?.mainRacePoints
                                                    : !res?.eventPoint
                                                        ?.mainRacePoints &&
                                                      seasonRace[swiperIndex]
                                                        ?.pointsStatus
                                                    ? "--"
                                                    : "Pending"}
                                                </td>
                                                <td>
                                                  {res?.eventPoint
                                                    ? res?.eventPoint
                                                        ?.racePoints
                                                    : !res?.eventPoint
                                                        ?.racePoints &&
                                                      seasonRace[swiperIndex]
                                                        ?.pointsStatus
                                                    ? "--"
                                                    : "Pending"}
                                                </td>
                                              </tr>
                                            ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {leaderboard_tab === "user" && (
                              <div
                                id="losing-leader"
                                className="tab-pane active show"
                              >
                                <div className="row">
                                  <div className="col-12">
                                    <div className="table_next_race border table_view">
                                      <table>
                                        <tbody>
                                          <tr>
                                            {seasonRace[swiperIndex]
                                              ?.pointsStatus && <th>Rank</th>}
                                            <th>All Users</th>
                                            <th>Q. Pts</th>
                                            <th>Race Pts</th>
                                            <th>Total Pts</th>
                                          </tr>
                                          {userData?.teams &&
                                            userData?.teams.map((res, i) => (
                                              <tr
                                                className={
                                                  MyRaceTeamView?.ownerId
                                                    ?._id === res?.ownerId?._id
                                                    ? "active"
                                                    : ""
                                                }
                                                onClick={() => {
                                                  viewOtherTeam(
                                                    res?._id,
                                                    seasonRace[swiperIndex]?._id
                                                  );
                                                }}
                                              >
                                                {seasonRace[swiperIndex]
                                                  ?.pointsStatus && (
                                                  <td>
                                                    <span
                                                      className="mr-3"
                                                      style={{
                                                        whiteSpace: "pre",
                                                      }}
                                                    >
                                                      {res?.teamEventPoints[0]
                                                        ?.raceTeamRank
                                                        ? `# ${res?.teamEventPoints[0]?.raceTeamRank}`
                                                        : "--"}
                                                    </span>
                                                  </td>
                                                )}
                                                <td>
                                                  <div className="d-flex align-items-baseline">
                                                    <span className="team">
                                                      <img
                                                        src={
                                                          startsWithHttp(
                                                            res?.ownerId?.image
                                                          )
                                                            ? res?.ownerId
                                                                ?.image
                                                            : ImageURL +
                                                              res?.ownerId
                                                                ?.image
                                                        }
                                                        alt="team-logo"
                                                        width="97px"
                                                        height="64px"
                                                        style={{
                                                          objectFit: "contain",
                                                        }}
                                                        onError={(e) => {
                                                          e.target.src =
                                                            "images/racing/user.svg";
                                                        }}
                                                      />
                                                    </span>
                                                    <span className=" set-name-width">
                                                      <p>
                                                        {res?.ownerId?.name
                                                          ? res?.ownerId?.name
                                                          : "--"}
                                                      </p>
                                                    </span>
                                                  </div>
                                                </td>
                                                <td>
                                                  {res?.teamEventPoints[0]
                                                    ?.qualifyingRacePoints
                                                    ? res?.teamEventPoints[0]
                                                        ?.qualifyingRacePoints
                                                    : "Pending"}
                                                </td>
                                                <td>
                                                  {res?.teamEventPoints[0]
                                                    ?.mainRacePoints
                                                    ? res?.teamEventPoints[0]
                                                        ?.mainRacePoints
                                                    : "Pending"}
                                                </td>
                                                <td>
                                                  {res?.teamEventPoints[0]
                                                    ?.raceTeamPoints
                                                    ? res?.teamEventPoints[0]
                                                        ?.raceTeamPoints
                                                    : "Pending"}
                                                </td>
                                              </tr>
                                            ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {leaderboard_tab === "constructors" && (
                              <div
                                id="constructors-leader"
                                class="tab-pane active show constructors-table"
                              >
                                <div class="row">
                                  <div class="col-12">
                                    <div class="table_next_race border table_view">
                                      <table>
                                        <tbody>
                                          <tr>
                                            {seasonRace[swiperIndex]
                                              ?.pointsStatus && <th>Rank</th>}
                                            <th>All Constructors</th>
                                            <th></th>
                                            <th className="text-center">
                                              Q. Pts
                                            </th>
                                            <th className="text-center">
                                              Race Pts
                                            </th>
                                            <th className="text-center">
                                              Total Pts
                                            </th>
                                          </tr>
                                          {ConstrutorData &&
                                            ConstrutorData?.map((res, i) => (
                                              <tr>
                                                {seasonRace[swiperIndex]
                                                  ?.pointsStatus && (
                                                  <td>
                                                    <span
                                                      class="mr-3"
                                                      style={{
                                                        whiteSpace: "pre",
                                                      }}
                                                    >
                                                      {res?.eventPoint?.raceRank
                                                        ? `# ${res?.eventPoint?.raceRank}`
                                                        : "--"}
                                                    </span>
                                                  </td>
                                                )}
                                                <td
                                                  class="Constructors-design"
                                                  data-toggle="modal"
                                                  data-target="#exampleModal5"
                                                >
                                                  <span class="mr-3">
                                                    {res?.name}
                                                  </span>
                                                </td>
                                                <td>
                                                  <img
                                                    class="car_align_Raceleaderboard"
                                                    src={
                                                      startsWithHttp(res?.image)
                                                        ? res?.image
                                                        : ImageURL + res?.image
                                                    }
                                                    width="189px"
                                                    alt="team-logo"
                                                  />
                                                </td>
                                                <td>
                                                  {res?.eventPoint
                                                    ?.qualifyingRacePoints
                                                    ? res?.eventPoint
                                                        ?.qualifyingRacePoints
                                                    : !res?.eventPoint
                                                        ?.qualifyingRacePoints &&
                                                      seasonRace[swiperIndex]
                                                        ?.pointsStatus
                                                    ? "--"
                                                    : "pending"}
                                                </td>
                                                <td>
                                                  {res?.eventPoint
                                                    ?.mainRacePoints
                                                    ? res?.eventPoint
                                                        ?.mainRacePoints
                                                    : !res?.eventPoint
                                                        ?.mainRacePoints &&
                                                      seasonRace[swiperIndex]
                                                        ?.pointsStatus
                                                    ? "--"
                                                    : "Pending"}
                                                </td>
                                                <td>
                                                  {res?.eventPoint
                                                    ? res?.eventPoint
                                                        ?.racePoints
                                                    : !res?.eventPoint
                                                        ?.racePoints &&
                                                      seasonRace[swiperIndex]
                                                        ?.pointsStatus
                                                    ? "--"
                                                    : "Pending"}
                                                </td>
                                              </tr>
                                            ))}
                                        </tbody>
                                      </table>
                                    </div>
                                    {loading ? <Customloader /> : ""}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        {leaderboard_tab === "user" && (
                          <div className="col-lg-6 col-12">
                            {!MyRaceTeamView?.driverIds &&
                              !MyRaceTeamView?.constructorIds &&
                              !MyRaceTeamView?.loserId && (
                                <div
                                  className="welcome-white-box py-5 bg-body-secondary rounded-25"
                                  style={{ height: "555px" }}
                                >
                                  <div className="match-white-box mt-3">
                                    <div className="half-hero-heading">
                                      <p>Drivers</p>
                                    </div>
                                    <ul
                                      className="d-flex inner-match-ul first-li py-0 px-2"
                                      style={{ width: "100%" }}
                                    >
                                      <li>
                                        <ul>
                                          {true && (
                                            <div className="mt-2">
                                              <img
                                                src="images/racing/5driver.png"
                                                alt=""
                                                className="img-fluid"
                                              />
                                            </div>
                                          )}
                                        </ul>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="match-white-box mt-3">
                                    <div className="half-hero-heading">
                                      <p>Constructors</p>
                                    </div>
                                    <ul className="d-flex inner-match-ul first-li py-0 px-2">
                                      <li>
                                        <ul>
                                          {true && (
                                            <div className="mt-2">
                                              <img
                                                src="images/racing/2constructor.png"
                                                alt=""
                                                className="img-fluid"
                                              />
                                            </div>
                                          )}
                                        </ul>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="match-white-box mt-3">
                                    <div className="half-hero-heading">
                                      <p>Losing Driver</p>
                                    </div>
                                    <ul className="d-flex inner-match-ul first-li py-0 px-2">
                                      <li>
                                        <ul>
                                          {true && (
                                            <div className="mt-2">
                                              <img
                                                src="images/racing/team-driver.png"
                                                alt=""
                                                className="img-fluid"
                                              />
                                            </div>
                                          )}
                                        </ul>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              )}
                            {MyRaceTeamView?.driverIds &&
                              MyRaceTeamView?.constructorIds &&
                              MyRaceTeamView?.loserId && (
                                <div
                                  className={
                                    MyRaceTeamView?.isNoCap
                                      ? "bg-primary-subtle leader-board-team pb-2 rounded-25 welcome-white-box"
                                      : "bg-body-secondary leader-board-team pb-2 rounded-25 welcome-white-box"
                                  }
                                >
                                  <p
                                    class="mb-0 pb-0 font-weight-bold fs-5 pl-3"
                                    style={{ color: "#1c4ba5" }}
                                  >
                                    {MyRaceTeamView?._id === userData?.myTeamId
                                      ? "My"
                                      : `${MyRaceTeamView?.ownerId?.name}'s`}{" "}
                                    Team
                                  </p>
                                  {seasonRace[swiperIndex]?.pointsStatus && (
                                    <>
                                      <div className="align-items-center bg_theme d-flex flex-column justify-content-center position-absolute px-1 shadow text-white my_race_team_card">
                                        <p class="mb-0 pb-0 font-weight-bold">
                                          Race Rank- #
                                          {MyRaceTeamView?.teamEventPoints[
                                            myTeamPointsIndex
                                          ]?.raceTeamRank
                                            ? MyRaceTeamView?.teamEventPoints[
                                                myTeamPointsIndex
                                              ]?.raceTeamRank
                                            : "1"}
                                        </p>
                                        <p class="mb-0 pb-0 font-weight-bold">
                                          Pts-{" "}
                                          {MyRaceTeamView?.teamEventPoints[
                                            myTeamPointsIndex
                                          ]?.raceTeamPoints
                                            ? MyRaceTeamView?.teamEventPoints[
                                                myTeamPointsIndex
                                              ]?.raceTeamPoints
                                            : "0"}
                                        </p>
                                      </div>
                                    </>
                                  )}

                                  <div className="match-white-box pt-2">
                                    <div className="half-hero-heading">
                                      <p>Drivers</p>
                                    </div>
                                    <ul className="d-flex inner-match-ul first-li px-2 justify-content-center px-2 my-0 overflow-hidden">
                                      <li>
                                        <ul className="px-1 d-flex flex-wrap justify-content-center flex-lg-nowrap">
                                          {MyRaceTeamView?.driverIds &&
                                            MyRaceTeamView?.driverIds?.map(
                                              (item, i) => (
                                                <li className="text-center mx-2">
                                                  <a>
                                                    <img
                                                      src={
                                                        startsWithHttp(
                                                          item?.image
                                                        )
                                                          ? item?.image
                                                          : ImageURL +
                                                            item?.image
                                                      }
                                                      alt="..."
                                                      onError={(e) => {
                                                        e.target.src =
                                                          "images/racing/team-driver.png";
                                                      }}
                                                      className="img-fluid img-responsive center-block"
                                                    />
                                                  </a>
                                                  <p
                                                    className={
                                                      item?.isCaptain
                                                        ? "name-list my-1 boaster_player_selection"
                                                        : item?.is3xCaptain
                                                        ? "name-list my-1 boaster_player_selection_3x"
                                                        : "name-list my-1"
                                                    }
                                                  >
                                                    {item?.name}
                                                  </p>
                                                  <p>$ {item?.funds}m</p>
                                                </li>
                                              )
                                            )}
                                        </ul>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="match-white-box">
                                    <div className="half-hero-heading">
                                      <p>Constructors</p>
                                    </div>
                                    <ul className="d-flex inner-match-ul first-li px-2 justify-content-center px-2 my-0 overflow-hidden">
                                      <li>
                                        <ul className="d-flex px-0">
                                          {MyRaceTeamView?.constructorIds &&
                                            MyRaceTeamView?.constructorIds?.map(
                                              (item, i) => (
                                                <li className="text-center car mx-2">
                                                  <a>
                                                    <img
                                                      className="img-fluid img-responsive center-block car-team"
                                                      src={
                                                        startsWithHttp(
                                                          item?.image
                                                        )
                                                          ? item?.image
                                                          : ImageURL +
                                                            item?.image
                                                      }
                                                      alt="..."
                                                      onError={(e) => {
                                                        e.target.src =
                                                          "images/racing/car1.png";
                                                      }}
                                                    />
                                                  </a>
                                                  <p className="name-list m-auto">
                                                    {item?.name}
                                                  </p>
                                                  <p>${item?.funds}m</p>
                                                </li>
                                              )
                                            )}
                                        </ul>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="match-white-box">
                                    <div className="half-hero-heading">
                                      <p>Losing Driver</p>
                                    </div>
                                    <ul className="d-flex inner-match-ul first-li px-2 justify-content-center px-2 my-0">
                                      <li>
                                        <ul className="px-0">
                                          {MyRaceTeamView?.loserId && (
                                            <li className="text-center">
                                              <a>
                                                <img
                                                  className="img-fluid img-responsive center-block"
                                                  src={
                                                    startsWithHttp(
                                                      MyRaceTeamView?.loserId
                                                        ?.image
                                                    )
                                                      ? MyRaceTeamView?.loserId
                                                          ?.image
                                                      : ImageURL +
                                                        MyRaceTeamView?.loserId
                                                          ?.image
                                                  }
                                                  alt="..."
                                                  onError={(e) => {
                                                    e.target.src =
                                                      "images/racing/team-driver.png";
                                                  }}
                                                />
                                              </a>
                                              <p className="name-list my-1">
                                                {MyRaceTeamView?.loserId?.name}
                                              </p>
                                            </li>
                                          )}
                                        </ul>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {loading ? <Customloader /> : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
