/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GetService, PostService } from "../../../Services/ConstantService";
import { API_URL } from "../../../Services/ApiService";
import {
  showErrorAlert,
  showSuccessforgotAlert,
} from "../../../Helper/SweetAlert";
import { format } from "date-fns";
import { useFormik } from "formik";
import { Spinner } from "react-bootstrap";

export default function Forgotpassword() {
  const navigate = useNavigate();
  const [submitloader, setsubmitLoader] = useState(false);
  const [typepassword, settypepassword] = useState("password");
  const [typeConfirmpassword, settypeConfirmpassword] = useState("password");
  const [submit, setsubmit] = useState(false);
  const { token } = useParams();
  const formik = useFormik({
    initialValues: {
      confirmPassword: "",
      newPassword: "",
    },
    validate: (values) => {
      const errors = {};

      // Validate 'newPassword ' field
      if (!values.newPassword) {
        errors.newPassword = "newPassword  is required";
      }

      // Validate 'confirmpassword' field
      if (!values.confirmPassword) {
        errors.confirmPassword = "confirmPassword is required";
      }

      return errors;
    },

    onSubmit: (values) => {
      console.log(token);
      setsubmit(true);
      if (formik.isValid) {
        setsubmitLoader(true);
        values.token = token;
        PostService(API_URL.FORGOT_PASSWORD_BY_TOKEN, values).then(
          (res) => {
            if (res?.data?.status) {
              showSuccessforgotAlert(res?.data?.message, "").then((res) => {
                if (res?.isConfirmed) {
                  navigate("/Home");
                }
              });
              setsubmitLoader(false);
            } else {
              showErrorAlert(res?.data?.message);
              setsubmitLoader(false);
            }
          },
          (errors) => {
            setsubmitLoader(false);
          }
        );
      }
    },
  });
  return (
    <div>
      {/*upcoming race wrapper start*/}
      <div
        className="upcome_race_wrapper float_left leader-board-team pt-0"
        style={{ height: "100vh" }}
      >
       
        <div className="align-items-center container d-flex h-100 justify-content-center my-5">
          <div
            className="border-0 card py-3 row shadow"
            style={{ width: "500px" }}
          >
            <div className="login__heading">
              <h5 className="login__title">Reset Password</h5>
              <div className="panel panel-default">
                <div className="panel-body">
                  <form
                    className="form-horizontal"
                    onSubmit={formik.handleSubmit}
                  >
                    <div className="form-group">
                      <label className="control-label">Password</label>
                      <div className="col-md-12">
                        <input
                          type={typepassword}
                          className={`form-control ${
                            formik.errors.newPassword && submit
                              ? "border_error"
                              : ""
                          }`}
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          name="newPassword"
                          placeholder="Enter Password"
                        />
                         <span
                          className={
                            typepassword === "password"
                              ? "fa-solid fa-eye-slash field-icon toggle-password"
                              : "fa-solid fa-eye field-icon toggle-password"
                          }
                          onClick={() =>
                            settypepassword(
                              typepassword === "password" ? "text" : "password"
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label">Confirm Password</label>
                      <div className="col-md-12">
                        <input
                          id="password-field"
                          type={typeConfirmpassword}
                          className={`form-control ${
                            (formik.values.confirmPassword !==
                              formik.values.newPassword ||
                              formik.errors.confirmPassword) &&
                            submit
                              ? "border_error"
                              : ""
                          }`}
                          value={formik.values.confirmPassword}
                          onChange={formik.handleChange}
                          name="confirmPassword"
                          placeholder="Enter Confirm Password"
                        />
                        <span
                          className={
                            typeConfirmpassword === "password"
                              ? "fa-solid fa-eye-slash field-icon toggle-password"
                              : "fa-solid fa-eye field-icon toggle-password"
                          }
                          onClick={() =>
                            settypeConfirmpassword(
                              typeConfirmpassword === "password" ? "text" : "password"
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group ">
                      <div className="col-md-12">
                        <div className="login-btn">
                          <button
                            type="submit"
                            className="btn btn-primary login-btn"
                            onClick={() => setsubmit(true)}
                          >
                            <div className="d-flex justify-content-center align-items-center px-3 rounded-25">
                              <span> Submit</span>
                              <Spinner
                                animation="border"
                                role="status"
                                size="sm"
                                className={
                                  submitloader ? "mx-2" : "mx-2 d-none"
                                }
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </Spinner>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <p />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
