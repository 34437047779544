/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import "swiper/css";
import "swiper/css/pagination";
import { GetService } from "../../Services/ConstantService";
import { API_URL } from "../../Services/ApiService";
import { showErrorAlert } from "../../Helper/SweetAlert";
import Customloader from "../../Helper/CustomLoader";

export default function ContactUs() {
  const [loading, setLoading] = useState(false);
  const [contactUs, setcontactUs] = useState("");

  useEffect(() => {
    getContactInfo();
  }, []);
  const getContactInfo = () => {
    setLoading(true);
    GetService(`${API_URL.GET_CMS}?type=Contact`).then(
      (res) => {
        if (res.data.status === true && res?.data?.data) {
          setcontactUs(res?.data?.data[0]);
          setLoading(false);
        } else {
          showErrorAlert(res.data?.message);
          setLoading(false);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
        setLoading(false);
      }
    );
  };
  return (
    <div>
      <div className="upcome_race_wrapper float_left leader-board-team mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ft_left_heading_wraper head1 center_headign text-center">
                <h1>Contact Us</h1>
                <p className="py-1">
                  Need help? Our crew is here to make sure your fantasy
                  <br /> experience is smooth and thrilling.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="d-flex justify-content-center align-items-center h-100">
                <img src="images/racing/f1-car.png" alt="logo" className="h-100 w-100" />
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="white-pre-line"
                dangerouslySetInnerHTML={{ __html: contactUs?.description }}
              />
            </div>
          </div>
        </div>
        {loading ? <Customloader /> : ""}
      </div>
    </div>
  );
}
