import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

export function showSuccessAlert(message, callback) {
    Swal.fire({
      title: "Success!",
      text: message,
      icon: "success",
      confirmButtonColor: "#1C4BA5",
      confirmButtonText: "Close",
      allowOutsideClick: false,
    }).then((result) => {
        if (result.isConfirmed && callback) {
          callback();
        }
      });
  }

  export function showSuccesstoaster(message) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      }
    });
    Toast.fire({
      icon: "success",
      title: `${message}`
    });
  }
  export function showSuccessforgotAlert(message, callback) {
  return  Swal.fire({
      title: "Success!",
      text: message,
      icon: "success",
      confirmButtonColor: "#1C4BA5",
      confirmButtonText: "Close",
      allowOutsideClick: false,
    })
  }

  export function showLoginAlert(message,title,callback) {
    Swal.fire({
      title: title,
      text: message,
      icon: "success",
      confirmButtonColor: "#1C4BA5",
      confirmButtonText: "Close",
      allowOutsideClick: false,
      backdrop: `
        rgba(0,0,0,0.5)
        url("/images/racing/leaderboard-bg.png")
        center bottom
        no-repeat
      `
    }).then((result) => {
        if (result.isConfirmed && callback) {
          callback();
        }
      });
  }
  
  export function showErrorAlert(message, callback) {
    Swal.fire({
      title: "Error!",
      text: message,
      icon: "error",
      confirmButtonColor: "#FF5733",
      confirmButtonText: "Close",
      allowOutsideClick: false,
    }).then((result) => {
        if (result.isConfirmed && callback) {
          callback();
        }
      });
  }

  export function showDeleteConfirmation(itemName,callback) {
    Swal.fire({
        title: `Delete ${itemName}`,
        text: `Are you sure you want to delete this ${itemName}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1F4CE0",
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed && callback) {
        callback();
      }
    });
  }

  export function showCreateTeam(message, buttonname) {
   return Swal.fire({
      title: `<div><img src="images/racing/celebration1.gif" /></div>
      <div class="align-items-center d-flex justify-content-center text-capitalize" style="white-space: nowrap;">Congratulations !</div>`,
      text:`${message}`,
      color: "#000",
      confirmButtonColor: "#1C4BA5",
      confirmButtonText: `
      ${buttonname} <i class="fa-solid fa-arrow-right px-2"></i>
    `,
    background: '#fff',
    customClass: {
      confirmButton: 'rounded-25 font_smaller'
    },
      backdrop: `
        rgba(0,0,0,0.5)
        url("/images/racing/leaderboard-bg.png")
        center bottom
        no-repeat
      `
    });
  }

  export function showPreviourTeam() {
    return Swal.fire({
      title: `Warning <i class="text-warning fa-solid fa-triangle-exclamation pl-2"></i>`,
      text: `Are you sure you want to discard your current changes and revert to the previous team's version?`,
      width: 600,
      padding: "2em",
      color: "#000",
      confirmButtonColor: "#1C4BA5",
      confirmButtonText: "Yes!",
      background: "#fff ",
      showCloseButton: true,
      backdrop: `
        rgba(0,0,0,0.5)
        url("/images/racing/leaderboard-bg.png")
        center bottom
        no-repeat
      `
    });
  }

  export function showOopsPopup(message,btntext) {
    return Swal.fire({
      html: `
        <div style="text-align: center;">
        <i class="fa-4x fa-face-frown fa-solid text-light-emphasis"></i>
        <div class="font-weight-bold fs-3 mt-1">Oops..</div>
          <div>${message}</div>
        </div>`,
      width: 600,
      padding: '1em',
      color: '#000',
      confirmButtonColor: '#1C4BA5',
      confirmButtonText: `
        ${btntext} <i class="fa-solid fa-arrow-right px-2"></i>
      `,
      background: '#fff',
      customClass: {
        confirmButton: 'rounded-25 font_smaller'
      },
      showCloseButton: true,
      backdrop: `
        rgba(0,0,0,0.5)
        url("/images/racing/leaderboard-bg.png")
        center bottom
        no-repeat
      `,
    });
  }

  export function showHappypopup(message,btntext) {
    return Swal.fire({
      html: `
        <div style="text-align: center;">
        <i class="fa-4x fa-solid fa-face-smile text-light-emphasis"></i>
        <div class="font-weight-bold fs-3 mt-1">League Joined</div>
          <div>${message}</div>
        </div>`,
      width: 600,
      padding: '1em',
      color: '#000',
      confirmButtonColor: '#1C4BA5',
      confirmButtonText: `
        ${btntext} <i class="fa-solid fa-arrow-right px-2"></i>
      `,
      background: '#fff',
      customClass: {
        confirmButton: 'rounded-25 font_smaller'
      },
      showCloseButton: true,
      backdrop: `
        rgba(0,0,0,0.5)
        url("/images/racing/leaderboard-bg.png")
        center bottom
        no-repeat
      `,
    });
  }


  export function showAccountVerify() {
    return Swal.fire({
      title: `Warning <i class="text-warning fa-solid fa-triangle-exclamation pl-2"></i>`,
      text: `your Account is not verified continue to verify.`,
      width: 600,
      padding: "2em",
      color: "#000",
      confirmButtonColor: "#1C4BA5",
      confirmButtonText: "Contiune",
      background: "#fff ",
      showCloseButton: true,
      backdrop: `
        rgba(0,0,0,0.5)
        url("/images/racing/leaderboard-bg.png")
        center bottom
        no-repeat
      `
    });
  }
  
 
  
  
  