/* eslint-disable no-script-url */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import axios from "axios";
import { API_URL } from "../../../Services/ApiService";
import {
  showErrorAlert,
  showLoginAlert,
  showSuccessAlert,
  showSuccesstoaster
} from "../../../Helper/SweetAlert";
import { useGoogleLogin } from "@react-oauth/google";
import {
  GetService,
  PostFormData,
  PostService,
} from "../../../Services/ConstantService";
import { Modal, Spinner } from "react-bootstrap";
import { requestForToken } from "../../../Common/firebase";

export default function Signup() {
  const navigate = useNavigate();
  const [submitloader, setsubmitLoader] = useState(false);
  const [submit, setsubmit] = useState(false);
  const [term_condition, setterm_condition] = useState(false);
  const [typepassword, settypepassword] = useState("password");
  const [confirm, setconfirm] = useState("password");
  const [show, setShow] = useState(false);
  const [userRegisterdata, setuserRegisterdata] = useState("");
  const [country, setcountry] = useState("");
  const [seconds, setSeconds] = useState(300);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [FCM_token, setFCM_token] = useState("");

  const otpInputs = useRef([]);

  useEffect(() => {
    requestForToken().then((e) => {
      setFCM_token(e);
    });
  }, []);

  const handleOtpChange = (e, index) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);
    if (e.target.value !== "" && index < otpInputs.current.length - 1) {
      otpInputs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      otpInputs.current[index - 1].focus();
    }
  };

  const handleTimerLogic = () => {
    let interval;

    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (seconds <= 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  };

  function getcountry() {
    GetService(API_URL.GET_COUNTRY).then((res) => {
      if (res?.data?.status) {
        setcountry(res?.data?.data);
      } else {
        showErrorAlert(res?.data?.message);
      }
    });
  }
  const handleClose = () => setShow(false);
  useEffect(() => {
    getcountry();
  }, []);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      name: "",
      phonenumber: "",
      confirm_password: "",
      terms_condition: "",
      profilePicture: "",
      address: "",
      country: "",
    },
    validate: (values) => {
      const errors = {};

      // Validate 'password' field
      if (values.password?.length<8) {
        errors.password = "password is required";
      }
      // Validate 'confirm_password' field
      if (!values.confirm_password) {
        errors.confirm_password = "confirm_password is required";
      }

      // Validate 'email' field
      if (!values.email) {
        errors.email = "email is required";
      }

      // Validate 'country' field
      if (!values.country) {
        errors.country = "country is required";
      }

      // Validate 'name' field
      if (!values.name) {
        errors.name = "name is required";
      }

      return errors;
    },

    onSubmit: (values) => {
      setsubmit(true);
      setsubmitLoader(true);
      if (formik.isValid) {
        if (FCM_token) {
          values.FCM_token = FCM_token;
        }
        axios.post(API_URL.REGISTRATION, values).then(
          (res) => {
            if (res?.data?.status === true) {
              showSuccesstoaster(res?.data?.message)

              // setuserRegisterdata(values);
              document.getElementById("closemodalregister")?.click();
              document.getElementById("loginModal")?.click();
              setsubmit(false);
              setsubmitLoader(false);

              // setShow(true);
              // setTimeout(() => {
              //   otpInputs.current[0]?.focus();
              // }, 1000);
              // handleTimerLogic();
            } else {
              setsubmitLoader(false);
              showErrorAlert(res.data?.message);
            }
          },
          (err) => {
            console.log(err);
            setsubmitLoader(false);
            showErrorAlert(err.response?.data?.message);
          }
        );
      }
    },
  });

  function verifyotp() {
    console.log(otp, userRegisterdata);
    const data = {
      email: userRegisterdata?.email,
      otp: parseInt(otp.join("")),
    };
    axios.post(API_URL.VERIFY_OTP, data).then(
      (res) => {
        if (res?.data?.status === true) {
          showLoginAlert(
            res?.data?.message,
            `Hi, ${res?.data?.data?.name}`,
            () => {
              const data = {
                token: res?.data?.data?.token,
                userName: res?.data?.data?.name,
                id: res?.data?.data?._id,
                profile: res?.data?.data?.image ? res?.data?.data?.image : "",
              };
              if (data) {
                localStorage.setItem("userdata", JSON.stringify(data));
              }
              setsubmit(false);
              setsubmitLoader(false);
              setShow(false);
              window?.location?.reload();
            }
          );
        } else {
          setsubmitLoader(false);
          setOtp(['','','',''])
          setSeconds(300)
          showErrorAlert(res.data?.message);
        }
      },
      (err) => {
        console.log(err);
        setsubmitLoader(false);
        showErrorAlert(err.response?.data?.message);
      }
    );
  }
  function resendOtp() {
    setSeconds(300)
    const data = {
      email: userRegisterdata?.email,
      name: userRegisterdata?.name
    };
    axios.post(API_URL.RESEND_OTP, data).then(
      (res) => {
        if (res?.data?.status === true) {
          showSuccesstoaster(res?.data?.message)
          handleTimerLogic()
          setsubmitLoader(false);
        } else {
          setsubmitLoader(false);
          showErrorAlert(res.data?.message);
        }
      },
      (err) => {
        console.log(err);
        setsubmitLoader(false);
        showErrorAlert(err.response?.data?.message);
      }
    );
  }

  function RegisterWithGoogle(access_token) {
    axios
      .get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        if (res?.data?.verified_email) {
          const data = {
            logintype: "google",
            googlekey: res?.data?.id,
            name:
              res?.data?.given_name + " " + res?.data?.family_name
                ? res?.data?.family_name
                : "",
            email: res?.data?.email,
            image: res?.data?.picture,
          };
          if (FCM_token) {
            data.FCM_token = FCM_token;
          }
          PostService(API_URL.ADMIN_LOGIN, data).then((res) => {
            if (res?.data?.status === true) {
              showSuccessAlert(res?.data?.message, () => {
                const data = {
                  token: res?.data?.data?.accessToken,
                  userName: res?.data?.data?.updatedUser?.name,
                  id: res?.data?.data?.updatedUser?._id,
                  profile: res?.data?.data?.updatedUser?.image,
                  history:
                    res?.data?.data?.updatedUser?.teams?.length > 0
                      ? true
                      : false,
                };
                if (data) {
                  localStorage.setItem("userdata", JSON.stringify(data));
                }

                document.getElementById("closemodalregister")?.click();
                setsubmit(false);
                setsubmitLoader(false);
                window?.location?.reload();
              });
            } else {
              setsubmitLoader(false);
              showErrorAlert(res.data?.message);
            }
          });
        }
      });
  }
  const reset = () => {
    setsubmit(false);
    setsubmitLoader(false);
    formik.resetForm();
  };
  return (
    <div>
      <div
        className="modal fade"
        id="RegisterModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content loginarea__wraper">
            <div className="modal-body">
              <div className>
                <div className="login__heading">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    id="closemodalregister"
                    aria-label="Close"
                    onClick={reset}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <h5 className="login__title">Register</h5>
                  <p className="login__description">
                    Join the thrill ride! Sign up for <br />
                    Fantasy Race Pace.
                  </p>
                  <div className="panel panel-default">
                    <div className="panel-body">
                      <form
                        className="form-horizontal"
                        onSubmit={formik.handleSubmit}
                      >
                        <div className="form-group">
                          <label className="control-label">Full Name</label>
                          <div className="col-md-12">
                            <input
                              type="text"
                              name="name"
                              placeholder="Enter Your Full Name"
                              className={`form-control ${
                                formik.errors.name && submit
                                  ? "border_error"
                                  : ""
                              }`}
                              value={formik.values.name}
                              onChange={formik.handleChange}
                            />
                          </div>
                        </div>
                        <div className="form-group select">
                          <div className="col-md-12">
                            <label
                              htmlFor="exampleFormControlSelect1"
                              className="control-label select px-0"
                            >
                              Country of residence
                            </label>
                            <select
                              id="exampleFormControlSelect1"
                              className={`form-control ${
                                formik.errors.country && submit
                                  ? "border_error"
                                  : ""
                              }`}
                              value={formik.values.country}
                              onChange={formik.handleChange}
                              name="country"
                            >
                              <option value="">Select Country</option>
                              {country &&
                                country?.map((item, i) => (
                                  <option value={item?.name} key={i}>
                                    {item?.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label">Email</label>
                          <div className="col-md-12">
                            <input
                              type="email"
                              className={`form-control ${
                                formik.errors.email && submit
                                  ? "border_error"
                                  : ""
                              }`}
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              name="email"
                              placeholder="Enter Email"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label">Password</label>
                          <div className="col-md-12">
                            <input
                              
                              type={typepassword}
                              className={`form-control ${
                                formik.errors.password && submit
                                  ? "border_error"
                                  : ""
                              }`}
                              value={formik.values.password}
                              onChange={formik.handleChange}
                              name="password"
                              placeholder="Enter password"
                            />
                            <span
                              className={
                                typepassword === "password"
                                  ? "fa-solid fa-eye-slash field-icon toggle-password"
                                  : "fa-solid fa-eye field-icon toggle-password"
                              }
                              onClick={() =>
                                settypepassword(
                                  typepassword === "password"
                                    ? "text"
                                    : "password"
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label">
                            Confirm Password
                          </label>
                          <div className="col-md-12">
                            <input
                              
                              type={confirm}
                              placeholder="Enter Confirm Password"
                              className={`form-control ${
                                (formik.values.confirm_password !==
                                  formik.values.password ||
                                  formik.errors.confirm_password) &&
                                submit
                                  ? "border_error"
                                  : ""
                              }`}
                              value={formik.values.confirm_password}
                              onChange={formik.handleChange}
                              name="confirm_password"
                            />
                            <span
                              className={
                                confirm === "password"
                                  ? "fa-solid fa-eye-slash field-icon toggle-password"
                                  : "fa-solid fa-eye field-icon toggle-password"
                              }
                              onClick={() =>
                                setconfirm(
                                  confirm === "password" ? "text" : "password"
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group ">
                          <div className="col-md-12">
                            <div className="login-btn">
                              <button
                                type="submit"
                                className="btn btn-primary login-btn"
                                onClick={() => setsubmit(true)}
                              >
                                <div className="d-flex justify-content-center align-items-center">
                                  <span>Register</span>
                                  <Spinner
                                    animation="border"
                                    role="status"
                                    size="sm"
                                    className={
                                      submitloader ? "mx-2" : "mx-2 d-none"
                                    }
                                  ></Spinner>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                        <p className="or-line">or </p>
                        <div className="form-group d-flex justify-content-center">
                          <div className="col-md-6 btn-group px-0">
                            <a
                              className="default__button login__button__1 px-4"
                              style={{
                                backgroundColor: "#EF6358",
                                whiteSpace: "nowrap",
                              }}
                              href="javascript:void(0)"
                              onClick={useGoogleLogin({
                                onSuccess: (tokenResponse) =>
                                  RegisterWithGoogle(
                                    tokenResponse?.access_token
                                  ),
                              })}
                            >
                              <span>
                                <img
                                  src="images/racing/gmail.svg"
                                  className="img-responsive"
                                  alt="logo"
                                />
                              </span>{" "}
                              Register with Google
                            </a>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#registerModal"
                  >
                    {" "}
                  </a>
                  <p />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="border-0"
      >
        <Modal.Body className="pb-0">
          <div className="leader-board-team login__heading pb-3 pt-1 upcome_race_wrapper">
            <button type="button" className="close" onClick={handleClose}>
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="login__title px-3 text-left">Otp Verification</h5>
            <div className="modal-body">
              <div className="container px-0">
                <div className="my-1 d-flex justify-content-center"></div>
                <div className="position-relative">
                  <div className="text-center d-block">
                    <h5>
                      Please enter the OTP to verify your account
                    </h5>
                    <div>
                      {" "}
                      <span className="text-reset opacity-60 fs-6">
                        A code has been sent to {userRegisterdata?.email}
                      </span>
                    </div>
                    <div className="inputs d-flex flex-row justify-content-center mt-2">
                      {otp.map((value, i) => (
                        <input
                          ref={(el) => (otpInputs.current[i] = el)}
                          className="m-2 text-center form-control rounded font-weight-bold"
                          type="text"
                          key={i}
                          maxLength={1}
                          value={value}
                          onChange={(e) => handleOtpChange(e, i)}
                          onKeyDown={(e) => handleKeyDown(e, i)}
                        />
                      ))}
                    </div>
                    {seconds > 1 && (
                      <div className="row">
                        <div className="d-flex justify-content-center">
                          <p>Valid for</p>
                          <h5 className={seconds<60? 'text-danger px-2':'px-2'}>
                            {Math.floor(seconds / 60)}:
                            {seconds % 60 < 10 ? "0" : ""}
                            {seconds % 60}
                          </h5>
                        </div>
                      </div>
                    )}
                    {seconds<=0 && (
                      <div className="text-center">
                        <a href="javascript:void" className="text-decoration-underline" onClick={()=>resendOtp()}>Resend Otp</a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end mt-2">
              <button
                type="submit"
                className="btn btn-primary px-3 rounded-25"
                onClick={() => verifyotp()}
              >
                <div className="d-flex justify-content-center align-items-center">
                  <span> Verify Otp</span>
                  <Spinner
                    animation="border"
                    role="status"
                    size="sm"
                    className={submitloader ? "mx-2" : "mx-2 d-none"}
                  ></Spinner>
                </div>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
