import './App.css';
import Main from './Main/Main';
import Homepage from './Main/Pages/Homepage/Homepage';
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Leaderboard from './Main/Pages/Leaderboard/Leaderboard';
import Profile from './Main/Pages/Profile/Profile';
import Myteam from './Main/Pages/My_team/My_team';
import Createteam from './Main/Pages/Create_Team/Create_Team';
import TermsCondition from './Common/Policies/Terms_condition';
import PrivacyPolicy from './Common/Policies/Privacy_policy';
import { useEffect } from 'react';
import Aboutus from './Common/About_us/About_us';
import Forgotpassword from './Main/Auth/Forgot_Password/ForgotPassword';
import PageNotFound from './Common/404page/404Page';
import Leagues from './Main/Pages/Leagues/Leagues';
import { onMessageListener, requestForToken } from './Common/firebase';
import FAQ from './Common/FAQ/FAQ';
import Rules from './Common/Rules/Rules';
import Statistics from './Main/Pages/Statistics/statistics';
import ContactUs from './Common/Contact_us/Contact_us';
function App() {
  const userdata = JSON.parse(localStorage.getItem("userdata"));
  const isAuthenticated = userdata?.token;

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
      .then(function (registration) {
        console.log('Service Worker registered with scope:', registration.scope);
      }).catch(function (err) {
        console.error('Error registering Service Worker:', err);
      });
  }
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    // Request permission to display notifications
    Notification?.requestPermission().then(permission => {
      if (permission === 'granted') {
        console.log('Notification permission granted');
      } else {
        console.log('Notification permission denied');
      }
    });

    // Request token for receiving messages
    requestForToken();
  }, []);

  useEffect(() => {
    // Listen for incoming messages
    onMessageListener()
      .then(payload => {
        // Display notification
        new Notification(payload?.notification?.title, {
          body: payload?.notification?.body,
          icon: 'https://racepace-images.s3.us-east-1.amazonaws.com/test/uploads/DriverImage/DriverImage_5ee00370-2635-4a6f-8e72-793db12c1fd6.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIASGX5XN5QN5MTT5N2%2F20240419%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240419T122225Z&X-Amz-Expires=43200&X-Amz-Signature=8cc287b43d13cea88c5b0d06f5157141aee04afeb9203a0c245eec1d6ed26b96&X-Amz-SignedHeaders=host&x-id=GetObject'
        });
      })
      .catch(err => console.log('Failed: ', err));
  }, []);

  return (
    <div>
      <Routes>
        <Route path="/forgotPassword/:token" element={<Forgotpassword />} />
        <Route path="" element={<Main />}>
          <Route path="" element={<Navigate to="/Home" />} />
          <Route path="/Home" element={<Homepage />} />
          <Route path="/Terms_condition" element={<TermsCondition />} />
          <Route path="/Privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/About_us" element={<Aboutus />} />
          <Route path="/page_not_found" element={<PageNotFound />} />
          <Route path="/FAQ" element={<FAQ />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/Rules" element={<Rules />} />
          {isAuthenticated && (
            <>
              <Route path="/Leaderboard" element={<Leaderboard />} />
              <Route path="/League_Leaderboard/:leagueId" element={<Leaderboard />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/my_team" element={<Myteam />} />
              <Route path="/edit_team/:id" element={<Createteam />} />
              <Route path="/edit_team/:id/:inviteCode" element={<Createteam />} />
              <Route path="/create_team" element={<Createteam />} />
              <Route path="/create_team/:inviteCode" element={<Createteam />} />
              <Route path="/leagues" element={<Leagues />} />
              <Route path="/statistics" element={<Statistics />} />
              <Route path="/leagues/:invitecode" element={<Leagues />} />
              <Route path="*" element={<Navigate to="/page_not_found" />} />
            </>
          )}
          <Route path="*" element={<Navigate to="/Home" />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
