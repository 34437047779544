/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import AllLeaderboard from "../All_leaderboard/All_leaderboard";
import { API_URL } from "../../../Services/ApiService";
import { showErrorAlert, showSuccessAlert } from "../../../Helper/SweetAlert";
import { GetService, PostService } from "../../../Services/ConstantService";
import Modal from "react-bootstrap/Modal";
import Myteam from "../My_team/My_team";
import { ImageURL } from "../../../Environment/Environment";
import Customloader from "../../../Helper/CustomLoader";
export default function Homepage() {
  const navigate = useNavigate();
  const [DashboardDetails, setDashboardDetails] = useState();
  const [active, setactive] = useState("leaderboard");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showTeam, setshowTeam] = useState(false);
  const [selectTeam, setSelectTeam] = useState();
  const handleClose = () => setShow(false);
  const handleTeamClose = () => setshowTeam(false);
  const [timeDifference, setTimeDifference] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  function handleShow() {
    if (!DashboardDetails?.activeTeam) {
       navigate('/create_team')
    } else if (DashboardDetails?.teams?.length > 0) {
      setshowTeam(true);
    } else if(DashboardDetails?.status !== "inactive"){
      setShow(true);
    }
  }

  const userdata = JSON.parse(localStorage.getItem("userdata"));
  const isAuthenticated = userdata?.token;
  const getDashboard = () => {
    setLoading(true);
    GetService(API_URL.DASHBOARD).then((res) => {
      setLoading(false);
      if (res?.data?.status) {
        setDashboardDetails(res?.data?.data);
        if (res?.data?.data?.status === "inactive") {
          settimecounter(res?.data?.data?.race_date);
        } else {
          settimecounter(res?.data?.data?.qualifying_date);
        }
      } else {
        showErrorAlert(res?.message, "");
      }
    });
  };
  function startsWithHttp(url) {
    const regex = /^(http)/;
    return regex.test(url);
  }
  const handleTeamSelection = (selectedTeam) => {
    setSelectTeam(selectedTeam);
  };
  useEffect(() => {
    getDashboard();
  }, []);
  const joinrace = () => {
    setLoading(true);
    const data = {
      raceId: DashboardDetails?._id,
      teamId: selectTeam?._id,
    };
    PostService(API_URL.JOIN_RACES, data).then((res) => {
      setLoading(false);
      if (res?.data?.status) {
        showSuccessAlert(res?.data?.message, "");
        handleClose();
        navigate("/Leaderboard");
      } else {
        showErrorAlert(res?.data?.message);
        handleClose();
      }
    });
  };

  function settimecounter(date) {
    // Set your target date here
    const targetDate = new Date(date);

    const calculateTimeDifference = () => {
      // Get the current date adjusted to the specified time zone
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const now = new Date().toLocaleString("en-US", {
        timeZone: userTimeZone,
      });
      const currentDateTime = new Date(now);
      const difference = targetDate - currentDateTime;
      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setTimeDifference({ days, hours, minutes, seconds });
      } else {
        // Target date has passed
        setTimeDifference({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };

    const interval = setInterval(calculateTimeDifference, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }
  return (
    <div>
      <div className="main-banner rev_slider_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 text-center hero-text">
              <div className="hero-sub-heading">
                <h4>World's Best Fantasy League</h4>
              </div>
              <div className="hero-heading">
                <h4>Get Ready to Race</h4>
              </div>
              <img
                src="images/racing/text-line.png"
                className="img-responsive"
                alt=""
              />
            </div>
            <div className="col-lg-8 col-md-12 hero-image">
              <img
                src="images/racing/f1-car.png"
                className="img-responsive"
                alt="logo"
              />
            </div>
            <div className="col-lg-4 col-md-12 text-left mb-3">
              <div className="half-hero-heading">
                <p>{DashboardDetails?.name}</p>
              </div>
              <div className="half-sub-heading">
                <p>{DashboardDetails?.description}</p>
              </div>

              <div className="d-flex justify-content-center row">
                {DashboardDetails &&
                  (timeDifference.days !== 0 ||
                    timeDifference.hours !== 0 ||
                    timeDifference.minutes !== 0 ||
                    timeDifference.seconds !== 0) && (
                    <div className="col-lg-8 col-12 p-0 mt-1">
                      <div
                        className={
                          timeDifference.days === 0 &&
                          timeDifference.hours === 0 &&
                          DashboardDetails
                            ? "text-bg-danger d-flex justify-content-around mx-2 py-2 px-2 rounded-25 text-white align-items-center"
                            : "align-items-center d-flex justify-content-around mx-2 px-2 py-2 rounded-25 bg_theme text-white"
                        }
                      >
                        <div
                          className={
                            timeDifference.days === 0 &&
                            timeDifference.hours === 0 &&
                            DashboardDetails
                              ? "badge text-bg-danger border py-2 rounded-25 position-absolute"
                              : "badge bg_theme border py-2 rounded-25 position-absolute"
                          }
                          style={{ top: "-20px" }}
                        >
                          <p
                            className="font-weight-bold mb-0 px-1 text-small"
                            style={{
                              fontSize: "x-small",
                              letterSpacing: "3px",
                            }}
                          >
                            {DashboardDetails?.status === "inactive"
                              ? "Race Countdown"
                              : "Qualifying Countdown"}
                          </p>
                        </div>
                        {timeDifference.days > 0 && (
                          <>
                            <div className="mx-1 text-center font-weight-bold">
                              <p className="mb-1 fs-4">{timeDifference.days}</p>
                              <p className="mb-1 font-weight-normal">Days</p>
                            </div>
                            <span
                              className="border-right"
                              style={{ height: "50px" }}
                            ></span>
                          </>
                        )}
                        {timeDifference.hours > 0 && (
                          <>
                            <div className="mx-1 text-center font-weight-bold">
                              <p className="mb-1 fs-4">
                                {timeDifference.hours}
                              </p>
                              <p className="mb-1 font-weight-normal">Hours</p>
                            </div>
                            <span
                              className="border-right"
                              style={{ height: "50px" }}
                            ></span>
                          </>
                        )}
                        <div className="mx-1 text-center font-weight-bold">
                          <p className="mb-1 fs-4">{timeDifference.minutes}</p>
                          <p className="mb-1 font-weight-normal">Mins</p>
                        </div>
                        <span
                          className="border-right"
                          style={{ height: "50px" }}
                        ></span>
                        <div className="mx-1 text-center font-weight-bold">
                          <p className="mb-1 fs-4">{timeDifference.seconds}</p>
                          <p className="mb-1 font-weight-normal">Sec</p>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              <div className="d-flex justify-content-center">
                <div className="main_button_wrapper mt-2">
                  {!isAuthenticated && (
                    <a
                      className="border-0 res_btn_log"
                      data-toggle="modal"
                      data-target="#loginModal"
                      href="#"
                    >
                      Create Team
                    </a>
                  )}
                  {isAuthenticated &&
                    DashboardDetails?.status !== "inactive" && (
                      <a
                        className="border-0 res_btn_log"
                        href="javascript:void(0)"
                        onClick={() => handleShow()}
                      >
                        {!DashboardDetails?.activeTeam
                          ? "Create Team"
                          : DashboardDetails?.teams?.length > 0
                          ? "View Team"
                          : "Join Race"}
                      </a>
                    )}

                  {isAuthenticated &&
                    DashboardDetails?.status === "inactive" &&  DashboardDetails?.teams?.length > 0 && (
                      <a
                        className="border-0 res_btn_log"
                        href="javascript:void(0)"
                        onClick={() => handleShow()}
                      >
                        View Team
                      </a>
                    )}
                </div>
              </div>
            </div>
          </div>
          {loading ? (
              <Customloader
              />
          ) : (
            ""
          )}
        </div>
      </div>
      {isAuthenticated && <AllLeaderboard activetab={active} />}

      {!isAuthenticated && (
        <div className="container dream-team">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <img
                src="images/racing/dream-team.png"
                className="img-responsive"
                alt="logo"
              />
            </div>
          </div>
        </div>
      )}
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Your Team</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-0">
            <Myteam
              data={DashboardDetails?._id}
              onTeamSelect={handleTeamSelection}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn" onClick={handleClose} className="rounded-25">
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-primary px-4 rounded-25"
            onClick={() => joinrace()}
          >
            Join Race
          </Button>
        </Modal.Footer>
      </Modal>

      {/* view team joined  */}

      <Modal
        show={showTeam}
        size="lg"
        onHide={handleTeamClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className={DashboardDetails?.teams[0]?.isNoCap? 'bg_theme text-white':''}>
          <Modal.Title className="h5">
            <div className="d-flex flex-wrap">
              <span>
                {DashboardDetails?.teams[0]?.name
                  ? DashboardDetails?.teams[0]?.name
                  : "My Team"}
              </span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={DashboardDetails?.teams[0]?.isNoCap?'py-1 pb-3 bg-primary-subtle':'py-1 pb-3'}>
          <div className="row">
            <div className="col-12">
              <div className="welcome-white-box upcome_race_wrapper leader-board-team py-0">
                <div className="d-flex justify-content-end">
                  {DashboardDetails?.teams[0]?.isEdit && (
                    <span
                      className="money money px-2 py-1 rounded"
                      style={{ background: "#1c4ba5" }}
                    >
                      <a href="javascript:void(0)">
                        <img
                          src="images/racing/team-edit.svg"
                          className="img-responsive"
                          alt=""
                          onClick={() => {
                            navigate(
                              `/edit_team/${DashboardDetails?.teams[0]?._id}`
                            );
                          }}
                        />
                      </a>
                    </span>
                  )}
                </div>
                <div className="match-white-box">
                  <div className="half-hero-heading">
                    <p>Drivers</p>
                  </div>
                  <ul className="d-flex inner-match-ul first-li px-2 justify-content-center px-2 my-0 overflow-hidden">
                    <li>
                      {DashboardDetails?.teams[0] &&
                        DashboardDetails?.teams[0]?.driverIds && (
                          <ul className="px-1 d-flex flex-wrap justify-content-center">
                            {DashboardDetails?.teams &&
                              DashboardDetails?.teams[0]?.driverIds?.map(
                                (item, i) => (
                                  <li className="text-center mx-2">
                                    <a>
                                      <img
                                        src={
                                          startsWithHttp(item?.image)
                                            ? item?.image
                                            : ImageURL + item?.image
                                        }
                                        alt="..."
                                        onError={(e) => {
                                          e.target.src =
                                            "images/racing/team-driver.png";
                                        }}
                                        className="img-fluid img-responsive center-block"
                                      />
                                    </a>
                                    <p
                                      className={
                                            item?.isCaptain
                                          ? "name-list my-1 boaster_player_selection"
                                          :  item?.is3xCaptain? "name-list my-1 boaster_player_selection_3x":"name-list my-1"
                                      }
                                    >
                                      {item?.name}
                                    </p>
                                    <p>$ {item?.funds}m</p>
                                  </li>
                                )
                              )}
                            {DashboardDetails?.teams?.length === 0 && (
                              <div className="mt-2">
                                <img
                                  src="images/racing/5driver.png"
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            )}
                          </ul>
                        )}
                    </li>
                  </ul>
                </div>
                <div className="match-white-box">
                  <div className="half-hero-heading">
                    <p>Constructors</p>
                  </div>
                  <ul className="d-flex inner-match-ul first-li px-2 justify-content-center px-2 my-0 overflow-hidden">
                    <li>
                      <ul className="d-flex px-0">
                        {DashboardDetails?.teams &&
                          DashboardDetails?.teams[0]?.constructorIds?.map(
                            (item, i) => (
                              <li className="text-center car mx-2">
                                <a>
                                  <img
                                    className="img-fluid img-responsive center-block car-team"
                                    src={
                                      startsWithHttp(item?.image)
                                        ? item?.image
                                        : ImageURL + item?.image
                                    }
                                    alt="..."
                                    onError={(e) => {
                                      e.target.src = "images/racing/car1.png";
                                    }}
                                  />
                                </a>
                                <p className={"name-list m-auto"}>
                                  {item?.name}
                                </p>
                                <p>${item?.funds}m</p>
                              </li>
                            )
                          )}
                        {DashboardDetails?.teams[0]?.constructorIds?.length ===
                          0 && (
                          <div className="mt-2">
                            <img
                              src="images/racing/2constructor.png"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        )}
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="match-white-box">
                  <div className="half-hero-heading">
                    <p>Losing Driver</p>
                  </div>
                  <ul className="d-flex inner-match-ul first-li px-2 justify-content-center px-2 my-0">
                    <li>
                      <ul className="px-0">
                        {DashboardDetails?.teams[0]?.loserId && (
                          <li className="text-center">
                            <a>
                              <img
                                className="img-fluid img-responsive center-block"
                                src={
                                  startsWithHttp(
                                    DashboardDetails?.teams[0]?.loserId?.image
                                  )
                                    ? DashboardDetails?.teams[0]?.loserId?.image
                                    : ImageURL +
                                      DashboardDetails?.teams[0]?.loserId?.image
                                }
                                alt="..."
                                onError={(e) => {
                                  e.target.src =
                                    "images/racing/team-driver.png";
                                }}
                              />
                            </a>
                            <p className={"name-list my-1"}>
                              {DashboardDetails?.teams[0]?.loserId?.name}
                            </p>
                          </li>
                        )}
                        {!DashboardDetails?.teams[0]?.loserId?._id && (
                          <div className="mt-2">
                            <img
                              src="images/racing/team-driver.png"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        )}
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
