/* eslint-disable no-script-url */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../Services/ApiService";
import { showErrorAlert, showSuccessAlert } from "../../../Helper/SweetAlert";
import {
  GetService,
  PostService,
  convertUtcToTimeZone,
} from "../../../Services/ConstantService";
import { ImageURL } from "../../../Environment/Environment";
import Modal from "react-bootstrap/Modal";
import "../My_team/My_team.css";
import { styled } from "@mui/material/styles";
import Customloader from "../../../Helper/CustomLoader";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export default function Myteam({ data, onTeamSelect }) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [EventInfo, setEventInfo] = useState("");
  const [selectedTeamId, setselectedTeamId] = useState("");
  const [swiperIndex, setSwiperIndex] = useState();
  const swiperRef = useRef(null);
  const handleClose = () => setShow(false);
  const handleShow = (teamid) => {
    GetService(API_URL.DASHBOARD).then((res) => {
      if (res?.data?.status) {
        setselectedTeamId(teamid?._id);
        if (res?.data?.data?.status === "inactive") {
          setEventInfo(res?.data?.data?.nextRace);
        } else {
          setEventInfo(res?.data?.data);
        }

        setShow(true);
      }
    });
  };
  const [loading, setLoading] = useState(false);
  const [myteam, setmyteam] = useState();
  const [selectTeam, setselectTeam] = useState();
  const userdata = JSON.parse(localStorage.getItem("userdata"));
  function startsWithHttp(url) {
    const regex = /^(http)/;
    return regex.test(url);
  }

  const getuserTeams = () => {
    setLoading(true);
    GetService(API_URL.USERS_TEAMS_BY_ID).then((res) => {
      setLoading(false);
      if (res?.data?.status) {
        setmyteam(res?.data?.data);
      } else {
        showErrorAlert(res?.message, "");
      }
    });
  };
  useEffect(() => {
    getuserTeams();
  }, []);

  const handleTeamChange = (item) => {
    if (data) {
      onTeamSelect(item);
      setselectTeam(item);
    }
  };

  const joinrace = () => {
    setLoading(true);
    const data = {
      raceId: EventInfo?._id,
      teamId: selectedTeamId,
    };
    PostService(API_URL.JOIN_RACES, data).then((res) => {
      setLoading(false);
      if (res?.data?.status) {
        showSuccessAlert(res?.data?.message, "");
        handleClose();
        navigate("/Leaderboard");
      } else {
        showErrorAlert(res?.data?.message);
        setselectedTeamId("");
        setEventInfo("");
        handleClose();
      }
    });
  };

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
      setSwiperIndex(swiperRef.current.swiper.realIndex);
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
      setSwiperIndex(swiperRef.current.swiper.realIndex);
    }
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip
      arrow
      placement="left-start"
      {...props}
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#000",
      color: "#fff",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(10),
      padding: "20px",
      borderTopLeftRadius: "25px",
      borderBottomRightRadius: "25px",
      textTransform: "capitalize",
    },
    "& .MuiTooltip-arrow": {
      color: "#000",
    },
  }));
  return (
    <div>
      <div
        className={
          !data
            ? "float_left leader-board-team mt-5 my-team-preview-2 pb-0 pb-sm-4 upcome_race_wrapper"
            : "upcome_race_wrapper float_left leader-board-team py-0 my-team-preview-2"
        }
      >
        <div className="container">
          <div className="row mb-5">
            <div className="col-lg-12 col-md-12 col-sm-12">
              {myteam?.length > 1 && (
                <div
                  className="align-items-center d-flex justify-content-between owl-theme position-absolute w-100"
                  style={{ zIndex: 2, top: "50%", left: 0 }}
                >
                  <div class="owl-nav curson_pointer">
                    <div class="owl-prev" onClick={goPrev}>
                      <i class="flaticon-left-arrow"></i>
                    </div>
                  </div>
                  <div class="owl-nav curson_pointer">
                    <div class="owl-next" onClick={goNext}>
                      <i class="flaticon-right-arrow"></i>
                    </div>
                  </div>
                </div>
              )}
              {myteam?.length === 0 && (
                <div className="welcome-white-box pb-4">
                  <div className="d-flex justify-content-between mb-3 strp">
                    <span className="funds">
                      <h4>My Team</h4>
                    </span>
                  </div>
                  <div className="match-white-box mt-3">
                    <div className="half-hero-heading">
                      <p>Drivers</p>
                    </div>
                    <ul
                      className="d-flex inner-match-ul first-li py-0 px-2"
                      style={{ width: "100%" }}
                    >
                      <li>
                        <ul>
                          {true && (
                            <div className="mt-2">
                              <img
                                src="images/racing/5driver.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          )}
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="match-white-box mt-3">
                    <div className="half-hero-heading">
                      <p>Constructors</p>
                    </div>
                    <ul className="d-flex inner-match-ul first-li py-0 px-2">
                      <li>
                        <ul>
                          {true && (
                            <div className="mt-2">
                              <img
                                src="images/racing/2constructor.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          )}
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="match-white-box mt-3">
                    <div className="half-hero-heading">
                      <p>Losing Driver</p>
                    </div>
                    <ul className="d-flex inner-match-ul first-li py-0 px-2">
                      <li>
                        <ul>
                          {true && (
                            <div className="mt-2">
                              <img
                                src="images/racing/team-driver.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          )}
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              )}

              <Swiper
                ref={swiperRef}
                slidesPerView={1}
                spaceBetween={30}
                modules={[Pagination]}
                className="mySwiper px-3"
                initialSlide={swiperIndex}
                onSwiper={(swiper) => setSwiperIndex(swiper.realIndex)}
              >
                {myteam &&
                  myteam?.map((res, i) => (
                    <SwiperSlide>
                      <div
                        key={i}
                        className={
                          res?.isNoCap
                            ? "welcome-white-box bg-primary-subtle"
                            : "welcome-white-box"
                        }
                        onClick={() => handleTeamChange(res)}
                      >
                        <div
                          className={
                            res?.isleagueTeam
                              ? "d-flex justify-content-between mb-3 strp bg_league bg_theme"
                              : "d-flex justify-content-between mb-3 strp"
                          }
                        >
                          <div>
                            <span className="funds">
                              <h4>{res?.name}</h4>
                            </span>
                            <p className="font_smaller mb-0 opacity-75" style={{letterSpacing:'1px'}}>
                              {res?.leagueId?.name && res?.isleagueTeam
                                ? res?.leagueId?.name
                                : "Leaderboard Team"}
                            </p>
                          </div>

                          <div className="align-items-center d-flex">
                            {res?.isEdit && (
                              <span className="money">
                                <a href="javascript:void(0)">
                                  <img
                                    src="images/racing/team-edit.svg"
                                    className="img-responsive"
                                    alt=""
                                    onClick={() => {
                                      if (res?.leagueId?._id) {
                                        navigate(
                                          `/edit_team/${res?._id}/${res?.leagueId?._id}`
                                        );
                                      } else {
                                        navigate(`/edit_team/${res?._id}`);
                                      }
                                    }}
                                  />
                                </a>
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="match-white-box my-sm-0 my-2">
                          <div className="half-hero-heading">
                            <p>Drivers</p>
                          </div>
                          <ul
                            className="d-flex inner-match-ul first-li py-0 px-2 overflow-hidden my-0"
                            style={{ width: "100%" }}
                          >
                            <li>
                              <ul className="justify-content-center px-0">
                                {res &&
                                  res?.driverIds.map((item) => (
                                    <li className="text-center">
                                      <a href="javascript:void(0)">
                                        <img
                                          src={
                                            startsWithHttp(item?.image)
                                              ? item?.image
                                              : ImageURL + item?.image
                                          }
                                          alt="..."
                                          onError={(e) => {
                                            e.target.src =
                                              "images/racing/team-driver.png";
                                          }}
                                          className="img-fluid img-responsive center-block"
                                        />
                                      </a>
                                      <p
                                        className={
                                          item?.isCaptain
                                            ? "name-list my-1 boaster_player_selection"
                                            : item?.is3xCaptain
                                            ? "name-list my-1 boaster_player_selection_3x"
                                            : "name-list my-1"
                                        }
                                      >
                                        {item?.name}
                                      </p>
                                      <p>${item?.funds}m</p>
                                    </li>
                                  ))}
                                {!myteam && (
                                  <div className="mt-2">
                                    <img
                                      src="images/racing/5driver.png"
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                )}
                              </ul>
                            </li>
                          </ul>
                        </div>
                        <div className="match-white-box my-0 d-none d-sm-block">
                          <div className="half-hero-heading">
                            <p>Constructors</p>
                          </div>
                          <ul className="d-flex inner-match-ul first-li py-0 px-2 my-0">
                            <li>
                              <ul className="px-0">
                                {res &&
                                  res?.constructorIds?.map((item) => (
                                    <li className="text-center car">
                                      <a href="javascript:;">
                                        <img
                                          src={
                                            startsWithHttp(item?.image)
                                              ? item?.image
                                              : ImageURL + item?.image
                                          }
                                          alt="..."
                                          onError={(e) => {
                                            e.target.src =
                                              "images/racing/car1.png";
                                          }}
                                          className="img-fluid img-responsive center-block car-team"
                                        />
                                      </a>
                                      <p
                                        className={
                                          item?.isCaptain
                                            ? "name-list m-auto boaster_player_selection"
                                            : "name-list m-auto"
                                        }
                                      >
                                        {item?.name}
                                      </p>
                                      <p>${item?.funds}m</p>
                                    </li>
                                  ))}
                                {!myteam && (
                                  <div className="mt-2">
                                    <img
                                      src="images/racing/2constructor.png"
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                )}
                              </ul>
                            </li>
                          </ul>
                        </div>
                        <div className="match-white-box my-0 d-none d-sm-block">
                          {res?.loserId && (
                            <div className="half-hero-heading">
                              <p>Losing Driver</p>
                            </div>
                          )}
                          <ul className="d-flex inner-match-ul first-li py-0 px-2 ">
                            <li>
                              <ul className="px-0">
                                {res && res?.loserId && (
                                  <li className="text-center pb-4">
                                    <a href="javascript:void(0)">
                                      <img
                                        src={
                                          startsWithHttp(res?.loserId?.image)
                                            ? res?.loserId?.image
                                            : ImageURL + res?.loserId?.image
                                        }
                                        alt="..."
                                        onError={(e) => {
                                          e.target.src =
                                            "images/racing/team-driver.png";
                                        }}
                                        className="img-fluid img-responsive center-block"
                                      />
                                    </a>
                                    <p
                                      className={
                                        res?.loserId?.isCaptain
                                          ? "name-list my-1 boaster_player_selection"
                                          : "name-list my-1"
                                      }
                                    >
                                      {res?.loserId?.name}
                                    </p>
                                  </li>
                                )}
                                {!myteam && (
                                  <div className="mt-2">
                                    <img
                                      src="images/racing/driver.png"
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                )}
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>
          {loading ? <Customloader /> : ""}
        </div>
      </div>
    </div>
  );
}
