/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../../Services/ApiService";
import { showErrorAlert, showSuccesstoaster } from "../../../Helper/SweetAlert";
import {
  GetService,
  PostService,
  limitDigitsforPoints,
} from "../../../Services/ConstantService";
import { ImageURL, currentDomain } from "../../../Environment/Environment";
import { Button, Modal, Spinner } from "react-bootstrap";
import Customloader from "../../../Helper/CustomLoader";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

export default function Leagues() {
  const navigate = useNavigate();
  const [league_type, setleague_type] = useState("public");
  const [league_tab, setleague_tab] = useState("");
  const { invitecode } = useParams();
  const [sliderValue, setSliderValue] = useState(100);
  const [customcap, setcustomcap] = useState(false);
  const [submitloader, setsubmitLoader] = useState(false);
  const [submit, setsubmit] = useState(false);
  const [leagueLeaderboard, setleagueLeaderboard] = useState("points");
  const [leagueList, setleagueList] = useState("");
  const [SelectedItem, setSelectedItem] = useState("");
  const [inviteCodeLeague, setinviteCodeLeague] = useState("");
  const userdata = JSON.parse(localStorage.getItem("userdata"));
  const [loading, setLoading] = useState(false);

  // Event handler for when the value of the slider changes
  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const handleClose = () => {
    setleague_tab("");
    formik.resetForm();
    setcustomcap(false);
    setSliderValue(100);
    setsubmit(false);
  };
  useEffect(() => {
    if (invitecode && invitecode.length === 6) {
      setinviteCodeLeague(invitecode);
      setleague_tab("Join_League");
    }
  }, [invitecode]);

  const formik = useFormik({
    initialValues: {
      name: "",
      type: "private",
      isLosingDriver: false,
      max3xDriver: false,
      salarycap: 100,
      teamsnumber: "",
      maxNocap: false,
      allowLateJoin: false,
      description: "",
      allowPairs: false,
    },
    validate: (values) => {
      const errors = {};

      // Validate 'name' field
      if (!values.name) {
        errors.name = "name is required";
      }

      // Validate 'teamsnumber' field
      if (!values.teamsnumber) {
        errors.teamsnumber = "teamsnumber is required";
      }

      return errors;
    },
    onSubmit: (values) => {
      setLoading(true);
      const dataobj = {
        name: values?.name,
        type: "private",
        isLosingDriver: values?.isLosingDriver,
        max3xDriver: values?.max3xDriver ? 2 : 0,
        salarycap: customcap ? sliderValue : values?.salarycap,
        teamsnumber: values?.teamsnumber,
        maxNocap: values?.maxNocap,
        allowLateJoin: values?.allowLateJoin,
        leagueLeaderboard: leagueLeaderboard,
        description: values?.description,
        allowPairs:values?.allowPairs ? values?.allowPairs :false
      };
      console.log(dataobj);
      if (formik.isValid) {
        PostService(API_URL.CREATE_LEAGUE, dataobj).then((res) => {
          setLoading(false);
          if (res?.data?.status) {
            navigate(`/create_team/${res?.data?.data?.inviteCode}`);
          }
        });
      }
    },
  });

  const getLeague = () => {
    setLoading(true);
    GetService(`${API_URL.GET_LEAGUE}?type=${league_type.toLowerCase()}`).then(
      (res) => {
        setLoading(false);
        if (res?.data?.status) {
          setleagueList(res?.data?.data);
        } else {
          showErrorAlert(res?.message, "");
        }
      }
    );
  };

  const copyAndPasteUrl = async (url) => {
    try {
      await navigator?.clipboard.writeText(url);
      const copiedUrl = await navigator?.clipboard.readText();
      showSuccesstoaster("Copy to Clipboard");
    } catch (error) {
      console.error("Error copying URL to clipboard:", error);
      // Handle error or show error toaster
    }
  };

  useEffect(() => {
    getLeague();
  }, [league_type]);


  return (
    <div>
      <div className="upcome_race_wrapper float_left leader-board-team mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ft_left_heading_wraper head1 center_headign">
                <h1>The Official RacePace Fantasy leagues</h1>
                <p className="py-1">
                  Experience the ultimate thrill ride with RacePace Fantasy
                  leagues <br />
                  where you can compete against friends as you strategize and
                  race to victory!
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="x_offer_tabs_wrapper tab_online">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    {" "}
                    <a
                      className={
                        league_type === "public"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={() => setleague_type("public")}
                    >
                      Public
                    </a>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <a
                      className={
                        league_type === "All"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={() => setleague_type("All")}
                    >
                      My Leagues
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="select-player-2 tab-content">
              <div id="leaderboard" className="tab-pane active leaderboard">
                {league_type === "All" && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 ">
                      <div className="x_offer_tabs_wrapper tab_online">
                        <ul className="justify-content-center justify-content-lg-start nav nav-tabs">
                          <li className="nav-item flex-grow-none">
                            {" "}
                            <a
                              className={
                                league_tab === "Create_League"
                                  ? "nav-link active show"
                                  : "nav-link"
                              }
                              onClick={() => {
                                setleague_tab("Create_League");
                              }}
                            >
                              Create League
                            </a>
                          </li>
                          <li className="nav-item flex-grow-none">
                            {" "}
                            <a
                              className={
                                league_tab === "Join_League"
                                  ? "nav-link active show"
                                  : "nav-link"
                              }
                              onClick={() => {
                                setleague_tab("Join_League");
                              }}
                            >
                              Join a League
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
                {leagueList?.length === 0 && (
                  <div className="text-center">
                    <img
                      src="images/racing/no_data_transparent.png"
                      alt=""
                      height="300px"
                    />
                  </div>
                )}

                {leagueList &&
                  leagueList?.map((res) => (
                    <div className="mt-3 px-3 px-md-0 row">
                      <div className="border col-12 overflow-hidden px-0">
                        {userdata?.id === res?.createdBy?._id &&
                          res?.userParticipates &&
                          res?.isLeagueOpen && (
                            <div className="align-items-center bg-primary-subtle d-flex justify-content-between px-3 py-2 text-primary">
                              <p className="mb-0">
                                Share this league with your friends
                              </p>
                              <span
                                className="curson_pointer"
                                onClick={() => {
                                  setleague_tab("sharing");
                                  setSelectedItem(res);
                                }}
                              >
                                <i class="fa-solid fa-share-nodes"></i>
                              </span>
                            </div>
                          )}
                        <div>
                          <div>
                            <h5 className=" position-absolute text-white pt-2 px-3">
                              {res?.name}
                            </h5>
                          </div>
                          <img src="images/racing/league_banner.png" alt="" />
                        </div>
                        <div className="bg-white px-4 py-2">
                          <div className="d-flex flex-wrap justify-content-between">
                            <div className="w-75">
                              {/* <h6>{res?.startRace?.name}</h6> */}
                              {res?.description && (
                                <p className="mb-1">{res?.description}</p>
                              )}
                              <p className="font_smaller py-1 mb-1">
                                Created By - {res?.createdBy?.name}
                              </p>
                            </div>

                            <div className="align-items-baseline d-flex d-md-block justify-content-between mb-1">
                              {!res?.userParticipates && res?.isLeagueOpen && (
                                <div className="my-2 my-md-0">
                                  <button
                                    className="bg_league btn font_smaller px-4 rounded-25 text-white bg_theme"
                                    onClick={() => {
                                      setleague_tab("Join_public_League");
                                      setSelectedItem(res);
                                    }}
                                    disabled={!res?.isLeagueOpen}
                                  >
                                    Join League
                                  </button>
                                </div>
                              )}
                              {res?.userParticipates && (
                                <div className="my-2 my-md-0">
                                  <button
                                    className="bg_league btn font_smaller px-4 rounded-25 text-white bg_theme"
                                    onClick={() =>
                                      navigate(
                                        `/League_Leaderboard/${res?._id}`
                                      )
                                    }
                                  >
                                    view League
                                  </button>
                                </div>
                              )}
                              {!res?.isLeagueOpen && (
                                <div className="mt-2">
                                  <span className="font-weight-bold font_smaller px-3 py-1 rounded-25 text-danger">
                                    League Closed !
                                  </span>
                                </div>
                              )}
                              {res?.userParticipates && (
                                <div className="ml-2 ml-md-0 mt-2 text-center">
                                  <i
                                    class="fa-circle-info fa-solid fs-4 text-primary curson_pointer"
                                    onClick={() => {
                                      setleague_tab("league_info");
                                      setSelectedItem(res);
                                    }}
                                  ></i>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="mb-2">
                            <span
                              className={
                                res?.type === "public"
                                  ? "badge bg_league bg_theme px-3 px-4 py-2 rounded-25 text-capitalize"
                                  : "badge bg-danger px-3 px-4 py-2 rounded-25 text-capitalize"
                              }
                            >
                              {res?.type}
                            </span>{" "}
                            <span className="ml-3 font_smaller font-weight-bold">
                              Total Teams :{" "}
                              {res?.teamsnumber === 0
                                ? "Unlimited"
                                : res?.teamsnumber}
                            </span>
                          </div>
                          {res?.type === "All" && (
                            <>
                              <div className="mt-2 align-items-center d-flex justify-content-between mb-1">
                                <div>
                                  {res?.teamsnumber - res?.teams?.length} Teams
                                  left
                                </div>
                                <div>{res?.teamsnumber} Teams</div>
                              </div>
                              <div className="progress w-100 mb-3">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{
                                    width: `${
                                      (res?.teams?.length / res?.teamsnumber) *
                                      100
                                    }%`,
                                  }}
                                  aria-valuenow={0}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                ></div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        {loading ? <Customloader /> : ""}
      </div>
      {/* modal for joining private leagues  */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={league_tab === "Join_League"}
        onHide={handleClose}
        backdrop="static"
      >
        <Modal.Header className="bg_league text-white d-flex justify-content-between align-items-baseline">
          <Modal.Title id="contained-modal-title-vcenter" className="h5 ">
            <div>
              Join a League <br />
              <span className="font_smaller opacity-50 font-weight-light mx-1">
                use the league code shared by friends
              </span>
            </div>
          </Modal.Title>
          <div className="curson_pointer" onClick={handleClose}>
            <i class="fa-solid fa-xmark"></i>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 py-2">
              <input
                type="text"
                className={"form-control rounded-25 p-4"}
                placeholder="Enter League code"
                value={inviteCodeLeague}
                onChange={(e) => setinviteCodeLeague(e?.target?.value)}
                maxLength={6}
              />
            </div>
          </div>
        </Modal.Body>
        <div className="d-flex justify-content-center pb-4">
          <Button
            className="bg_league btn font_smaller px-4 rounded-25 text-white"
            onClick={() => navigate(`/create_team/${inviteCodeLeague}`)}
          >
            Create Team
          </Button>
        </div>
      </Modal>

      {/* modal for creating leagues  */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={league_tab === "Create_League"}
        onHide={handleClose}
        backdrop="static"
      >
        <Modal.Header className="bg_league text-white d-flex justify-content-between align-items-baseline">
          <Modal.Title id="contained-modal-title-vcenter" className="h5 ">
            <div>
              Create Private League <br />
              <span className="font_smaller opacity-50 font-weight-light mx-1">
                Create a League and Invite your friends.
              </span>
            </div>
          </Modal.Title>
          <div className="curson_pointer" onClick={handleClose}>
            <i class="fa-solid fa-xmark"></i>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="row py-2">
              <div className="col-12">
                <input
                  type="text"
                  className={`form-control rounded-25 p-4 ${
                    formik.errors.name && submit ? "border_error" : ""
                  }`}
                  placeholder="Enter League Name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  maxLength={30}
                />
              </div>
              <div className="m-0 mb-3 row">
                <h6 className="py-2 text-center pt-3"># League Include</h6>
                <div className="border col-6 rounded-25 py-2">
                  <div className="col-12">
                    <div className="d-flex justify-content-around">
                      <p className="mb-0 ">Losing Driver</p>
                      <div className="checkbox-wrapper-30">
                        <span className="checkbox">
                          <input
                            type="checkbox"
                            name="isLosingDriver"
                            value={formik.values.isLosingDriver}
                            onChange={formik.handleChange}
                          />
                          <svg>
                            <use
                              xlinkHref="#checkbox-30"
                              className="checkbox"
                            />
                          </svg>
                        </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ display: "none" }}
                        >
                          <symbol id="checkbox-30" viewBox="0 0 22 22">
                            <path
                              fill="none"
                              stroke="#1c4ba5"
                              d="M5.5,11.3L9,14.8L20.2,3.3l0,0c-0.5-1-1.5-1.8-2.7-1.8h-13c-1.7,0-3,1.3-3,3v13c0,1.7,1.3,3,3,3h13 c1.7,0,3-1.3,3-3v-13c0-0.4-0.1-0.8-0.3-1.2"
                            />
                          </symbol>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border col-6 rounded-25 py-2">
                  <div className="col-12">
                    <div className="d-flex justify-content-around">
                      <p className="mb-0  text-danger">3x Driver</p>
                      <div className="checkbox-wrapper-30">
                        <span className="checkbox">
                          <input
                            type="checkbox"
                            name="max3xDriver"
                            value={formik.values.max3xDriver}
                            onChange={formik.handleChange}
                          />
                          <svg>
                            <use
                              xlinkHref="#checkbox-30"
                              className="checkbox"
                            />
                          </svg>
                        </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ display: "none" }}
                        >
                          <symbol id="checkbox-30" viewBox="0 0 22 22">
                            <path
                              fill="none"
                              stroke="#1c4ba5"
                              d="M5.5,11.3L9,14.8L20.2,3.3l0,0c-0.5-1-1.5-1.8-2.7-1.8h-13c-1.7,0-3,1.3-3,3v13c0,1.7,1.3,3,3,3h13 c1.7,0,3-1.3,3-3v-13c0-0.4-0.1-0.8-0.3-1.2"
                            />
                          </symbol>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border col-6 rounded-25 py-2 mt-2">
                  <div className="col-12">
                    <div className="d-flex justify-content-around">
                      <p className="mb-0  text-primary">NO CAP Team</p>
                      <div className="checkbox-wrapper-30">
                        <span className="checkbox">
                          <input
                            type="checkbox"
                            name="maxNocap"
                            value={formik.values.maxNocap}
                            onChange={(e) => {
                              formik.handleChange(e);
                              setcustomcap(false);
                            }}
                          />
                          <svg>
                            <use
                              xlinkHref="#checkbox-30"
                              className="checkbox"
                            />
                          </svg>
                        </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ display: "none" }}
                        >
                          <symbol id="checkbox-30" viewBox="0 0 22 22">
                            <path
                              fill="none"
                              stroke="#1c4ba5"
                              d="M5.5,11.3L9,14.8L20.2,3.3l0,0c-0.5-1-1.5-1.8-2.7-1.8h-13c-1.7,0-3,1.3-3,3v13c0,1.7,1.3,3,3,3h13 c1.7,0,3-1.3,3-3v-13c0-0.4-0.1-0.8-0.3-1.2"
                            />
                          </symbol>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border col-6 rounded-25 py-2 mt-2">
                  <div className="col-12">
                    <div className="d-flex justify-content-around">
                      <p className="mb-0 ">Allow Late Join</p>
                      <div className="checkbox-wrapper-30">
                        <span className="checkbox">
                          <input
                            type="checkbox"
                            name="allowLateJoin"
                            value={formik.values.allowLateJoin}
                            onChange={formik.handleChange}
                          />
                          <svg>
                            <use
                              xlinkHref="#checkbox-30"
                              className="checkbox"
                            />
                          </svg>
                        </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ display: "none" }}
                        >
                          <symbol id="checkbox-30" viewBox="0 0 22 22">
                            <path
                              fill="none"
                              stroke="#1c4ba5"
                              d="M5.5,11.3L9,14.8L20.2,3.3l0,0c-0.5-1-1.5-1.8-2.7-1.8h-13c-1.7,0-3,1.3-3,3v13c0,1.7,1.3,3,3,3h13 c1.7,0,3-1.3,3-3v-13c0-0.4-0.1-0.8-0.3-1.2"
                            />
                          </symbol>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="border col-6 rounded-25 py-2 mt-2">
                  <div className="col-12">
                    <div className="d-flex justify-content-around">
                      <p className="mb-0 ">Allow Pair</p>
                      <div className="checkbox-wrapper-30">
                        <span className="checkbox">
                          <input
                            type="checkbox"
                            name="allowPairs"
                            value={formik.values.allowPairs}
                            onChange={formik.handleChange}
                          />
                          <svg>
                            <use
                              xlinkHref="#checkbox-30"
                              className="checkbox"
                            />
                          </svg>
                        </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ display: "none" }}
                        >
                          <symbol id="checkbox-30" viewBox="0 0 22 22">
                            <path
                              fill="none"
                              stroke="#1c4ba5"
                              d="M5.5,11.3L9,14.8L20.2,3.3l0,0c-0.5-1-1.5-1.8-2.7-1.8h-13c-1.7,0-3,1.3-3,3v13c0,1.7,1.3,3,3,3h13 c1.7,0,3-1.3,3-3v-13c0-0.4-0.1-0.8-0.3-1.2"
                            />
                          </symbol>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 pt-3 text-center">
                  <h6 className="py-2 text-center mb-0">Run League By</h6>

                  <div className="row mt-2">
                    <div className="border col-6 rounded-25 py-2">
                      <div className="col-12 px-0">
                        <div className="d-flex justify-content-around">
                          <p className="mb-0">Points</p>
                          <div className="checkbox-wrapper-30">
                            <span className="checkbox">
                              <input
                                type="checkbox"
                                checked={leagueLeaderboard === "points"}
                                onChange={() => setleagueLeaderboard("points")}
                              />
                              <svg>
                                <use
                                  xlinkHref="#checkbox-30"
                                  className="checkbox"
                                />
                              </svg>
                            </span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ display: "none" }}
                            >
                              <symbol id="checkbox-30" viewBox="0 0 22 22">
                                <path
                                  fill="none"
                                  stroke="#1c4ba5"
                                  d="M5.5,11.3L9,14.8L20.2,3.3l0,0c-0.5-1-1.5-1.8-2.7-1.8h-13c-1.7,0-3,1.3-3,3v13c0,1.7,1.3,3,3,3h13 c1.7,0,3-1.3,3-3v-13c0-0.4-0.1-0.8-0.3-1.2"
                                />
                              </symbol>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border col-6 rounded-25 py-2">
                      <div className="col-12 px-0">
                        <div className="d-flex justify-content-around">
                          <p className="mb-0">Records</p>
                          <div className="checkbox-wrapper-30">
                            <span className="checkbox">
                              <input
                                type="checkbox"
                                checked={leagueLeaderboard === "records"}
                                onChange={() => setleagueLeaderboard("records")}
                              />
                              <svg>
                                <use
                                  xlinkHref="#checkbox-30"
                                  className="checkbox"
                                />
                              </svg>
                            </span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ display: "none" }}
                            >
                              <symbol id="checkbox-30" viewBox="0 0 22 22">
                                <path
                                  fill="none"
                                  stroke="#1c4ba5"
                                  d="M5.5,11.3L9,14.8L20.2,3.3l0,0c-0.5-1-1.5-1.8-2.7-1.8h-13c-1.7,0-3,1.3-3,3v13c0,1.7,1.3,3,3,3h13 c1.7,0,3-1.3,3-3v-13c0-0.4-0.1-0.8-0.3-1.2"
                                />
                              </symbol>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 pt-3 text-center">
                  <h6 className="py-2 text-center mb-0">
                    Salary Cap Fund{" "}
                    <span className="mx-2">
                      {customcap ? sliderValue : 100}m
                    </span>
                  </h6>
                  <div className="row mt-2">
                    <div className="border col-6 rounded-25 py-2">
                      <div className="col-12 px-0">
                        <div className="d-flex justify-content-around">
                          <p className="mb-0">Standard cap</p>
                          <div className="checkbox-wrapper-30">
                            <span className="checkbox">
                              <input
                                type="checkbox"
                                checked={!customcap}
                                onChange={() => setcustomcap(false)}
                              />
                              <svg>
                                <use
                                  xlinkHref="#checkbox-30"
                                  className="checkbox"
                                />
                              </svg>
                            </span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ display: "none" }}
                            >
                              <symbol id="checkbox-30" viewBox="0 0 22 22">
                                <path
                                  fill="none"
                                  stroke="#1c4ba5"
                                  d="M5.5,11.3L9,14.8L20.2,3.3l0,0c-0.5-1-1.5-1.8-2.7-1.8h-13c-1.7,0-3,1.3-3,3v13c0,1.7,1.3,3,3,3h13 c1.7,0,3-1.3,3-3v-13c0-0.4-0.1-0.8-0.3-1.2"
                                />
                              </symbol>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border col-6 rounded-25 py-2">
                      <div className="col-12 px-0">
                        <div className="d-flex justify-content-around">
                          <p className="mb-0">Custom cap</p>
                          <div className="checkbox-wrapper-30">
                            <span className="checkbox">
                              <input
                                type="checkbox"
                                checked={customcap}
                                onChange={() => setcustomcap(true)}
                              />
                              <svg>
                                <use
                                  xlinkHref="#checkbox-30"
                                  className="checkbox"
                                />
                              </svg>
                            </span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ display: "none" }}
                            >
                              <symbol id="checkbox-30" viewBox="0 0 22 22">
                                <path
                                  fill="none"
                                  stroke="#1c4ba5"
                                  d="M5.5,11.3L9,14.8L20.2,3.3l0,0c-0.5-1-1.5-1.8-2.7-1.8h-13c-1.7,0-3,1.3-3,3v13c0,1.7,1.3,3,3,3h13 c1.7,0,3-1.3,3-3v-13c0-0.4-0.1-0.8-0.3-1.2"
                                />
                              </symbol>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {customcap && (
                    <>
                      <div className="d-flex justify-content-between px-2 mt-3">
                        <span>100m</span>
                        <span>150m</span>
                      </div>
                      <Box sx={{ width: "100%" }}>
                        <Slider
                          defaultValue={100}
                          aria-label="Default"
                          onChange={handleSliderChange}
                          valueLabelDisplay="auto"
                          min={100}
                          max={150}
                          color="primary"
                        />
                      </Box>
                    </>
                  )}
                </div>
                <div className="col-12 mt-3 px-0">
                  <input
                    type="text"
                    className={`form-control rounded-25 p-4 ${
                      formik.errors.teamsnumber && submit ? "border_error" : ""
                    }`}
                    placeholder="Enter Total Number of Teams"
                    name="teamsnumber"
                    value={formik.values.teamsnumber}
                    onChange={formik.handleChange}
                    onInput={(e) => limitDigitsforPoints(e)}
                    maxLength={6}
                  />
                </div>
                <div className="col-12 mt-3 px-0">
                  <textarea
                    type="text"
                    className={`form-control rounded-25 p-3 px-4 ${
                      formik.errors.description && submit ? "border_error" : ""
                    }`}
                    placeholder="Enter Description"
                    name="description"
                    value={formik.values.description}
                    maxLength={250}
                    onChange={formik.handleChange}
                  ></textarea>
                  <p className="font_smaller mb-0 mt-1 px-4 text-muted">
                    *maximum 250 words
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center pb-4">
              <Button
                className="bg_league bg_theme btn font_smaller px-4 rounded-25 text-white"
                type="submit"
                onClick={() => setsubmit(true)}
              >
                Create Team for League
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* modal for sharing leagues  */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={league_tab === "sharing"}
        onHide={handleClose}
        backdrop="static"
      >
        <Modal.Header className="bg_league text-white d-flex justify-content-between align-items-baseline">
          <Modal.Title id="contained-modal-title-vcenter" className="h5 ">
            <div>
              Share League <br />
              <span className="font_smaller opacity-50 font-weight-light mx-1">
                Invite your friends.
              </span>
            </div>
          </Modal.Title>
          <div className="curson_pointer" onClick={handleClose}>
            <i class="fa-solid fa-xmark"></i>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row py-2 pb-2">
            <div className="col-12 text-center">
              <h6>{SelectedItem?.name}</h6>
              <p className="badge badge-danger px-3">Private</p>
            </div>
            <div className="col-12">
              <div className="bg-light p-3">
                <h6>Invite your friends</h6>
                <span className="font_smaller opacity-50 font-weight-light mx-1">
                  Invite your friends to Join the League with this link
                </span>
                <div className="bg-white border font_smaller my-1 px-3 py-2 rounded-25 text-muted">
                  <div className="row">
                    <div className="col-11">
                      <p className="mb-0 text-break">{`${currentDomain}#/leagues/${SelectedItem?.inviteCode}`}</p>
                    </div>
                    <div className="col-1 px-0">
                      <span
                        className="curson_pointer"
                        onClick={() =>
                          copyAndPasteUrl(
                            `${currentDomain}#/leagues/${SelectedItem?.inviteCode}`
                          )
                        }
                      >
                        <i class="fa-solid fa-copy"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-3">
              <div className="bg-light p-3">
                <h6>League Code</h6>
                <span className="font_smaller opacity-50 font-weight-light mx-1">
                  Your friends can also Join the league by entering this code.
                </span>
                <div className="bg-white border font_smaller my-1 px-3 py-2 rounded-25 text-muted d-flex justify-content-between align-items-center ">
                  <p className="mb-0">{SelectedItem?.inviteCode}</p>
                  <span
                    className="curson_pointer"
                    onClick={() => copyAndPasteUrl(SelectedItem?.inviteCode)}
                  >
                    <i class="fa-solid fa-copy"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* modal for joining public leagues  */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={league_tab === "Join_public_League"}
        onHide={handleClose}
        backdrop="static"
      >
        <Modal.Header className="bg_league text-white d-flex justify-content-between align-items-baseline">
          <Modal.Title id="contained-modal-title-vcenter" className="h5 ">
            <div>
              Join a League <br />
            </div>
          </Modal.Title>
          <div className="curson_pointer" onClick={handleClose}>
            <i class="fa-solid fa-xmark"></i>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 py-2 text-center">
              <h5>{SelectedItem?.name}</h5>
              <h6 className="mt-2">{SelectedItem?.latestRace?.name}</h6>
              <span
                className={"badge bg_league bg_theme px-3 px-4 py-2 rounded-25"}
              >
                Public
              </span>
            </div>
          </div>
        </Modal.Body>
        <div className="d-flex justify-content-center pb-4">
          <Button
            className="bg_league btn font_smaller px-4 rounded-25 text-white bg_theme"
            onClick={() => navigate(`/create_team/${SelectedItem?.inviteCode}`)}
          >
            Create Team
          </Button>
        </div>
      </Modal>

      {/* modal for League Info */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={league_tab === "league_info"}
        onHide={handleClose}
        backdrop="static"
      >
        <Modal.Header className="bg_league text-white d-flex justify-content-between align-items-baseline">
          <Modal.Title id="contained-modal-title-vcenter" className="h5 ">
            <div>
              League Details <br />
            </div>
          </Modal.Title>
          <div className="curson_pointer" onClick={handleClose}>
            <i class="fa-solid fa-xmark"></i>
          </div>
        </Modal.Header>
        <Modal.Body>
           <div className="text-center">
              <h5>{SelectedItem?.name}</h5>
           </div>
          <div className="row px-3">
            <div className="bg-body-tertiary col-12 py-3 mb-2">
              <small>Starting Race</small>
              <h6>
                {SelectedItem?.startRace?.name
                  ? SelectedItem?.startRace?.name
                  : "--"}
              </h6>
            </div>

            <div className="bg-body-tertiary col-12 py-3 mb-2">
              <small>Active Race</small>
              <h6>
                {SelectedItem?.latestRace?.name
                  ? SelectedItem?.latestRace?.name
                  : "--"}
              </h6>
            </div>

            <div className="bg-body-tertiary col-12 py-3 mb-2">
              <div className="d-flex">
                <div>
                  <img src="images/racing/3x.png" alt="" />
                </div>
                <div className="ml-3">
                  {SelectedItem?.userdetails?.length !== 0 && (
                    <h6>
                      {(SelectedItem?.userdetails?.find(
                        (i) => i?.userId === userdata?.id
                      )?.league_3xdriver<=2 && SelectedItem?.max3xDriver)
                        ? `Remaining ${SelectedItem?.userdetails?.find(
                          (i) => i?.userId === userdata?.id
                        )?.league_3xdriver===0 ?2:SelectedItem?.userdetails?.find(
                          (i) => i?.userId === userdata?.id
                        )?.league_3xdriver }`
                        : "Not Available for this League"}
                    </h6>
                  )}
                  <small>
                    {" "}
                    Experience the thrill of the 3X Booster driver. Elevate your
                    fantasy F1 team with triple the driving excellence.
                  </small>
                </div>
              </div>
            </div>

            <div className="bg-body-tertiary col-12 py-3 mb-2">
              <div className="d-flex">
                <div>
                  <img
                    src="images/racing/infinity.png"
                    alt=""
                    height={"45px"}
                  />
                </div>
                <div className="ml-3">
                  {SelectedItem?.userdetails?.length !== 0 && (
                    <h6>
                      {(SelectedItem?.userdetails?.find(
                        (i) => i?.userId === userdata?.id
                      )?.league_isNoCap===false && SelectedItem?.maxNocap)
                        ? "Remaining 1"
                        : "Not Available for this League"}
                    </h6>
                  )}
                  <small>
                    {" "}
                    Experience the thrill of the 3X Booster driver. Elevate your
                    fantasy F1 team with triple the driving excellence.
                  </small>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className="d-flex justify-content-center pb-4">
          <Button
            className="bg_league btn font_smaller px-4 rounded-25 text-white bg_theme"
            onClick={handleClose}
          >
            close
          </Button>
        </div>
      </Modal>
    </div>
  );
}
