/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../../Services/ApiService";
import { showErrorAlert, showSuccesstoaster } from "../../../Helper/SweetAlert";
import Customloader from "../../../Helper/CustomLoader";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { GetService } from "../../../Services/ConstantService";

export default function Statistics() {
  const navigate = useNavigate();
  const userdata = JSON.parse(localStorage.getItem("userdata"));
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [statistics, setstatistics] = useState("");
  const [statistics_constructor, setstatistics_constructor] = useState("");
  const [seasonRace, setseasonRace] = useState("");
  const [loading, setLoading] = useState(false);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const swiperRef = useRef(null);
  const stats_type = [
    { name: "Points Per Race", type: "pointsPerRace" },
    { name: "Driver Popularity", type: "popularity" },
    { name: "Budget Performance", type: "budgetPerformance" },
    { name: "Leading Finishers", type: "leadingFinishers" },
  ];
  useEffect(() => {
    setInnerWidth(window.innerWidth);
  }, [window.innerWidth]);

  function swipercounter() {
    if (innerWidth > 1200) {
      return 4;
    } else if (innerWidth < 1200 && innerWidth > 800) {
      return 3;
    } else {
      return 1;
    }
  }

  const getStatisticsList = async () => {
    setLoading(true);
    GetService(API_URL.GET_STATISTICS_DRIVER).then(
      (res) => {
        if (res.data.status === true) {
          setstatistics(res.data.data);
          console.log(res.data.data);
          setLoading(false);
        } else {
          showErrorAlert(res.data?.message);
          setLoading(false);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
        setLoading(false);
      }
    );
  };

  const getStatisticsList_Constructor = async () => {
    setLoading(true);
    GetService(API_URL.GET_STATISTICS_CONSTRUCTOR).then(
      (res) => {
        if (res.data.status === true) {
          setstatistics_constructor(res.data.data);
          console.log(res.data.data);
          setLoading(false);
        } else {
          showErrorAlert(res.data?.message);
          setLoading(false);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
        setLoading(false);
      }
    );
  };
  const getRacelist = async () => {
    setLoading(true);
    GetService(`${API_URL.ACTIVE_RACE}?type=All`).then(
      (res) => {
        if (res.data.status === true) {
          setseasonRace(res.data?.data?.races);
          setLoading(false);
        } else {
          showErrorAlert(res.data?.message);
          setLoading(false);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    getStatisticsList();
    getStatisticsList_Constructor();
    getRacelist();
  }, []);
  return (
    <div>
      <div className="upcome_race_wrapper float_left leader-board-team mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ft_left_heading_wraper head1 center_headign pb-0">
                <h1>Statistics</h1>
                <p className="py-1">
                  Explore F1 fantasy statistics, track driver and team
                  performance, compare fantasy <br /> points, and monitor
                  progress with real-time data, visualizations, and insights to
                  stay competitive
                </p>
              </div>
            </div>

            <div className="col-12 px-0">
              <div className="bg-white px-4 py-3 shadow-sm">
                <h4 className="fw-bolder text-muted">Season 2024</h4>
                <div className="d-flex justify-content-between">
                  <h6>Races</h6>
                  <h4 className="font-weight-bold opacity-50 text-muted mb-0">
                    {seasonRace?.length}/24
                  </h4>
                </div>
              </div>
            </div>
            {/* drivers section */}
            <div className="mt-3 px-0">
              <div className="bg-white py-3">
                <div className="d-flex justify-content-between px-4">
                  <h5 className="fw-bolder text-muted">Drivers</h5>
                  <p className="opacity-50 text-decoration-underline curson_pointer">
                    View All
                  </p>
                </div>
                {statistics && statistics?.length !== 0 && (
                  <div className="row px-4">
                    <Swiper
                      ref={swiperRef}
                      slidesPerView={swipercounter()}
                      spaceBetween={20}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[Pagination]}
                      className="mySwiper"
                      onSwiper={(swiper) => setSwiperIndex(swiper.realIndex)}
                    >
                      {stats_type &&
                        stats_type?.map((res, i) => (
                          <SwiperSlide key={i}>
                            <div>
                              <div className="card shadow-sm card">
                                <div className="bg_theme card-header f_15 font-weight-bold p-3 text-center text-white">
                                  {res?.name}
                                </div>
                                <div className="card-body">
                                  {statistics &&
                                    statistics[res?.type]?.map((item, i) => (
                                      <>
                                        {i <= 5 && (
                                          <div
                                            className="d-flex justify-content-between mb-3"
                                            key={i}
                                          >
                                            <div className="d-flex">
                                              <div>
                                                <img
                                                  src={item?.image}
                                                  alt=""
                                                  height={40}
                                                  width={60}
                                                />
                                              </div>
                                              <div className="mx-3">
                                                <h6 className="font_smaller">
                                                  {item?.name}
                                                </h6>
                                                <p className="mb-0"></p>
                                              </div>
                                            </div>
                                            {res?.type === "pointsPerRace" && (
                                              <div className="on-wrap">
                                                {item?.pointsPerRace !== "NaN"
                                                  ? item?.pointsPerRace
                                                  : "-"}
                                              </div>
                                            )}
                                            {res?.type ===
                                              "leadingFinishers" && (
                                              <div className="on-wrap">
                                                {item?.topFinishes !== "NaN"
                                                  ? item?.topFinishes
                                                  : "-"}
                                              </div>
                                            )}
                                            {res?.type ===
                                              "budgetPerformance" && (
                                              <div className="on-wrap">
                                                {item?.valueForMoney !== "NaN"
                                                  ? item?.valueForMoney
                                                  : "-"}
                                              </div>
                                            )}
                                            {res?.type === "popularity" && (
                                              <div className="on-wrap">
                                                {item?.popularity !== "NaN"
                                                  ? item?.popularity
                                                  : "-"}{" "}
                                                %
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </>
                                    ))}
                                  <button className="btn btn-block bg-secondary-subtle mt-2">
                                    View All
                                  </button>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </div>
                )}
              </div>
            </div>

            {/* Constructors section */}
            <div className="px-0">
              <div className="bg-white pb-3">
                <div className="d-flex justify-content-between px-4">
                  <h5 className="fw-bolder text-muted">Constructors</h5>
                  <p className="opacity-50 text-decoration-underline curson_pointer">
                    View All
                  </p>
                </div>
                <div className="row px-4">
                  <Swiper
                    ref={swiperRef}
                    slidesPerView={swipercounter()}
                    spaceBetween={20}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                    onSwiper={(swiper) => setSwiperIndex(swiper.realIndex)}
                  >
                    {stats_type &&
                      stats_type?.map((res, i) => (
                        <SwiperSlide key={i}>
                          <div>
                            <div className="card shadow-sm card">
                              <div className="bg_theme card-header f_15 font-weight-bold p-3 text-center text-white">
                                {res?.type === "popularity"
                                  ? "Constructor Popularity"
                                  : res?.name}
                              </div>
                              <div className="card-body">
                                {statistics_constructor &&
                                  statistics_constructor[res?.type]?.map(
                                    (item, i) => (
                                      <>
                                        {i <= 5 && (
                                          <div
                                            className="d-flex justify-content-between mb-4"
                                            key={i}
                                          >
                                            <div className="d-flex">
                                              <div>
                                                <img
                                                  src={item?.image}
                                                  alt=""
                                                  height={25}
                                                  width={110}
                                                />
                                              </div>
                                              <div className="mx-3">
                                                <h6 className="font_smaller">
                                                  {item?.name}
                                                </h6>
                                                <p className="mb-0"></p>
                                              </div>
                                            </div>
                                            {res?.type === "pointsPerRace" && (
                                              <div className="on-wrap">
                                                {item?.pointsPerRace !== "NaN"
                                                  ? item?.pointsPerRace
                                                  : "-"}
                                              </div>
                                            )}
                                            {res?.type ===
                                              "leadingFinishers" && (
                                              <div className="on-wrap">
                                                {item?.topFinishes !== "NaN"
                                                  ? item?.topFinishes
                                                  : "-"}
                                              </div>
                                            )}
                                            {res?.type ===
                                              "budgetPerformance" && (
                                              <div className="on-wrap">
                                                {item?.valueForMoney !== "NaN"
                                                  ? item?.valueForMoney
                                                  : "-"}
                                              </div>
                                            )}
                                            {res?.type === "popularity" && (
                                              <div className="on-wrap">
                                                {item?.popularity !== "NaN"
                                                  ? item?.popularity
                                                  : "-"}{" "}
                                                %
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </>
                                    )
                                  )}
                                <button className="btn btn-block bg-secondary-subtle mt-2">
                                  View All
                                </button>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading ? <Customloader /> : ""}
      </div>
    </div>
  );
}
