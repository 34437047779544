/* eslint-disable no-script-url */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../Services/ApiService";
import { showErrorAlert, showSuccessAlert } from "../../../Helper/SweetAlert";
import {
  GetService,
  PostFormData,
  PostService,
} from "../../../Services/ConstantService";
import { MoonLoader } from "react-spinners";
export default function Profile() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [country, setcountry] = useState("");
  const [submitloader, setsubmitLoader] = useState(false);
  const [submit, setsubmit] = useState(false);
  const [Image, setImage] = useState("");
  const [ImgPath, setLocalImgPath] = useState("");
  const loginuserdata = JSON.parse(localStorage.getItem("userdata"));
  const isAuthenticated = loginuserdata?.token;
  function getcountry() {
    GetService(API_URL.GET_COUNTRY).then((res) => {
      if (res?.data?.status) {
        setcountry(res?.data?.data);
      } else {
        showErrorAlert(res?.data?.message);
      }
    });
  }
  const onimageUpload = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      setLocalImgPath(window.URL.createObjectURL(e.target.files[0]));
    }
  };
  const getuser = () => {
    setLoading(true);
    const user_id = JSON.parse(localStorage.getItem("userdata"));

    if (user_id) {
      PostService(API_URL.USER_DETAIL, { id: user_id?.id }).then(
        (res) => {
          setLoading(false);
          if (res?.data?.status) {
            setUserData(res?.data?.data);
            setLocalImgPath(res?.data?.data?.image? res?.data?.data?.image:'images/racing/user.svg');
            setImage(res?.data?.data?.image? res?.data?.data?.image:'images/racing/user.svg');
            formik.setValues({
              name: res?.data?.data?.name,
              country: res?.data?.data?.country,
              email: res?.data?.data?.email,
            });
          } else {
            setLoading(false);
            showErrorAlert(res?.message, "");
          }
        },
        (error) => {
          setLoading(false);
          showErrorAlert(error?.message, "");
        }
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      country: "",
    },
    validate: (values) => {
      const errors = {};
      // Validate 'email' field
      if (!values.email) {
        errors.email = "email is required";
      }

      // Validate 'country' field
      if (!values.country) {
        errors.country = "country is required";
      }

      // Validate 'name' field
      if (!values.name) {
        errors.name = "name is required";
      }

      return errors;
    },

    onSubmit: (values) => {
      setsubmit(true);
      if (formik.isValid) {
        const formdata = new FormData();
        formdata.append("name", values?.name);
        formdata.append("email", values?.email);
        formdata.append("country", values?.country);
        formdata.append("image", Image);
        formdata.append("id", userData? userData?._id:'');
        setLoading(true);
        PostFormData(API_URL.UPDATE_PROFILE, formdata).then(
          (res) => {
            setLoading(false);
            if (res?.data?.status === true) {
              const data = {
                userName: res?.data?.data?.name,
                profile: res?.data?.data?.image,
                token: isAuthenticated,
                history: res?.data?.data?.teams?.length>0 ? true:false,
                id: res?.data?.data?._id
              };
              localStorage.setItem("userdata", JSON.stringify(data));
              showSuccessAlert(res?.data?.message, getuser);
              window.location.reload()
            } else {
              setsubmitLoader(false);
              showErrorAlert(res.data?.message);
            }
          },
          (err) => {
            setLoading(false);
            setsubmitLoader(false);
            showErrorAlert(err.response?.data?.message);
          }
        );
      }
    },
  });
  useEffect(() => {
    getuser();
    getcountry();
  }, []);

  return (
    <div>
      <div className="contact_wrapper float_left inner_contact_wrapper upcome_race_wrapper user-pro">
        <div className="container mt-5">
          <div className="row mb-4 align-items-center">
            <div className="col-lg-3 offset-lg-3 col-md-6 col-sm-6 ">
              <div className="frame-left">
                <div
                  className="control-group file-upload text-right"
                  id="file-upload1"
                >
                  <div className="image-box">
                    <img
                      src={ImgPath}
                      className="img-responsive"
                      alt=""
                      onError={(e) => {
                        e.target.src="images/racing/user.svg"
                      }}
                    />
                    <div>
                      <span className="upload-picture">Upload Picture </span>
                      <input
                        type="file"
                        name="contact_image_1"
                        className="position-absolute btn_file"
                        onChange={(e) => onimageUpload(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="popup-head-list">
                <h4 style={{ color: "#1C4BA5 !important" }}>{userData?.name}</h4>
                {/* <p className="pt-2">Driver Value</p> */}
                {/* <h4>Skill Score : 100</h4> */}
              </div>
            </div>
          </div>
          <div className="row profile">
            <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12 col-12">
              <form
                className="form-horizontal mt-4"
                onSubmit={formik.handleSubmit}
              >
                <div className="row">
                  <div className="form-group col-md-6">
                    <label className="control-label">Full Name</label>
                    <input
                      type="text"
                      name="name"
                      defaultValue
                      placeholder="Full Name"
                      className={`form-control ${
                        formik.errors.name && submit ? "border_error" : ""
                      }`}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="form-group select col-md-6">
                    <label
                      htmlFor="exampleFormControlSelect1"
                      className="control-label select"
                    >
                      Country of residence
                    </label>
                    <select
                      id="exampleFormControlSelect1"
                      className={`form-control ${
                        formik.errors.country && submit ? "border_error" : ""
                      }`}
                      value={formik.values.country}
                      onChange={formik.handleChange}
                      name="country"
                    >
                      {country &&
                        country?.map((item) => (
                          <option value={item?.name}>{item?.name}</option>
                        ))}
                    </select>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label">Email Address</label>
                    <input
                      type="email"
                      name="email"
                      className={`form-control ${
                        formik.errors.email && submit ? "border_error" : ""
                      }`}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      placeholder="Email address"
                      readOnly
                    />
                  </div>
                  <div className="col-md-12 mb-5">
                    <div className="main_button_wrapper">
                      <button
                        type="submit"
                        className=" btn btn-primary px-4 rounded-25"
                        onClick={() => setsubmit(true)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="LoaderSpinner">
          <MoonLoader
            color={"#003bff"}
            loading={loading}
            speedMultiplier={0.6}
            size={50}
          />
          <div>Loading... Please wait.</div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
